<template>
  <div>
    <layout class="user-learn-progress">
      <template v-slot:header> </template>
      <template v-slot:body>
        <div class="d-flex justify-content-between mb-3">
          <p>การตรวจพบใบหน้า</p>
          <a
            :href="`/${company.url}/analytics/users/${$route.params.mId}/learning-stats/learn-progress/${$route.params.cerId}/courses/${$route.params.cId}/face-detect`"
            target="_blank"
            >ดูทั้งหมด</a
          >
        </div>
        <b-table
          striped
          hover
          :fields="fields"
          :items="datalectures"
          :busy="isLoading"
        >
          <template v-slot:cell(lec_name)="data">
            <span>
              <i
                :class="[
                  data.item.lec_type === 'VIDEO'
                    ? 'fa-solid fa-video'
                    : data.item.lec_type === 'SLIDE'
                    ? 'fa-brands fa-slideshare'
                    : data.item.lec_type === 'QUIZ'
                    ? 'fa-solid fa-list'
                    : 'fa-solid fa-globe',
                  'mr-2',
                ]"
              ></i>
              {{ data.item.lec_name }}
            </span>
          </template>
          <template v-slot:cell(llfd_status)="data">
            <span
              :class="[
                data.item.llfd_status === 'ผ่าน'
                  ? 'text-success'
                  : data.item.llfd_status === 'ไม่ผ่าน'
                  ? 'text-danger'
                  : '',
              ]"
            >
              {{ data.item.llfd_status }}
            </span>
          </template>
          <template v-slot:cell(llfd_learningFaceApprovedProgress)="data">
            <span>
              {{ data.item.llfd_learningFaceApprovedProgress
              }}<span v-if="data.item.llfd_learningFaceApprovedProgress != '-'"
                >%</span
              >
            </span>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
      </template>
    </layout>
  </div>
</template>
<script>
import api from "@/services/api.js";
import layout from "@/components/pages/analytics/shared/Layout";
export default {
  components: {
    layout
  },
  data() {
    return {
      datalectures: [],
      cId: this.$route.params.cId,
      cerId: this.$route.params.cerId,
      company: {},
      fields: [
        { key: "lec_name", label: "เนื้อหาคอร์ส" },
        { key: "llfd_learningFaceApprovedProgress", label: "การตรวจพบใบหน้า" },
        { key: "llfd_status", label: "สถานะ" }
      ],
      isLoading: true
    };
  },

  async mounted() {
    this.company = this.$store.state.Company.companyInfo;
    await api
      .get("/v1/learning-face-detection/" + this.cerId + "?limit=10")
      .then(({ data }) => {
        this.datalectures = data;
        this.isLoading = false;
      });
  }
};
</script>
<style scoped>
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css);

p {
  font-family: "Prompt", sans-serif;
}
</style>