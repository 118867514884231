<template>
  <div style="margin-left: 3em; margin-right: 3em;">
    <PageHeader title="รายงานการจบคอร์สและสอบผ่าน"></PageHeader>
    <br />
    <br />
    <p><strong>กรุณาระบุช่วงเวลาที่ต้องการดาวน์โหลดรายงาน</strong></p>
    <br />
    <br />
    <div class="mb-4 d-flex">
      <div class="mr-5">
        <p><strong>ช่วงวันที่จบคอร์สและสอบผ่าน</strong></p>
        <div style="width: 250px; margin-top: 15px;">
          <b-input-group>
            <b-form-input
              type="text"
              :value="formattedDate"
              placeholder="ค้นหาช่วงวันที่"
              disabled
            />
            <b-input-group-prepend>
              <b-button
                variant="primary"
                @click="showCalendarRange()"
              >
                <b-icon icon="calendar2-event" variant="white"></b-icon>
              </b-button>
            </b-input-group-prepend>
          </b-input-group>
          <DatePicker
            id="calendar-range"
            style="display: none; float: right; position: relative; will-change: transform; left: 0px;"
            v-model="range"
            is-range
          />
        </div>
      </div>
      <div>
        <p><strong>สถานะหลักสูตร</strong></p>
        <div style="width: 250px; margin-top: 15px;">
          <v-select
            :options="statusOptions"
            label="title"
            v-model="statusSelected"
            placeholder="เลือกสถานะ"
            :clearable="false"
            @input="filterCourses($event)"
          />
        </div>
      </div>
    </div>

    <div class="md-4" style="width: 750px;">
      <p><strong>หลักสูตรทั้งหมด</strong></p>
      <div style="margin-top: 15px;">
        <v-select
          v-if="courses != null"
          :options="courses"
          label="courseName"
          v-model="courseData"
          placeholder="กรุณาพิมพ์เพื่อค้นหาชื่อหลักสูตร"
          :clearable="false"
          @input="checkDisplayData()"
        />
        <v-select v-else placeholder="กำลังโหลดข้อมูล..." />
      </div>
    </div>
    <br />
    <p>
      <strong style="color: #ffcc00;">
        หมายเหตุ: ระบบทำการ generate report เป็นไฟล์ .xlsx
        และดาวน์โหลดลงบนเครื่องของคุณ
      </strong>
    </p>
    <br />
    <br />
    <br />
    <div v-if="isDisplay" class="card card-csq no-shadow">
      <div class="card-body">
        <div style="display: flex;">
          <div>
            <b-form-input
              v-model="search"
              placeholder="ค้นหาข้อมูลผู้เรียน"
              style="padding: 1rem; display: inline-block; width: 300px"
            >
            </b-form-input>
          </div>
          <div style="margin-left: auto;">
            <b-button
              @click="exportCPAReport()"
              id="cpa-report"
              variant="primary"
              style="display: inline-block;"
              :disabled="isDisplayNoData"
            >
              <b-spinner
                id="cpa-report-loading"
                class="align-middle"
                style="display: none;"
              ></b-spinner>
              Export CPA
            </b-button>
            <ag-grid-vue
              style="width: 100%; height: 800px; display: none"
              class="ag-theme-alpine"
              :columnDefs="columnDef"
              @grid-ready="onGridReady"
              :rowData="reportData"
              :excelStyles="excelStyles"
            ></ag-grid-vue>
          </div>
          <div class="mx-4">
            <b-button
              @click="exportCPDReport()"
              id="cpd-report"
              variant="primary"
              style="display: inline-block;"
              :disabled="isDisplayNoData"
            >
              <b-spinner
                id="cpd-report-loading"
                class="align-middle"
                style="display: none;"
              ></b-spinner>
              Export CPD
            </b-button>
            <ag-grid-vue
              style="width: 100%; height: 800px; display: none"
              class="ag-theme-alpine"
              :columnDefs="columnDef"
              @grid-ready="onGridReady"
              :rowData="reportData"
              :excelStyles="excelStyles"
            ></ag-grid-vue>
          </div>
          <div class="mr-4">
            <b-button
              @click="exportNonCPDReport()"
              id="non-cpd-report"
              variant="primary"
              style="display: inline-block;"
              :disabled="isDisplayNoData"
            >
              <b-spinner
                id="non-cpd-report-loading"
                class="align-middle"
                style="display: none;"
              ></b-spinner>
              Export Non-CPD
            </b-button>
            <ag-grid-vue
              style="width: 100%; height: 800px; display: none"
              class="ag-theme-alpine"
              :columnDefs="columnDef"
              @grid-ready="onGridReady"
              :rowData="reportData"
              :excelStyles="excelStyles"
            ></ag-grid-vue>
          </div>
          <div>
            <b-button
              @click="exportTableReport()"
              id="table-report"
              variant="primary"
              style="display: inline-block;"
              :disabled="isDisplayNoData"
            >
              <b-spinner
                id="table-report-loading"
                class="align-middle ml-3"
                style="display: none;"
              ></b-spinner>
              <i class="fa fa-angle-double-down" aria-hidden="true"></i>
              ดาวน์โหลดตารางข้อมูล
            </b-button>
            <ag-grid-vue
              style="width: 100%; height: 800px; display: none"
              class="ag-theme-alpine"
              :columnDefs="columnReport"
              @grid-ready="onGridReportReady"
              :rowData="rowReport"
              :excelStyles="excelStyles"
            ></ag-grid-vue>
          </div>
        </div>

        <div class="no-data" v-if="isDisplayNoData">
          ไม่มีรายละเอียดการจบคอร์สในขณะนี้ กรุณาเลือกคอร์สเรียน
          วันและเวลาที่เข้าเรียนต้องการดาวน์โหลดข้อมูล
        </div>

        <b-table
          v-else
          :items="
            search == null
              ? items
              : items.filter((i) =>
                  (
                    i.mp_firstName +
                    i.mp_lastName +
                    (this.comId == 519 ? i.mp_idCard : i.sm_membershipId) +
                    i.sm_cpaNo
                  ).toLowerCase().replace(/ /g, '').includes(search.toLowerCase().replace(/ /g, ''))
                )
          "
          :fields="fields"
          :busy="isLoading"
          :per-page="perPage"
          :current-page="currentPage"
          hover
          responsive
          class="mt-3 table-report"
        >
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #head(mp_idCard)>
            {{ comId == "519" ? "เลขบัตรประชาชน" : "เลขประจำตัวผู้ทำบัญชี" }}
          </template>
          <template v-slot:cell(cpd)="props">
            {{
              comId == "513" ? props.item.sm_membershipId : props.item.mp_idCard
            }}
          </template>
          <template v-slot:cell(cer_completeDate)="props">
            {{ props.item.cer_completeDate | thaiDate }}
          </template>
          <template v-slot:cell(download)="{ item }">
            <div>
              <b-button
                variant="warning"
                style="display: inline-block; margin-right: 5px;"
                :disabled="isDisplayNoData"
                v-b-modal="`modal-notify-id-card-${item.m_id}`"
              >
                <i class="bi bi-envelope-arrow-up-fill" style="color: white"></i>
              </b-button>

              <b-modal
                title="ยืนยันการยกเลิกการลงทะเบียน"
                :id="`modal-notify-id-card-${item.m_id}`"
                hide-footer
                hide-header
                centered
              >
                <div class="container text-center">
                  <div class="row center-row my-5">
                    <div class="col">
                      <b-icon
                        icon="exclamation-triangle"
                        variant="warning"
                        scale="5"
                      ></b-icon>
                    </div>
                  </div>
                  <div class="row center-row">
                    <div class="col">
                      <label class="bold-text larger-text">แจ้งอัปเดตบัตรประชาชน</label>
                    </div>
                  </div>
                  <div class="row center-row">
                    <div class="col">
                      <label>ส่งอีเมลอัปเดตรูปบัตรประชาชน <br> ให้กับ {{ item.mp_firstName }} {{ item.mp_lastName }} หรือไม่?</label>
                    </div>
                  </div>
                  <div class="row center-row mt-3">
                    <div class="col">
                      <b-button variant="outline-secondary" @click="closeModal(item.m_id)" class="mr-2" :disabled="onLoading">ยกเลิก</b-button>
                      <b-button variant="primary" @click="notifyIdCard(item.m_id)" :disabled="onLoading"><b-spinner v-if="onLoading" small></b-spinner>ยืนยัน</b-button>
                    </div>
                  </div>
                </div>
              </b-modal>

              <b-button
                :href="
                  `${company.whitelabelUrl}/courseEnroll/dharmnitiCompletedEnrollReport?cerId=${item.cer_id}&token=${token}`
                "
                target="_blank"
                id="export-report"
                variant="primary"
                style="display: inline-block;"
                :disabled="isDisplayNoData"
              >
                <i class="fa fa-angle-double-down" aria-hidden="true"></i>
                ดาวน์โหลดรายงาน
              </b-button>
            </div>
            
          </template>
          <div slot="table-busy" style="text-align: center;">
            <b-spinner></b-spinner>
          </div>
        </b-table>
      </div>
      <div v-if="items.length > 0" class="card-footer bg-transparent border-0">
        <div class="row mt-3">
          <div class="col-lg-6">
            <form class="form-inline">
              <div class="form-group mb-2">
                <label>แสดง</label>
              </div>
              <div class="form-group ml-2 mb-2">
                <b-form-select
                  v-model="selectedShow"
                  size="sm"
                  @change="changeShowData"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                </b-form-select>
              </div>
              <div class="form-group ml-2 mb-2">
                <label>
                  จาก
                  {{ items.length }}
                  รายการ
                </label>
              </div>
            </form>
          </div>
          <div class="col-lg-6">
            <nav class="float-right" aria-label="Page navigation example">
              <b-pagination
                v-model="currentPage"
                :total-rows="items.length"
                :per-page="perPage"
                @change="getDataTable"
                aria-controls="course-list"
                last-number
              ></b-pagination>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/layouts/PageHeader.vue";
import moment from "moment";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import api from "@/services/api";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { AgGridVue } from "ag-grid-vue";

export default {
  components: { PageHeader, DatePicker, vSelect, "ag-grid-vue": AgGridVue },
  props: {
    company: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      comId: this.$store.state.Company.companyInfo.id,
      token: localStorage.getItem("token"),
      range: {
        start: "",
        end: "",
      },
      selectedDate: {
        start: null,
        end: null,
      },
      statusSelected: "เลือกสถานะ",
      statusOptions: [
        {title: 'สถานะทั้งหมด', value: ''},
        {title: 'กำลังเผยแพร่', value: 'PUBLISH'},
        {title: 'พักการเผยแพร่', value: 'HOLD'},
      ],
      courses: null,
      courseData: "กรุณาพิมพ์เพื่อค้นหาชื่อหลักสูตร",
      isDisplay: false,
      isLoading: false,
      isDisplayNoData: false,
      items: [],
      fields: [
        { label: "ลำดับ", key: "index" },
        { label: "เลขบัตรประชาชน", key: "mp_idCard" },
        {
          label: "CPD",
          key: "sm_membershipId",
        },
        { label: "CPA", key: "sm_cpaNo" },
        { label: "ชื่อ", key: "mp_firstName" },
        { label: "นามสกุล", key: "mp_lastName" },
        { label: "วันที่จบคอร์สและสอบผ่าน", key: "cer_completeDate" },
        { label: "", key: "download", class: "text-right" },
      ],
      selectedShow: 10,
      perPage: 10,
      currentPage: 1,
      columnDef: [
        { headerName: "", field: "header" },
        {
          headerName: "",
          field: "runningNumber",
        },
        { headerName: "รหัสหน่วยงาน:", field: "B", cellClass: "stringType" },
        { headerName: "ชื่อหน่วยงาน:", field: "C" },
        { headerName: "รหัสหลักสูตร:", field: "D" },
        { headerName: "ชื่อหลักสูตร:", field: "E" },
        {
          headerName: "สถานที่อบรม/สัมมนา:",
          field: "F",
          colSpan: (params) => (params.data.F === "การบัญชี" ? 2 : 1),
        },
        { headerName: "วันที่อนุมัติจากสภาฯ:", field: "G" },
        {
          headerName: "วันที่การอบรม/สัมมนา:",
          field: "H",
          colSpan: (params) => (params.data.H === "จรรยาบรรณ" ? 2 : 1),
        },
        { headerName: "เวลาที่อบรม/สัมมนา:", field: "I" },
        {
          headerName: "",
          field: "J",
          colSpan: (params) => (params.data.J === "อื่นๆ" ? 2 : 1),
        },
        { headerName: "", field: "K" },
        {
          headerName: "",
          field: "L",
          colSpan: (params) => (params.data.L === "รวมทั้งสิ้น" ? 2 : 1),
        },
        { headerName: "", field: "M" },
      ],
      reportData: [],
      excelStyles: [
        {
          id: "header",
          font: { fontName: "Cordia New", size: 16, bold: true },
          alignment: {
            horizontal: "Center",
          },
        },
        {
          id: "cell",
          font: { fontName: "Cordia New", size: 16 },
          alignment: {
            horizontal: "Center",
          },
        },
        {
          id: "stringType",
          dataType: "String",
        },
      ],
      gridApi: null,
      gridApiReport: null,
      columnReport: [
        {
          headerName: "",
          field: "runningNumber",
        },
        { headerName: "", field: "mp_idCard", cellClass: "stringType" },
        {
          headerName: "",
          field: "cpd",
          cellClass: "stringType",
        },
        { headerName: "", field: "sm_cpaNo", cellClass: "stringType" },
        {
          headerName: "",
          field: "mp_prefixName",
        },
        { headerName: "", field: "mp_firstName" },
        { headerName: "", field: "mp_lastName" },
        { headerName: "", field: "cer_completeDate" },
      ],
      rowReport: [],
      search: null,
      onLoading: false
    };
  },
  async mounted() {
    await api.get(`v1/companies/${this.comId}/courses`).then(({ data }) => {
      this.courses = data.data;
    });
  },
  computed: {
    formattedDate() {
      if (this.range.start) {
        let rangeCalendar = document.getElementById("calendar-range");
        if (rangeCalendar.style.display == "inline-block") {
          this.showCalendarRange();
        }
        this.formatApiDate();
        return (
          moment(this.range.start).format("DD MMM YYYY") +
          " - " +
          moment(this.range.end).format("DD MMM YYYY")
        );
      } else {
        return "";
      }
    },
  },
  watch: {
    search(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentPage = 1;
      }
      }
  },
  methods: {
    formattedDateTh() {
      let newDate =
        moment(this.range.start).format("DD MMM ") +
        (moment(this.range.start).year() + 543) +
        " - " +
        moment(this.range.end).format("DD MMM ") +
        (moment(this.range.end).year() + 543);

      return newDate;
    },
    getReportHeader(result, type) {
      moment.updateLocale("th", {});
      let seminarDate =
        moment(this.range.start).format("DD MMM ") +
        (moment(this.range.start).year() + 543) +
        " - " +
        moment(this.range.end).format("DD MMM ") +
        (moment(this.range.end).year() + 543);
      let item = result[0];
      let approveDate = item.c_approveDate
        ? moment(item.c_approveDate).format("DD-MM-") +
          (moment(item.c_approveDate).year() + 543)
        : " - ";
      let header = [
        {
          header: "HEADER",
          B:
            this.comId == "519"
              ? "06-001"
              : this.comId == "513"
              ? "06-343"
              : "-",
          C:
            this.comId == "519"
              ? "บริษัท ฝึกอบรมและสัมมนาธรรมนิติ จำกัด"
              : "บริษัท เอ็น วาย ซี อะแคเดมิ จำกัด",
          D: `${item.tfc_cpdCourseCode ?? "-"}`,
          E: `${item.c_name ?? "-"}`,
          F:
            "การพัฒนาความรู้ต่อเนื่องผ่านระบบเครือข่ายอินเตอร์เน็ต (e-Learning)",
          G: `${approveDate}`,
          H: `${seminarDate}`,
        },
        {
          H: "",
        },
        {
          H: "",
        },
        {
          header: "HEADER",
        },
        {
          B: type == "Non-CPD" ? "" : "เลขทะเบียน",
          F: "การบัญชี",
          H: "จรรยาบรรณ",
          J: "อื่นๆ",
          L: "รวมทั้งสิ้น",
        },
        {
          runningNumber: "ลำดับ",
          B: type == "CPA" ? "ผู้สอบบัญชีรับอนุญาต" : 
            type == "CPD" ? "ผู้ทำบัญชีรับอนุญาต" : "บัตรประชาชน",
          C: "คำนำหน้าชื่อ",
          D: "ชื่อ",
          E: "นามสกุล",
          F: "ชั่วโมง",
          G: "นาที",
          H: "ชั่วโมง",
          I: "นาที",
          J: "ชั่วโมง",
          K: "นาที",
          L: "ชั่วโมง",
          M: "นาที",
        },
      ];
      return header;
    },
    exportCPAReport() {
      let result = [];
      let data = [];

      document.getElementById("cpa-report-loading").style.display =
        "inline-block";
      document.getElementById("cpa-report").disabled = true;
      document.getElementById("cpd-report").disabled = true;
      document.getElementById("non-cpd-report").disabled = true;

      result = this.items.filter(
        (item) => item.sm_cpaNo !== null && item.sm_cpaNo !== ""
      );

      if (result.length > 0) {
        data = result.map((item, index) => {
          return {
            header: "",
            runningNumber: index + 1,
            B: item.sm_cpaNo,
            C: this.comId == "485" ? item.mp_prefixName : "คุณ",
            D: item.mp_firstName,
            E: item.mp_lastName,
            F: item.cpaAccHour,
            G: item.cpaAccMinute,
            H: item.cpaEthicHour,
            I: item.cpaEthicMinute,
            J: item.cpaOtherHour,
            K: item.cpaOtherMinute,
            L:
              Number(item.cpaAccHour) +
              Number(item.cpaEthicHour) +
              Number(item.cpaOtherHour) +
              Math.floor(
                (item.cpaAccMinute +
                  item.cpaEthicMinute +
                  item.cpaOtherMinute) /
                  60
              ),
            M:
              (item.cpaAccMinute + item.cpaEthicMinute + item.cpaOtherMinute) %
              60,
          };
        });
        data[0].header = "DETAIL";
        data.push({ header: "DETAIL" });
      }

      this.reportData = [...this.getReportHeader(result, "CPA"), ...data];
      setTimeout(() => {
        this.gridApi.exportDataAsExcel({
          sheetName: this.generateFileName() + "CPA",
          fileName: `${this.generateFileName() + "CPA"}`,
        });
        document.getElementById("cpa-report-loading").style.display = "none";
        document.getElementById("cpa-report").disabled = false;
        document.getElementById("cpd-report").disabled = false;
        document.getElementById("non-cpd-report").disabled = false;
      }, 1000);
    },
    exportCPDReport() {
      let result = [];
      let data = [];

      document.getElementById("cpd-report-loading").style.display =
        "inline-block";
      document.getElementById("cpd-report").disabled = true;
      document.getElementById("cpa-report").disabled = true;
      document.getElementById("non-cpd-report").disabled = true;

      result = this.items.filter((item) => item.sm_membershipId != null);

      if (result.length > 0) {
        data = result.map((item, index) => {
          return {
            header: "",
            runningNumber: index + 1,
            B: item.sm_membershipId ?? "",
            C: this.comId == "485" ? item.mp_prefixName : "คุณ",
            D: item.mp_firstName,
            E: item.mp_lastName,
            F: item.accAccHour,
            G: item.accAccMinute,
            H: item.accEthicHour,
            I: item.accEthicMinute,
            J: item.accOtherHour,
            K: item.accOtherMinute,
            L:
              Number(item.accAccHour) +
              Number(item.accEthicHour) +
              Number(item.accOtherHour) +
              Math.floor(
                (item.accAccMinute +
                  item.accEthicMinute +
                  item.accOtherMinute) /
                  60
              ),
            M:
              (item.accAccMinute + item.accEthicMinute + item.accOtherMinute) %
              60,
          };
        });
        data[0].header = "DETAIL";
        data.push({ header: "DETAIL" });
      }

      this.reportData = [...this.getReportHeader(result, "CPD"), ...data];

      setTimeout(() => {
        this.gridApi.exportDataAsExcel({
          sheetName: this.generateFileName() + "CPD",
          fileName: `${this.generateFileName() + "CPD"}`,
        });
        document.getElementById("cpd-report-loading").style.display = "none";
        document.getElementById("cpd-report").disabled = false;
        document.getElementById("cpa-report").disabled = false;
        document.getElementById("non-cpd-report").disabled = false;
      }, 1000);
    },
    generateFileName() {
      let startDate = moment(this.range.start).format("YYYYMMDD");
      let endDate = moment(this.range.end).format("YYYYMMDD");
      let date = startDate == endDate ? startDate : startDate + "-" + endDate;
      let fileName = date + "-(" + this.courseData.courseName + ")-";
      return fileName;
    },
    exportTableReport() {
      document.getElementById("table-report-loading").style.display =
        "inline-block";
      document.getElementById("table-report").disabled = true;

      let result = this.items;
      let data = [];

      if (result.length > 0) {
        data = result.map((item, index) => {
          return {
            runningNumber: index + 1,
            mp_idCard: item.mp_idCard,
            cpd: item.sm_membershipId,
            sm_cpaNo: item.sm_cpaNo,
            mp_prefixName: this.comId == "485" ? item.mp_prefixName : "คุณ",
            mp_firstName: item.mp_firstName,
            mp_lastName: item.mp_lastName,
            cer_completeDate: this.formatDate(item.cer_completeDate),
          };
        });
      }

      let row = [
        {
          runningNumber: "ชื่อหลักสูตร",
          mp_idCard: this.courseData.courseName,
        },
        {
          runningNumber: "ช่วงวันที่ : ",
          mp_idCard: this.formattedDateTh(),
        },
        { mp_idCard: "" },
        {
          runningNumber: "#",
          mp_idCard: "ID Card",
          cpd: "CPD",
          sm_cpaNo: "CPA",
          mp_prefixName: "Prefix",
          mp_firstName: "First Name",
          mp_lastName: "Last Name",
          cer_completeDate: "Completed",
        },
      ];

      this.rowReport = [...row, ...data];
      setTimeout(() => {
        this.gridApiReport.exportDataAsExcel({
          sheetName: this.generateFileName() + "CPA",
          fileName: `${this.generateFileName() + "CPA"}`,
        });
        document.getElementById("table-report-loading").style.display = "none";
        document.getElementById("table-report").disabled = false;
      }, 1000);
    },
    exportNonCPDReport() {
      let result = [];
      let data = [];

      document.getElementById("non-cpd-report-loading").style.display =
        "inline-block";
      document.getElementById("cpa-report").disabled = true;
      document.getElementById("cpd-report").disabled = true;
      document.getElementById("non-cpd-report").disabled = true;

      result = this.items.filter(
        (item) => (item.sm_cpaNo == null || item.sm_cpaNo == "") && (item.sm_membershipId == null || item.sm_membershipId == "")
      );

      if (result.length > 0) {
        data = result.map((item, index) => {
          return {
            header: "",
            runningNumber: index + 1,
            B: item.mp_idCard ?? "",
            C: this.comId == "485" ? item.mp_prefixName : "คุณ",
            D: item.mp_firstName,
            E: item.mp_lastName,
            F: item.accAccHour,
            G: item.accAccMinute,
            H: item.accEthicHour,
            I: item.accEthicMinute,
            J: item.accOtherHour,
            K: item.accOtherMinute,
            L:
              Number(item.accAccHour) +
              Number(item.accEthicHour) +
              Number(item.accOtherHour) +
              Math.floor(
                (item.accAccMinute +
                  item.accEthicMinute +
                  item.accOtherMinute) /
                  60
              ),
            M:
              (item.accAccMinute + item.accEthicMinute + item.accOtherMinute) %
              60,
          };
        });
        data[0].header = "DETAIL";
        data.push({ header: "DETAIL" });
      }

      this.reportData = [...this.getReportHeader(result, "Non-CPD"), ...data];
      setTimeout(() => {
        this.gridApi.exportDataAsExcel({
          sheetName: this.generateFileName() + "non-cpd",
          fileName: `${this.generateFileName() + "non-cpd"}`,
        });
        document.getElementById("non-cpd-report-loading").style.display = "none";
        document.getElementById("cpa-report").disabled = false;
        document.getElementById("cpd-report").disabled = false;
        document.getElementById("non-cpd-report").disabled = false;
      }, 1000);
    },
    async onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    async onGridReportReady(params) {
      this.gridApiReport = params.api;
      this.gridColumnApi = params.columnApi;
    },
    async getDataTable() {
      this.isLoading = true;
      await api
        .get(
          `/v1/tfac/completed-enroll-report?c_id=${this.courseData.courseId}&complete_date_start=${this.selectedDate.start}&complete_date_finish=${this.selectedDate.end}`
        )
        .then(({ data }) => {
          this.items = data;
          this.isLoading = false;

          if (this.items.length < 1) {
            this.isDisplayNoData = true;
          } else {
            this.isDisplayNoData = false;
          }
        });
    },
    async filterCourses(status) {
      if (status.value === "HOLD") {
        try {
          const [suspendRes, disabledRes] = await Promise.all([
            api.get(`v1/companies/${this.comId}/courses?status=SUSPEND`),
            api.get(`v1/companies/${this.comId}/courses?status=DISABLED`),
          ]);

          this.courses = [...suspendRes.data.data, ...disabledRes.data.data];
        } catch (error) {
          console.error("Error fetching courses:", error);
        }
      } else {
        await api.get(`v1/companies/${this.comId}/courses?status=${status.value}`).then(({ data }) => {
          this.courses = data.data;
        });
      }
    },
    checkDisplayData() {
      if (
        this.selectedDate.start &&
        this.selectedDate.end &&
        this.courseData !== "กรุณาพิมพ์เพื่อค้นหาชื่อหลักสูตร"
      ) {
        this.isDisplay = true;
        this.getDataTable();
      } else {
        this.isDisplay = false;
      }
    },
    formatApiDate() {
      this.selectedDate.start = moment(this.range.start).format("YYYY-MM-DD");
      this.selectedDate.end = moment(this.range.end).format("YYYY-MM-DD");
      this.checkDisplayData();
    },
    showCalendarRange() {
      let rangeCalendar = document.getElementById("calendar-range");
      if (rangeCalendar.style.display == "inline-block") {
        rangeCalendar.style.display = "none";
      } else {
        rangeCalendar.style.display = "inline-block";
      }
    },
    changeShowData() {
      this.perPage = this.selectedShow;
    },
    formatDate(date) {
      const dateObject = new Date(date);
      const thaiYear = dateObject.getFullYear() + 543;

      const formattedDate = dateObject.toLocaleDateString("th-TH", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });

      const formattedDateWithThaiYear = formattedDate
        .replace(/\d{4}/, thaiYear.toString())
        .replace(/\//g, "-");

      return formattedDateWithThaiYear;
    },
    async notifyIdCard(m_id){
      this.onLoading = true;
      await api
        .post(
          `/v1/members/${m_id}/tfac-notify-upload-id-card`
        )
        .then(() => {
          this.$bvToast.toast(`แจ้งอัปเดตบัตรประชาชนแล้ว`, {
            variant: 'success', 
            solid: true,
            noCloseButton: true
          });
          this.onLoading = false;
          this.closeModal(m_id)
        });
    },
    closeModal(m_id) {
      this.$bvModal.hide(`modal-notify-id-card-${m_id}`);
    }
  },
};
</script>

<style scoped>
.no-data {
  font-weight: 600;
  color: #eb8122;
  background-color: #fbe4d0;
  border: 1px solid #f8d7b9;
  padding: 12px 15px;
  border-radius: 4px;
  margin-top: 15px;
}

.spinner-border {
  width: 1.3rem;
  height: 1.3rem;
  margin-right: 8px;
}
</style>
