import api from "../../../services/api";
import axios from "@/services/exam-api.js";

export default {
  async fetchExams({ commit }, comId) {
    const { data } = await api.get(`v1/temp-exams/${comId}`);
    commit("SET_EXAMS", data);
  },

  async searchExams({ commit }, q) {
    const { data } = await api.get(`v1/exam/search/?q=${q}`);
    commit("SET_EXAMS", data);
  },

  async createExams({ commit }, body, token) {
    const { data } = await axios.post(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/exams`,
      body,
      token
    );
    commit("SET_CREATE_EXAM", data);
    return data;
  },

  async findExamsbyId({ commit, dispatch }, id) {
    const { data } = await axios.get(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/exams/${id}/entire?builder=1`
    );
    commit("FIND_EXAMS_BY_ID", data);
    dispatch("countSectionAndQuestion", id);
  },

  async countSectionAndQuestion({ commit }, id) {
    const { data } = await axios.get(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/exams/${id}/count-question`
    );
    commit("UPDATE_SECTION_AND_QUESTION_COUNT", data)
  },

  async deleteExams({ commit }, id, token) {
    let response = await axios.delete(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/exams/${id}`,
      token
    );
    if (response.status == 200 || response.status == 204) {
      commit("DELETE_EXAMS", id);
    }
  },

  async updateExamInfo(_, request) {
    await axios.patch(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/exams/${request.id}`,
      request.body,
      request.token
    );
  },
  
  async updateExamSectionOrder(_, request) {
    await axios.patch(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/exams/${request.id}`,
      request.body,
      request.token
    );
  },
  // Sections
  async createSection({ commit }, request) {
    const { data } = await axios.post(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/exams/${request.id}/sections`,
      request.body,
      request.token
    );
    commit("SET_SECTION", data);
  },
  async addNewSection({ commit }, request) {
    const { data } = await axios.post(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/exams/${request.id}/sections`,
      request.body,
      request.token
    );
    commit("ADD_NEW_SECTION", data);
    
  },
  async deleteExamSection(_, request) {
    await axios.delete(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/exams/${request.id}/sections/${request.sectionId}`,
      request.token
    );
    
  },
  async updateSectionInfo({ commit }, request) {
    const { data } = await axios.patch(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/exams/${request.id}/sections/${request.sectionId}`,
      request.body,
      request.token
    );
    commit("UPDATE_SECTION_INFO", data);
  },

  // Questions
  async createMultipleChoiceQuestion({ commit }, request) {
    const { data } = await axios.post(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/question-multi-choices`,
      request.body,
      request.token
    );
    let payload = {
      data: data,
      sectionIndex: request.sectionIndex,
    };
    commit("ADD_NEW_QUESTION", payload);
    
    axios.put(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/exams/${request.id}/sections/${request.sectionId}/questions/${data.id}`
    );
    return data;
  },
  async createFillBlankQuestion({ commit }, request) {
    const { data } = await axios.post(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/questions-fill-blank`,
      request.body,
      request.token
    );
    let payload = {
      data: data,
      sectionIndex: request.sectionIndex,
    };
    commit("ADD_NEW_QUESTION", payload);
    
    axios.put(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/exams/${request.id}/sections/${request.sectionId}/questions/${data.id}`
    );
    return data;
  },
  async createPassageAndBundleQuestion({ commit }, request) {
    const { data } = await axios.post(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/questions-passage`,
      request.body,
      request.token
    );
    let payload = {
      data: data,
      sectionIndex: request.sectionIndex,
    };
    commit("ADD_NEW_QUESTION", payload);
    
    axios.put(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/exams/${request.id}/sections/${request.sectionId}/questions/${data.id}`
    );
    return data;
  },
  async createNewSubQuestion({ commit }, request) {
    const { data } = await axios.post(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/questions-passage/${request.questionId}/sub/questions-multi-choice`,
      request.body,
      request.token
    );
    commit("CREATE_NEW_SUB_QUESTION", data);
    
    return data;
  },

  // question answer
  async createQuestionAnswer(_, request) {
    await axios.post(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/questions/${request.questionId}/answer`,
      request.body,
      request.token
    );
  },

  async updateQuestionAnswer(_, request) {
    await axios.put(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/questions/${request.questionId}/answer`,
      request.body,
      request.token
    );
  },

  async deleteQuestionAnswer(_, request) {
    await axios.delete(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/questions/${request.questionId}/answer`,
      request.token
    );
  },

  async createSubQuestionAnswer(_, request) {
    await axios.post(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/questions-passage/${request.questionId}/answers/sub-questions/${request.subQuestionId}`,
      request.body,
      request.token
    );
  },

  async updateSubQuestionAnswer(_, request) {
    await axios.put(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/questions-passage/${request.questionId}/answers/sub-questions/${request.subQuestionId}`,
      request.body,
      request.token
    );
  },

  async deleteSubQuestionAnswer(_, request) {
    await axios.delete(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/questions-passage/${request.questionId}/answers/sub-questions/${request.subQuestionId}`,
      request.token
    );
  },

  async fetchQuestionAnswer({ commit }, id) {
    const { data } = await axios.get(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/questions/${id}/answer`
    );

    commit("SET_QUESTION_ANSWER", data);
  },

  async fetchQuestionAllAnswer({ commit }, id) {
    const { data } = await axios.get(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/questions/get-answer-by-exam-id/${id}`
    );

    commit("SET_QUESTION_ALL_ANSWER", data);
  },

  async deleteQuestion({ commit }, request) {
    await axios.delete(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/exams/${request.id}/sections/${request.sectionId}/questions/${request.questionId}`,
      request.token
    );
    let payload = {
      questionId: request.questionId,
      sectionIndex: request.sectionIndex,
    };
    commit("DELETE_QUESTIONS", payload);
    
  },

  async deleteSubQuestion({ commit }, request) {
    await axios.delete(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/questions-passage/${request.questionId}/sub/questions/${request.subQuestionId}`,
      request.token
    );
    commit("DELETE_SUB_QUESTIONS", request);
  },

  async updateQuestionInfo(_, request) {
    await axios.patch(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/question-multi-choices/${request.questionId}`,
      request.body,
      request.token
    );
  },

  async updateSubQuestionInfo(_, request) {
    await axios.put(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/questions-passage/${request.questionId}/sub/questions-multi-choice/${request.subQuestionId}`,
      request.body,
      request.token
    );
  },

  async updateQuestionFillBlankInfo(_, request) {
    await axios.patch(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/questions-fill-blank/${request.questionId}`,
      request.body,
      request.token
    );
  },

  async updateQuestionOrder(_, request) {
    await axios.patch(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/question-multi-choices/${request.id}`,
      request.body,
      request.token
    );
  },

  async updateSubQuestionOrder(_, request) {
    await axios.put(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/questions-passage/${request.id}/sub/questions-multi-choice/${request.subId}`,
      request.body,
      request.token
    );
  },

  // choices
  async createChoiceForMultipleChoicesQuestion({ commit }, request) {
    const data = await axios.post(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/question-multi-choices/${request.questionId}/choices`,
      request.body,
      request.token
    );

    commit("CREATE_CHOICE_FOR_MULTI_QUESTION", data);
    return true;
  },

  async createChoiceForSubQuestion({ commit }, request) {
    const data = await axios.post(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/questions-passage/${request.questionId}/sub/questions-multi-choice/${request.subQuestionId}/choices`,
      request.body,
      request.token
    );
    let payload = {
      data: data,
      sectionIndex: request.sectionIndex,
      questionId: request.questionId,
      questionIndex: request.questionIndex,
      subQuestionId: request.subQuestionId,
    };

    commit("CREATE_CHOICE_FOR_SUB_QUESTION", payload);
    return true;
  },

  async deleteChoiceForMultipleChoicesQuestion(_, request) {
    await axios.delete(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/question-multi-choices/${request.questionId}/choices/${request.choiceId}`,
      request.token
    );
  },

  async deleteChoiceForSubQuestion(_, request) {
    await axios.delete(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/questions-passage/${request.questionId}/sub/questions-multi-choice/${request.subQuestionId}/choices/${request.choiceId}`,
      request.token
    );
  },

  async updateChoiceForMultipleChoicesQuestion(_, request) {
    await axios.put(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/question-multi-choices/${request.questionId}/choices/${request.choiceId}`,
      request.body,
      request.token
    );
  },

  async updateChoiceForSubQuestion(_, request) {
    await axios.put(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/questions-passage/${request.questionId}/sub/questions-multi-choice/${request.subQuestionId}/choices/${request.choiceId}`,
      request.body,
      request.token
    );
  },

  // Criteria
  async addglobalCriteria({ commit, dispatch }, request) {
    const data = await axios.post(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/exams/${request.id}/global-criteria`,
      request.body,
      request.token
    );
    commit("ADD_NEW_GRADING_CRITERIA", data);
    dispatch("findExamsbyId", request.id);
  },

  async updateGlobalCriteria({ commit }, request) {
    const data = await axios.put(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/exams/${request.id}/global-criteria/${request.index}`,
      request.body,
      request.token
    );
    let payload = {
      data: data,
      arrayindex: request.arrayindex,
      checkUpdateOfDelete: request.checkUpdateOfDelete,
    };
    commit("UPDATE_GRADING_CRITERIA", payload);
  },

  async deleteglobalCriteria({ commit }, request) {
    await axios.delete(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/exams/${request.id}/global-criteria/${request.index}`,
      request.token
    );
    commit("DELETE_GRADING_CRITERIA", request.index);
  },

  // exam-setting

  async updateOptions({ commit , state}, request) {
    if ( state.exam._options?.timeLimit?.timeLimitType === "QUESTION" || request.body?.solution?.hasSolutionAfterEachQuestion) {
      request.body.isSkippable = false;
    }
    const { data } = await axios.put(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/exams/${request.id}/options`,
      request.body,
      request.token
    );
    commit("UPDATE_OPTIONS", data);
  },

  async updateOptionsTimeLimit({ commit }, request) {
    const { data } = await axios.put(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/exams/${request.id}/options`,
      request.body,
      request.token
    );
    let payload = {
      data: data,
    };
    commit("UPDATE_OPTIONS_TIME_LIMIT", payload);
  },

  async sectionOptionsSetting({ commit }, request) {
    await axios.patch(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/exams/${request.id}/sections/${request.sectionId}`,
      request.body,
      request.token
    );

    commit("SECTION_OPTIONS_SETTING", request);
  },

  async updateOptionsRandomQuestion({ commit }, request) {
    const { data } = await axios.patch(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/exams/${request.id}`,
      request.body,
      request.token
    );

    commit("UPDATE_EXAM_SECTION_ORDER", data);
  },

  async addNewRandomQuestion({ commit }, request) {
    const { data } = await axios.patch(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/exams/${request.id}`,
      request.body,
      request.token
    );

    commit("UPDATE_EXAM_SECTION_ORDER", data);
  },

  async findExamsSectionbyId({ commit }, id) {
    const { data } = await axios.get(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/exams/${id}`
    );
    commit("FIND_EXAMS_BY_ID", data);
  },

  addCollapsedQuestions({ commit }, id) {
    commit("ADD_COLLAPSED_QUESTION", id)
  },

  toggleCollapsedQuestions({ commit }, id) {
    commit("TOGGLE_COLLAPSED_QUESTION", id)
  },

  setQuestionAnswered({ commit }, questionId) {
    commit("SET_HAS_ANSWER", questionId)
  },

  setQuestionUnanswered({ commit }, questionId) {
    commit("SET_NO_ANSWER", questionId)
  },

  setSolution({ commit }, {questionId, solution}) {
    commit("SET_SOLUTION", {questionId, solution})
  },

  setNoSolution({ commit }, questionId) {
    commit("SET_NO_SOLUTION", questionId)
  },

  async updateExamAccessLimit(_, id) {
    await axios.put(
      `${process.env.VUE_APP_EXAM_API_BASE_URL}/exams/${id}/access-limit`
    );
  },
};
