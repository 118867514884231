<template>
  <Layout>
    <p style="font-family: 'Prompt', sans-serif;">สรุปผู้เรียนรายปี</p>
    <b-row class="mt-3">
      <b-col lg="12" class="ml-2 d-flex align-items-center">
        <span class="mr-3">ข้อมูลรายปี</span>
        <YearPicker
          :value="begin"
          :format="DatePickerFormat"
          minimum-view="year"
          name="datepicker"
          input-class="input-class"
          class="mr-3"
          :disabledDates="{ from: new Date(end) }"
          @input="filterSearch('begin', $event)"
        />
        <span class="mr-3">ถึง</span>
        <YearPicker
          :value="end"
          :format="DatePickerFormat"
          minimum-view="year"
          name="datepicker"
          input-class="input-class"
          class="mr-3"
          :disabledDates="{ to: new Date(begin) }"
          @input="filterSearch('end', $event)"
        />
      </b-col>
    </b-row>
    <br />
    <b-row>
      <b-col md="4">
        <label class="mr-sm-2" for="branch">สาขา</label>
        <div class="mr-sm-2" v-if="hasRoles(['org/admin'])">
          <b-form-select
            id="branch"
            class="mb-2 ml-lg-2 mr-sm-4 mb-sm-0"
            v-model="departmentSelected"
            :options="departmentOptions"
            @input="filterSearch()"
          ></b-form-select>
        </div>
      </b-col>
      <b-col md="4">
        <label class="mr-sm-2" for="group-c">กลุ่มตำแหน่ง </label>
        <div class="mr-sm-2" v-if="hasRoles(['org/admin'])">
          <b-form-select
            id="group-c"
            class="mb-2 ml-lg-2 mr-sm-2 mb-sm-0"
            v-model="groupSelected"
            :options="groupOptions"
            @input="filterSearch()"
          ></b-form-select>
        </div>
      </b-col>
      <b-col md="4">
        <label class="mr-sm-2" for="courseName">ค้นหาคอร์ส </label>
        <v-select
          v-if="courses != null"
          :options="courses"
          label="courseName"
          v-model="courseData"
          placeholder="กรุณาพิมพ์เพื่อค้นหาชื่อหลักสูตร..."
          :clearable="false"
          @input="filterSearch()"
        />
        <v-select v-else placeholder="กำลังโหลดข้อมูล..." />
      </b-col>
    </b-row>
    <br />
    <div :key="reloadKey">
      <b-row>
        <TotalLearningStatByHour
          :begin="begin"
          :end="end"
          :dep="departmentSelected"
          :group="groupSelected"
          :course="courseData.courseId"
          statType="year"
        />
      </b-row>
      <br />
      <b-row>
        <b-col lg="6">
          <TotalLearningStatByPeriod
            :begin="begin"
            :end="end"
            :dep="departmentSelected"
            :group="groupSelected"
            :course="courseData.courseId"
            statType="year"
          />
        </b-col>
        <b-col lg="6">
          <TotalLearningStatByDay
            :begin="begin"
            :end="end"
            :dep="departmentSelected"
            :group="groupSelected"
            :course="courseData.courseId"
            statType="year"
          />
        </b-col>
      </b-row>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/components/pages/analytics/shared/Layout.vue";
import TotalLearningStatByHour from "@/components/pages/analytics/user/overview/panel/TotalLearningStatByHour.vue";
import TotalLearningStatByPeriod from "@/components/pages/analytics/user/overview/panel/TotalLearningStatByPeriod.vue";
import TotalLearningStatByDay from "@/components/pages/analytics/user/overview/panel/TotalLearningStatByDay.vue";
import api from "@/services/api.js";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import YearPicker from "vuejs-datepicker";

export default {
  components: {
    Layout,
    TotalLearningStatByHour,
    TotalLearningStatByPeriod,
    TotalLearningStatByDay,
    vSelect,
    YearPicker
  },
  props: {
    company: Object
  },
  data() {
    return {
      comId: this.$store.state.Company.companyInfo.id,
      departmentSelected: "*",
      departmentOptions: [],
      groupSelected: "*",
      groupOptions: [],
      userRoles: [],
      courses: null,
      courseData: "กรุณาพิมพ์เพื่อค้นหาชื่อหลักสูตร...",
      begin: new Date().toISOString(),
      end: new Date().toISOString(),
      reloadKey: 0,
      DatePickerFormat: "yyyy"
    };
  },
  async created() {
    this.setUserRole();
    this.setDepartmentOptions();
    this.setGroupOptions();
    await api.get(`v1/companies/${this.comId}/courses`).then(({ data }) => {
      this.courses = data.data;
    });
  },
  methods: {
    setUserRole() {
      this.userRoles = this.$store.state.Authentication.authUser.roles
        .filter(el => el.companyId === this.company.id)
        .filter(
          el =>
            el.roleName.substring(0, 5) === "tutor" ||
            el.roleName.substring(0, 3) === "org"
        )
        .map(el => el.roleName);
    },
    hasRoles(roles) {
      for (let role of roles) {
        if (this.userRoles.includes(role)) return true;
      }
      return false;
    },
    async setDepartmentOptions() {
      try {
        // Fetch the company departments from backend
        let results = await api.get(
          `/v1/companies/${this.company.id}/departments`
        );
        this.departmentOptions = results.data.map(el => {
          // Handle null case
          if (!el) {
            return { value: "null", text: "ไม่มี" };
          }
          return { value: el.replace(/ /g, "!"), text: el };
        });
        // Add the total option in the first place
        this.departmentOptions.unshift({
          value: "*",
          text: "ทั้งหมด"
        });
      } catch (err) {
        console.log("setDepartmentOptions error", err);
      }
    },
    async setGroupOptions() {
      try {
        // Fetch the company groups from backend
        let results = await api.get(`/v1/companies/${this.company.id}/groups`);
        this.groupOptions = results.data.data.map(el => {
          return { value: el.id + "", text: el.name };
        });
        // Add the total option in the first place
        this.groupOptions.unshift({
          value: "*",
          text: "ทั้งหมด"
        });
      } catch (err) {
        console.log("setGroupOptions error", err);
      }
    },
    async filterSearch(type = null, value = null) {
      if (type == "begin") {
        this.begin = value.toISOString();
      } else if (type == "end") {
        this.end = value.toISOString();
      }
      this.reloadKey += 1;
    }
  }
};
</script>