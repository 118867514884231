<template>
  <div>
    <PageHeader title="การตรวจสอบใบหน้า"></PageHeader>
    <b-container fluid="xl">
      <div class="py-5 text-font">
        <div class="inner bg-container">
          <b-card no-body class="no-shadow border-0">
            <b-tabs>
              <b-tab title="ใบหน้าของผู้เรียนทั้งหมดในระบบ">
                <VerifyMemberAll :company="company" />
              </b-tab>
              <b-tab title="ใบหน้าระหว่างเรียนในแต่ละคอร์ส">
                <VerifyMemberByCourse :company="company" />
              </b-tab>
              <b-tab title="รายงานการยืนยันตัวตนขณะเรียน">
                <VerifyMemberByMember :company="company" />
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import PageHeader from "@/components/layouts/PageHeader.vue";
import VerifyMemberAll from "./panel/VerifyMemberFaceAll.vue";
import VerifyMemberByCourse from "./panel/VerifyMemberByCourse.vue";
import VerifyMemberByMember from "./panel/VerifyMemberByMember.vue";

export default {
  name: "VerifyMemberFacePage",
  components: {
    PageHeader,
    VerifyMemberAll,
    VerifyMemberByCourse,
    VerifyMemberByMember
  },
  props: ["company"],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
#verify-mmember-course-list-table th {
  border-top: 0px !important;
}
#verify-member label {
  font-size: 14px;
}
</style>
