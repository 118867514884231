<template>
  <b-container fluid="xl">
    <layout>
      <template v-slot:header
        >เวลาเรียนรวม - Accumulated Learning Time</template
      >
      <template v-slot:body>
        <span v-if="max == null"
          >เรียนไปแล้ว {{ Math.round(result.learning_time) }} ชั่วโมง</span
        >
        <span v-if="max != null"
          >เรียนไปแล้ว 
         {{ Math.round((result.learning_time / max) * 100) }} %
          ({{ Math.round(result.learning_time) }}/{{ max }} ชั่วโมง)</span
        >
        <span style="float:right" v-if="max == null">
          คงเหลือ Unlimited </span>
        <span style="float:right" v-if="max != null">
          คงเหลือ
          {{
            max - result.learning_time >= 0
              ? Math.round(((max - result.learning_time) / max) * 100)
              : 0
          }}
          % ({{
            Math.round(
              max - result.learning_time >= 0 ? max - result.learning_time : 0
            )
          }}
          ชั่วโมง)</span
        >
        <b-progress
          :value="result.learning_time"
          :max="max"
          variant="warning"
          height="1.5rem"
          class="mt-2"
          v-if="result.learning_time <= max"
        ></b-progress>
        <b-progress
          height="1.5rem"
          class="mt-2"
          :max="result.learning_time"
          v-if="result.learning_time > max"
        >
          <b-progress-bar :value="max" variant="warning"></b-progress-bar>
          <b-progress-bar v-if="max == null"
            :value="result.learning_time - max"
            variant="info"
          ></b-progress-bar>
          <b-progress-bar v-if="max != null"
            :value="result.learning_time - max"
            variant="danger"
          ></b-progress-bar>
        </b-progress>
        <span v-if="max == null"> Unlimited </span>
        <span v-if="max != null"> {{ max }} ชั่วโมง</span>
      </template>
    </layout>
  </b-container>
</template>

<script>
import layout from "@/components/pages/analytics/shared/Layout";
import api from "@/services/api.js";
export default {
  components: {
    layout,
  },
  props: ["apiRoute"],
  data() {
    return {
      company: {},
      result: {},
      isBusy: true,
      max: null,
    };
  },

  async mounted() {
    this.isBusy = true;
    let res = await api.get(this.apiRoute);
    this.result = res.data;
    this.max = this.result.max;
    setTimeout(() => {
      this.isBusy = false;
    }, 1 * 1000);
    this.company = this.$store.state.Company.companyInfo;
  },
};
</script>
<style scoped>
h6 {
  margin-bottom: 0 !important;
}
</style>
