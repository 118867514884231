<template>
  <div>
    <page-header
      :title="`ความก้าวหน้าในแต่ละหลักสูตรของ ${
        memberInfo ? memberInfo.firstName + ' ' + memberInfo.lastName : ''
      }`"
    ></page-header>
    <layout class="m-4">
      <template v-slot:header> </template>
      <template v-slot:body>
        <b-row>
          <b-col cols="10"> </b-col>
          <b-col cols="2" class="text-right">
            <b-button @click="exportData()" class="btn btn-download" href=""
              ><i class="fa fa-angle-double-down" aria-hidden="true"></i
              >ดาวน์โหลดรายงาน</b-button
            >
          </b-col>
        </b-row>
        <div>
          <b-table
            table-class="tbNumber text-left "
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            small
            sort-icon-left
            responsive
            :busy="isLoading"
            hover
            striped
          >
            <template v-slot:cell(courseName)="data">
              <span>{{ data.item.c_name | cut }}</span>
            </template>
            <template v-slot:cell(enrollment)="data">
              <span v-if="data.item.cer_status == 'NOT_STARTED'"
                >ยังไม่เริ่มเรียน</span
              >
              <span v-else-if="data.item.cer_status == 'IN_PROGRESS'"
                >กำลังเรียน ({{ data.item.lel_completed }})</span
              >
              <span v-else-if="data.item.cer_status == 'COMPLETED'"
                >เรียนจบแล้ว</span
              >
              <span v-else-if="data.item.cer_status == 'TIMEOUT'"
                >จำนวนชั่วโมงหมด</span
              >
            </template>
            <template v-slot:cell(courseEndDate)="data">
              <span>{{ data.item.cer_endDate | thaiDateFilter }}</span>
            </template>
            <template v-slot:cell(completeRule)="data">
              <span
                class="complete-rule-link"
                v-b-modal="`modal-complete-rule-${data.item.cer_id}`"
              >
                <span>{{ data.item.completeRuleStatus }}</span>
              </span>
              <b-modal size="xl" :id="`modal-complete-rule-${data.item.cer_id}`">
                <template v-slot:modal-title>
                  <span>เกณฑ์การจบคอร์ส {{ data.item.c_name }}</span
                  ><br />
                  <span>ของ {{ memberInfo.profiles[0].fullName }}</span>
                </template>
                <complete-rule-modal
                  :apiRoute="`/v1/members/${memberId}/course/${data.item.c_id}/complete-rules?cerId=${data.item.cer_id}`"
                ></complete-rule-modal>
              </b-modal>
            </template>
            <template v-slot:cell(courseStatus)="data">
              <span v-if="data.item.cer_isExpired" style="color: red"
                >หมดอายุ</span
              >
              <span v-else-if="data.item.cer_status == 'TIMEOUT'" style="color: red">จำนวนชั่วโมงหมด</span>
              <span v-else style="color: green">เข้าเรียนได้</span>
            </template>
            <template v-slot:cell(info)="data">
              <b-button class="mr-2" size="sm" variant="outline-primary" @click="redirectToLearningHistoryEnroll(data.item.cer_id)">
                  ดูประวัติ
              </b-button>
              <b-button size="sm" variant="outline-primary">
                <a
                  :href="`${$route.path}/${data.item.cer_id}/courses/${data.item.c_id}/overview`"
                  target="_blank"
                >
                  ดูข้อมูล
                </a>
              </b-button>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
        </div>
        <div class="d-flex justify-content-end">
          <b-pagination
            class="mr-4"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="recent-study-table"
          ></b-pagination>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
import PageHeader from "@/components/layouts/PageHeader.vue";
import layout from "@/components/pages/analytics/shared/Layout";
import dateMixins from "@/components/pages/analytics/shared/mixins/date.js";
import timeMixins from "@/components/pages/analytics/shared/mixins/time.js";
import CompleteRuleModal from "./panel/CompleteRuleModal.vue";
import api from "@/services/api.js";
import exp from "@/services/export.js";
export default {
  components: {
    PageHeader,
    layout,
    CompleteRuleModal
  },
  mixins: [dateMixins, timeMixins],
  data() {
    return {
      memberId: null,
      memberInfo: null,
      companyInfo: null,
      isLoading: false,
      fields: [
        { key: "courseName", label: "คอร์สเรียน" },
        { key: "enrollment", label: "การเข้าเรียน" },
        { key: "courseEndDate", label: "วันหมดอายุคอร์ส" },
        { key: "completeRule", label: "ผ่านเกณฑ์การจบคอร์ส" },
        { key: "courseStatus", label: "สถานะคอร์ส" },
        { key: "info", label: "" }
      ],
      items: [],
      currentPage: 1,
      perPage: 10
    };
  },
  computed: {
    rows() {
      return this.items.length;
    }
  },
  created() {
    this.setMemberId();
    this.setCompanyInfo();
    this.fetchMemberInfo();
    this.fetchMemberEachCourseProgress();
  },
  methods: {
    setMemberId() {
      this.memberId = this.$route.params.mId;
    },

    async exportData() {
      let value = [];
      let set = this.items;
      set.forEach(i => {
        let temp = {
          courseName: i.c_name,
          enrollment:
            i.cer_status == "NOT_STARTED"
              ? "ยังไม่เริ่มเรียน"
              : i.cer_status == "IN_PROGRESS"
              ? "กำลังเรียน (" + i.lel_completed + ")"
              : i.cer_status == "COMPLETED"
              ? "เรียนจบแล้ว"
              : "-",
          courseEndDate: this.thaiDateFilter(i.cer_endDate),
          completeRule: `${i.completeRuleStatus} เกณฑ์`,
          courseStatus: i.cer_isExpired ? "หมดอายุ" : "เข้าเรียนได้"
        };
        value.push(temp);
      });
      let key = [
        "คอร์สเรียน",
        "การเข้าเรียน",
        "วันหมดอายุคอร์ส",
        "ผ่านเกณฑ์การจบคอร์ส",
        "สถานะคอร์ส"
      ];
      let filename = "learn-progress-" + this.companyInfo.url;
      const data = {
        key: key,
        filename: filename,
        data: value
      };
      await exp.exportData(data);
    },
    thaiDateFilter(value) {
      if (value && !Date.parse(value)) {
        return value;
      } else if (value) {
        const yearMonthDate = value.split(" ")[0].split("-");
        const thaiYear = parseInt(yearMonthDate[0], 10) + 543;
        const thaiMonth = [
          "ม.ค.",
          "ก.พ.",
          "มี.ค.",
          "เม.ย.",
          "พ.ค.",
          "มิ.ย.",
          "ก.ค.",
          "ส.ค.",
          "ก.ย.",
          "ต.ค.",
          "พ.ย.",
          "ธ.ค."
        ][parseInt(yearMonthDate[1], 10) - 1];
        return `${yearMonthDate[2]} ${thaiMonth} ${thaiYear}`;
      }
      return "-";
    },
    async fetchMemberInfo() {
      try {
        const endpoint = `/v1/members/${this.memberId}`;
        const results = await api.get(endpoint);
        this.memberInfo = results.data.data;
      } catch (err) {
        console.log("fetchMemberInfo error", err);
      }
    },
    setCompanyInfo() {
      this.companyInfo = { ...this.$store.state.Company.companyInfo };
    },
    async fetchMemberEachCourseProgress() {
      this.isLoading = true;
      try {
        let results = await api.get(
          `/v1/members/${this.memberId}/each-course-progress`
        );
        for (let element of results.data) {
          element.completeRuleStatus = await this.getCompleteRuleStatus(
            element.c_id,
            element.cer_id
          );
        }
        this.items = results.data;
      } catch (err) {
        console.log("fetchMemberEachCourseProgress error", err);
      }
      this.isLoading = false;
    },
    async getCompleteRuleStatus(courseId, cerId) {
      const endpoint = `/v1/members/${this.memberId}/course/${courseId}/complete-rules?cerId=${cerId}`;
      let answer = null;
      try {
        let res = await api.get(endpoint);
        if (res.data.length > 0) {
          let countSuccess = res.data.filter(el => el.rulePass).length;
          let countTotal = res.data.length;
          answer = `${countSuccess} / ${countTotal}`;
        } else {
          answer = "ไม่มีเกณฑ์การจบ";
        }
      } catch (err) {
        console.log("getCompleteRuleStatus error!", err);
        answer = "error";
      }
      return answer;
    },
    redirectToLearningHistoryEnroll(enrollId) {
      const route = this.$router.resolve({
        name: "report.learning-history-report.enrolls",
        params: {cerId: enrollId}
      });
      window.open(route.href, "_blank");
    }
  }
};
</script>

<style scoped>
.progress-bar {
  background-color: #eb8123 !important;
}
.complete-rule-link {
  color: #007bff;
  cursor: pointer;
}
.complete-rule-link:hover {
  color: #007bff;
  opacity: 0.6;
  cursor: pointer;
}
</style>
