<template>
  <b-container fluid="xl">
    <layout>
      <template v-slot:header>ผู้เข้าเรียนรายวัน - Daily Active User</template>
      <template v-slot:body>
        <b-row class="justify-content-end mb-3 pr-0">
          <b-col cols="3" class="pr-0">
            <month-year-picker
              id="dpicker"
              class="mb-2 mr-sm-2 mb-sm-0 pr-0"
              v-model="date"
              style="width:100%"
            ></month-year-picker>
          </b-col>
        </b-row>

        <bar-chart
          :chart-data="data"
          :options="options"
          :height="300"
          :key="componentKey"
        ></bar-chart>
      </template>
    </layout>
  </b-container>
</template>

<script>
import Layout from "@/components/pages/analytics/shared/Layout.vue";
import BarChart from "@/components/charts/BarChart.js";
import MonthYearPicker from "@/components/pages/analytics/shared/MonthYearPicker.vue";
import moment from "moment";
import chartMockMixin from "@/mixins/chart-mock-mixin.js";
import api from "@/services/api.js";
export default {
  mixins: [chartMockMixin],
  components: {
    BarChart,
    Layout,
    MonthYearPicker,
  },
  props: ["apiRoute"],
  data() {
    return {
      componentKey: 0,
      max: null,
      date: moment().format("YYYY/MM"),
      result: {},
      data: {},
      options: {
        tooltips: {
          callbacks: {
            title: function(tooltipItems) {
              if (!tooltipItems.length) return "";
              
              let day = tooltipItems[0].label;
              let selectedMonthYear = moment().locale("th").format("MMMM YYYY");

              return `${day} ${selectedMonthYear}`;
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: { display: false },
        plugins: {
          datalabels: {
            display: false,
          },
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: true,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: true,
              },
              ticks: {
                callback: function(value) {
                  return value;
                },
                beginAtZero: true,
                maxTicksLimit: 20,
                min: 0,
                stepSize: 1,
              },
            },
          ],
        },
      },
    };
  },
  methods: {
    async fetch() {
      this.isBusy = true;
      let res = await api.get(
        this.apiRoute + "?date=" + (this.date._i ? this.date._i : this.date)
      );
      this.max = res.data.max;
      this.result = res.data.data;
      this.mockBarChart(res.data.data);
      this.data = {
        labels: [],
        datasets: [
          {
            data: Object.values(this.result).map((el) =>
              this.max === null || el.count <= this.max ? el.count : this.max
            ),
            label: "จำนวนผู้ใช้งาน",
            backgroundColor: "#17A2B8",
          },
          {
            data: Object.values(this.result).map((el) =>
              this.max === null || el.count <= this.max ? 0 : el.count - this.max
            ),
            label: "จำนวนผู้ใช้งานเกินกำหนด",
            backgroundColor: "#E78C06",
          },
        ],
      };
      this.result.forEach((element) => {
        this.data.labels.push(element.day);
      });
      setTimeout(() => {
        this.isBusy = false;
      })
    },
    async updateChartTooltip() {
      let date = this.date.locale("th").format("MMMM YYYY");

      this.options.tooltips.callbacks = {
        title: function(tooltipItems) {
          if (!tooltipItems.length) return "";
          let day = tooltipItems[0].label;
          let selectedMonthYear = date;

          return `${day} ${selectedMonthYear}`;
        },
      };
      this.componentKey += 1;
    },
  },
  watch: {
    async date() {
      this.updateChartTooltip();
      this.fetch();
    },
  },
  async mounted() {
    this.fetch();
  },
};
</script>
<style scoped></style>
