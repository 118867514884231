<template>
  <div class>
    <layout>
      <template v-slot:header v-if="perPage == 10">
        <b-row>
          <b-col cols="6">
            <h6>
              ข้อสอบแบ่งออกเป็น
              {{ result && result.length }} ตอน
            </h6>
          </b-col>
        </b-row>
      </template>
      <template v-slot:body>
        <div class>
          <b-table
            striped
            id="exam-section-table"
            :per-page="perPage"
            :current-page="currentPage"
            table-class="tbNumber text-left "
            :items="result"
            :fields="fields"
            small
            sort-icon-left
            responsive
            :busy="isBusy"
            hover
          >
            <template v-slot:cell(sectionId)="data">
              <span>{{ data.index + 1 }}</span>
            </template>

            <template v-slot:cell(sectionName)="data">
              <span>{{ data.item.sectionTitle }}</span>
            </template>

            <template v-slot:cell(questionCount)="data">
              <span>{{
                data.item.result && data.item.result.totalQuestion
              }}</span>
            </template>

            <template v-slot:cell(totalScore)="data">
              <span>{{ data.item.result && data.item.result.fullScore }}</span>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="exam-section-table"
            v-if="rows > 0"
          ></b-pagination>
        </div>
      </template>
    </layout>
  </div>
</template>
<script>
import layout from "@/components/pages/analytics/shared/Layout";
import api from "@/services/exam-api.js";
export default {
  components: {
    layout
  },
  props: ["apiRoute", "perPage"],
  data() {
    return {
      currentPage: 1,
      rows: 0,
      result: [],
      isBusy: true,
      fields: [
        { key: "sectionId", label: "ตอนที่" },
        { key: "sectionName", label: "ชื่อตอน" },
        { key: "questionCount", label: "จำนวนคำถาม" },
        { key: "totalScore", label: "คะแนนของตอน" }
      ],
      items: [],
      company: {}
    };
  },
  methods: {
    getRandomInt(max, min) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
  },
  async mounted() {
    let res = await api.get(this.apiRoute);
    this.result = res.data && res.data.sections;
    this.rows = this.result.length;
    setTimeout(() => {
      this.isBusy = false;
    }, 1 * 1000);
    this.company = this.$store.state.Company.companyInfo;
  }
};
</script>
<style scoped>
h6 {
  font-family: "Prompt", sans-serif;
}
</style>
