import Chart from "chart.js"; // Import Chart.js

export default {
  methods: {
    async generateChart() {
      const canvas = document.getElementById("chartCanvas");
      const ctx = canvas.getContext("2d");

      // Clear the canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Define your chart data here
      const chartData = {
        labels: this.data.ExamResult.map(result => result.lec_name),
        datasets: [
          {
            label: "Scores",
            data: this.data.ExamResult.map(result => result.lel_examResultPercent),
            backgroundColor: this.data.ExamResult.map(() => "rgba(255, 111, 49, 1)"), // Set all data points to the same color
            borderColor: this.data.ExamResult.map(() => "rgba(255, 111, 49, 1)"), // Set all data points to the same color
            borderWidth: 1,
          },
        ],
      };

      const bgColor = {
        id: 'bgColor',
        beforeDraw:(chart) => {
          const {ctx, width, height} = chart;
          ctx.fillStyle='white'
          ctx.fillRect(0,0,width,height)
          ctx.restore()
        }
      }

      // Create the Chart.js chart with horizontal bars
      new Chart(ctx, {
        type: "horizontalBar",
        data: chartData,
        options: {
          legend: {
              display: false
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  fontSize: 18, // Change font size for X-axis labels
                  fontColor: "black", // Change font color for X-axis labels
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontSize: 18, // Change font size for Y-axis labels
                  fontColor: "black", // Change font color for Y-axis labels
                },
              },
            ],
          },
          animation: {
            duration: 0, // Set the animation duration to 0 to disable it
          },
        },
        plugins: [bgColor]
      });
    },
    classStatus(pdf, status, l, y){
      switch (status) {
        case "PRESENT":
          this.thaitext(pdf, "เข้าเรียน",l, y);
          break;
        case "LATE":
          this.thaitext(pdf, "มาสาย", l + 1, y);
          break;
        case "ABSENT":
          this.thaitext(pdf, "ขาดเรียน", l - 1, y);
          break;
        case "LEAVE":
          this.thaitext(pdf, "ลา", l + 6, y);
          break;
        default:
          this.thaitext(pdf, "-", l + 6, y);
      }        
    },
    formatDateToThai(dateString, haveEra = true) {
      const [datePart] = dateString.split(' ');
      const [year, month, day] = datePart.split('-').map(Number);

      const thaiDate = `${day} ${this.thaiMonths[month - 1]} ${haveEra ? "พ.ศ. " : ""} ${year + 543}`;

      return thaiDate;
    },

    formatDateToThaiShort(dateString) {
      const monthsInThai = [
        'ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.',
        'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'
      ];

      const [year, month, day] = dateString.split('-');
      const thaiYear = parseInt(year) + 543; // Convert to Thai year
      const thaiMonth = monthsInThai[parseInt(month) - 1];

      return `${parseInt(day)} ${thaiMonth} ${thaiYear}`;
    },

    formatDateToThaiShortYear2Digits(dateString) {
      const monthsInThai = [
        'ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.',
        'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'
      ];
    
      const [year, month, day] = dateString.split('-');
      const thaiYear = (parseInt(year) + 543) % 100; // Convert to Thai year and get last two digits
      const thaiMonth = monthsInThai[parseInt(month) - 1];
      const formattedDay = day.padStart(2, '0'); // Add leading zero if day has one digit
    
      return `${formattedDay} ${thaiMonth} ${thaiYear}`;
    },
    

    formatThaiMonthRange(start, end) {
      // Convert input strings to Date objects
      const startDate = new Date(start);
      const endDate = new Date(end);

      // Format the first and last days in Thai date format
      const thaiFirstDay = `${startDate.getDate()} ${
        this.thaiMonths[startDate.getMonth()]
      } ${startDate.getFullYear() + 543}`;

      endDate.setMonth(endDate.getMonth() + 1);
      endDate.setDate(0);
      const thaiLastDay = `${endDate.getDate()} ${
        this.thaiMonths[endDate.getMonth()]
      } ${endDate.getFullYear() + 543}`;

      return `${thaiFirstDay} - ${thaiLastDay}`;
    },

    thaitext(doc, str, x, y, width = 0) {
      var sara = ["่", "้", "๊", "๋", "์"];
      var pushers = ["ิ", "ี", "ึ", "ื", "ำ", "ั"];
      var base = "";
      var dim = doc.getTextDimensions(str);
      var len = doc.getTextWidth(base);
      for (var i = 0; i < str.length; i++) {
        var c = str.charAt(i);
        if (sara.indexOf(c) < 0) {
          base += c;
        } else {
          var pusher = base.charAt(base.length - 1);
          if (pushers.indexOf(pusher) < 0) {
            if (str.charAt(i + 1) != "" && str.charAt(i + 1) == "ำ") {
              // next char is ำ
              len = doc.getTextWidth(base + "ำ");
              doc.text(c, x + len, y - dim.h / 4);
            } else {
              base += c;
            }
          } else {
            len = doc.getTextWidth(base);
            doc.text(c, x + len, y - dim.h / 4);
          }
        }
      }
      if ( width > 0 ) {
        doc.text(base, x, y, { maxWidth: width, lineHeightFactor: 1.3 });
      } else {
        doc.text(base, x, y);
      }
    },

    secondsToHHMM(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);

      // Ensure that hours and minutes are displayed with two digits
      const formattedHours = hours.toString().padStart(2, '0');
      const formattedMinutes = minutes.toString().padStart(2, '0');

      return `${formattedHours}:${formattedMinutes}`;
    },
    countPresent() {
      return this.data.classAttendanceStat.filter(
        (stat) => stat.clsa_status === "PRESENT"
      ).length;
    },
    countLate() {
      return this.data.classAttendanceStat.filter(
        (stat) => stat.clsa_status === "LATE"
      ).length;
    },
    countAbsent() {
      return this.data.classAttendanceStat.filter(
        (stat) => stat.clsa_status === "ABSENT"
      ).length;
    },
    countLeave() {
      return this.data.classAttendanceStat.filter(
        (stat) => stat.clsa_status === "LEAVE"
      ).length;
    },
    classAttendanceStat(status) {
      return (
        status === "PRESENT" ? "เข้าเรียน" :
        status === "LATE" ? "มาสาย" :
        status === "ABSENT" ? "ขาดเรียน" :
        status === "LEAVE" ? "ลา" :
        "-"
      );
    },
    async loadImage(url) {
      return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = url;
          
          img.onload = () => resolve(img);
          img.onerror = () => reject(new Error("Image failed to load"));
      });
    }
  }
}