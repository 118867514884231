<template>
  <div class>
    <layout>
      <template v-slot:header v-if="perPage == 10">
        <b-row>
          <b-col cols="6">
            <h6 style="font-family: 'Prompt', sans-serif;">อันดับบทเรียน</h6>
          </b-col>
          <b-col cols="6" class="text-right">
            <a
              :href="`/${companyInfo.url}/analytics/courses/${cId}/lecture-ranking`"
              target="_blank"
              >ดูทั้งหมด</a
            >
          </b-col>
        </b-row>
      </template>
      <template v-slot:body>
        <div class>
          <div v-if="perPage > 10" class="mb-3">
            <b-row>
              <b-col cols="10">
                <h5 style="font-weight: bold">
                  {{ courseInfo.data.courseName }}
                </h5>
                <b-form-input
                  v-model="search"
                  placeholder="ค้นหาบทเรียน"
                  style="padding: 1rem; display: inline-block; width: 20%"
                >
                </b-form-input>
              </b-col>
              <b-col cols="2" class="text-right">
                <b-button @click="exportData()" class="btn btn-download" href=""
                  ><i class="fa fa-angle-double-down" aria-hidden="true"></i>ดาวน์โหลดรายงาน</b-button
                >
              </b-col>
            </b-row>
          </div>
          <b-table
            striped
            id="course-lecture-ranking-table"
            :per-page="perPage"
            :current-page="currentPage"
            table-class="tbNumber text-left "
            :items="
              search == null
                ? result
                : result.filter((i) => i.lec_name.includes(search))
            "
            :fields="fields"
            small
            sort-icon-left
            responsive
            :busy="isBusy"
            hover
          >
            <template v-slot:cell(rankingNo)="data"
              ><span>{{
                (data.field.value = data.field.value + 1)
              }}</span></template
            >
            <template v-slot:cell(lectureName)="data"
              ><span>{{ data.item.lec_name | cut(10) }}</span></template
            >
            <template v-slot:cell(totalCount)="data">
              <span style="float: right"
                >{{ data.item.totalCount ? data.item.totalCount : 0 }} คน</span
              >
            </template>

            <template v-slot:cell(completeCount)="data">
              <span style="float: right">{{ data.item.completeCount }} คน</span>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="
              search == null
                ? rows
                : result.filter((i) => i.lec_name.includes(search)).length
            "
            :per-page="perPage"
            aria-controls="course-lecture-ranking-table"
            v-if="perPage > 10"
          ></b-pagination>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
import layout from "@/components/pages/analytics/shared/Layout";
import api from "@/services/api.js";
import exp from "@/services/export.js";
export default {
  components: {
    layout,
  },
  props: ["apiRoute", "perPage"],
  data() {
    return {
      companyId: this.$store.state.Company.companyInfo.id,
      companyInfo: this.$store.state.Company.companyInfo,
      cId: this.$route.params.cId,
      search: null,
      currentPage: 1,
      rows: 0,
      result: [],
      isBusy: true,
      fields: [
        { key: "rankingNo", label: "อันดับที่", value: 0 },
        { key: "lectureName", label: "ชื่อบทเรียน" },
        {
          key: "totalCount",
          label: "จำนวนผู้เรียนทั้งหมด",
          class: "text-right",
        },
        {
          key: "completeCount",
          label: "จำนวนผู้เรียนที่เรียนจบ",
          class: "text-right",
        },
      ],
      items: [],
      courseInfo: [],
    };
  },
  methods: {
    async exportData() {
      let value = [];
      let set =
        this.search == null
          ? this.result
          : this.result.filter((i) => i.lec_name.includes(this.search));
      set.forEach((i) => {
        let temp = {
          name: i.lec_name,
          totalCount: i.totalCount ? i.totalCount + " คน" : "0 คน",
          completeCount: i.completeCount ? i.completeCount + " คน" : "0 คน",
        };
        value.push(temp);
      });
      let key = [
        "ชื่อบทเรียน",
        "จำนวนผู้เรียนทั้งหมด",
        "จำนวนผู้เรียนที่เรียนจบ",
      ];
      let filename = "Individual-Course-Lecture-Ranking" + this.companyInfo.url;
      const data = {
        key: key,
        filename: filename,
        data: value,
      };
      await exp.exportData(data);
    },
    getRandomInt(max, min) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
  },
  async mounted() {
    await api.get("/v1/courses/" + this.cId).then(({ data }) => {
      this.courseInfo = data;
    });
    this.isBusy = true;
    let res = await api.get(this.apiRoute);
    this.result = res.data;
    this.rows = this.result.length;
    setTimeout(() => {
      this.isBusy = false;
    }, 1 * 1000);
    this.items = this.$store.getters.data;
  },
};
</script>
<style scoped>
h5 {
  font-family: "Prompt", sans-serif;
}
</style>
