<template>
  <div>
    <PageHeader
      :title="`ประวัติอนุมัติการจบคอร์ส ${courseCompletionInfo.c_name}`"
      style="margin-left: -20%"
    />
    <b-container class="outer" fluid>
      <div class="inner bg-container">
        <div class="card no-shadow">
          <div class="card-header bg-white">
            <b-row>
              <b-col cols="12">
                <b-media>
                  <template #aside>
                    <b-img
                      :src="baseUrl + courseCompletionInfo.c_thumbnailPath"
                      width="125"
                      alt="placeholder"
                    ></b-img>
                  </template>
                  <h4>{{ courseCompletionInfo.c_name }}</h4>
                  <h6>
                    By
                    {{
                      this.formatName(
                        this.courseCompletionInfo.mp_prefixName,
                        this.courseCompletionInfo.mp_firstName,
                        this.courseCompletionInfo.mp_lastName
                      )
                    }}
                  </h6>
                  <h6>{{ courseCompletionInfo.c_name }}</h6>
                </b-media>
              </b-col>
            </b-row>
          </div>
          <div class="card-body pt-4">
            <b-row>
              <b-col cols="3">
                <SearchBox
                  title="พิมพ์ชื่อผู้เรียนหรืออีเมลที่ต้องการค้นหา"
                  size="sm"
                  @typed="getSearchKeyword"
                />
              </b-col>
              <b-col cols="3">
                <b-input-group size="sm">
                  <b-form-input
                    type="text"
                    :value="formattedDate"
                    placeholder="ระบุวันที่ต้องการค้นหา"
                    disabled
                  />
                  <b-input-group-prepend>
                    <b-button
                      variant="primary"
                      size="sm"
                      @click="showCalendarRange()"
                    >
                      <b-icon
                        icon="calendar3-range"
                        style="color: #fff;"
                      ></b-icon>
                    </b-button>
                  </b-input-group-prepend>
                </b-input-group>
                <DatePicker
                  :id="'calendar-range'"
                  locale="th"
                  style="
                        display: none;
                        float: right;
                        position: absolute;
                        will-change: transform;
                        left: 0px;
                      "
                  v-model="range"
                  is-range
                />
              </b-col>
              <b-col cols="2">
                <b-form-select
                  v-model="selectStatusType"
                  :options="statusOptions"
                  size="sm"
                ></b-form-select>
              </b-col>
            </b-row>

            <b-table
              id="course-completed-log"
              :items="tableData"
              :fields="fields"
              :sort-by.sync="sort.by"
              :sort-desc.sync="sort.desc"
              sort-icon-left
              hover
              responsive
              table-class="mt-4 border-bottom"
            >
              <template v-slot:cell(date)="props">
                <h6>{{ props.item.timestamp | thaiDateTimeFilter }}</h6>
              </template>
              <template v-slot:cell(learnerName)="props">
                <h6>
                  {{
                    formatName(
                      props.item.mp_prefixName,
                      props.item.mp_firstName,
                      props.item.mp_lastName
                    )
                  }}
                </h6>
                <h6>{{ props.item.m_email }}</h6>
              </template>
              <template v-slot:cell(actionType)="props">
                <h6
                  v-if="props.item.action == 'APPROVED'"
                  class="approve-status"
                >
                  อนุมัติ
                </h6>
                <h6 v-if="props.item.action == 'REJECT'" class="not-approve">
                  ไม่อนุมัติ
                </h6>
              </template>
              <template v-slot:cell(adminName)="props">
                <h6 v-if="props.item.is_auto_approve == 1">
                  ระบบ
                </h6>
                <h6 v-else>
                  {{
                    formatName(
                      props.item.admin_prefixName,
                      props.item.admin_firstName,
                      props.item.admin_lastName
                    )
                  }}
                </h6>
              </template>
            </b-table>
            <b-row>
              <b-col cols="6">
                <div class="d-flex justify-content-start align-items-center">
                  <p class="pr-3">แสดง</p>
                  <b-form-select
                    v-model="perPage"
                    :options="[5, 10, 15, 20]"
                    style="width: 75px;"
                  ></b-form-select>
                  <p class="pl-3">จาก {{ rows }} รายการ</p>
                </div>
              </b-col>
              <b-col cols="6">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  align="right"
                  aria-controls="course-completed-log"
                  last-number
                ></b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PageHeader from "@/components/layouts/PageHeader";
import SearchBox from "@/components/shared/SearchBox";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import date from "@/components/pages/analytics/shared/mixins/date";
import moment from "moment";

export default {
  mixins: [date],
  components: { PageHeader, SearchBox, DatePicker },
  data() {
    return {
      courseId: this.$route.params.courseId,
      baseUrl: process.env.VUE_APP_S1_URL || "https://s1-dev.coursesquare.com/",
      range: {
        start: "",
        end: "",
      },
      selectStatusType: "NOT_SELECT",
      statusOptions: [
        { value: "NOT_SELECT", text: "สถานะการดำเนินการ" },
        { value: "APPROVED", text: "อนุมัติ" },
        { value: "REJECT", text: "ไม่อนุมัติ" },
      ],
      fields: [
        { label: "วันที่ตรวจสอบ", key: "date", sortable: true },
        { label: "ชื่อผู้เรียน", key: "learnerName", sortable: true },
        { label: "การดำเนินการ", key: "actionType" },
        { label: "ชื่อผู้ตรวจสอบ", key: "adminName" },
      ],
      sort: {
        by: "timestamp",
        desc: false,
      },
      currentPage: 1,
      perPage: 5,
      searchKeyword: null,
    };
  },
  computed: {
    ...mapGetters("CourseCompletion", [
      "courseCompletionInfo",
      "approveEnrollLog",
    ]),
    tableData() {
      let paginatedData;
      let filteredData = this.approveEnrollLog;

      if (this.searchKeyword) {
        filteredData = filteredData.filter((item) => {
          return (
            this.formatName(
              item.mp_prefixName,
              item.mp_firstName,
              item.mp_lastName
            )
              .toLowerCase()
              .includes(this.searchKeyword.toLowerCase()) ||
            item.m_email
              .toLowerCase()
              .includes(this.searchKeyword.toLowerCase())
          );
        });
      }

      if (this.range.start && this.range.end) {
        filteredData = filteredData.filter((item) => {
          const cerStartDate = moment(item.timestamp).format("YYYY-MM-DD");
          const rangeStart = moment(this.range.start).format("YYYY-MM-DD");
          const rangeEnd = moment(this.range.end).format("YYYY-MM-DD");
          return cerStartDate >= rangeStart && cerStartDate <= rangeEnd;
        });
      }

      if (this.selectStatusType != "NOT_SELECT") {
        filteredData = filteredData.filter(
          (item) => item.action == this.selectStatusType
        );
      }

      const firstIndex = (this.currentPage - 1) * this.perPage;
      if (filteredData) {
        paginatedData = filteredData.slice(
          firstIndex,
          firstIndex + this.perPage
        );
      }
      return paginatedData;
    },
    rows() {
      if (
        this.searchKeyword ||
        (this.range.start && this.range.end) ||
        this.selectStatusType != "NOT_SELECT"
      ) {
        return this.tableData.length;
      }
      return this.approveEnrollLog.length;
    },
    formattedDate() {
      if (this.range.start) {
        let rangeCalendar = document.getElementById("calendar-range");
        if (rangeCalendar.style.display == "inline-block") {
          this.showCalendarRange();
        }
        return (
          moment(this.range.start).format("DD/MM/YYYY") +
          " - " +
          moment(this.range.end).format("DD/MM/YYYY")
        );
      } else {
        return "";
      }
    },
  },
  async mounted() {
    await this.fetchCourseCompletionInfo(this.courseId);
    await this.fetchApproveEnrollLog(this.courseId);
  },
  methods: {
    ...mapActions("CourseCompletion", [
      "fetchCourseCompletionInfo",
      "fetchApproveEnrollLog",
    ]),
    showCalendarRange() {
      let rangeCalendar = document.getElementById("calendar-range");
      if (rangeCalendar.style.display == "inline-block") {
        rangeCalendar.style.display = "none";
      } else {
        rangeCalendar.style.display = "inline-block";
      }
    },
    formatName(prefixName, firstName, lastName) {
      return (prefixName ?? "") + firstName + " " + lastName;
    },
    getSearchKeyword(keyword) {
      this.currentPage = 1;
      this.searchKeyword = keyword;
    },
  },
};
</script>

<style scoped>
.vc-container {
  z-index: 1;
  left: 20px !important;
}
.input-group-prepend .btn,
.input-group-append .btn {
  z-index: 0;
}
.approve-status {
  color: #28a745;
}
.not-approve {
  color: #dc3545;
}
</style>
