import jsPDF from "jspdf";
import { font, fontBold } from "@/assets/fonts/Sarabun-normal.js";

export default {
  methods: {
    async generateVerifyMemberReport() {
      try {
        this.genaratingPDF = true;
        let pdf = new jsPDF(this.pdfAllCourseOption);

        const pdf_width = pdf.internal.pageSize.width;
        const pdf_height = pdf.internal.pageSize.height;

        const margin_l = this.pdfAllCourseConfig.margin.l;
        const margin_t = this.pdfAllCourseConfig.margin.t;

        const addNewPageIfNeeded = (incrementValue, rowHeight = 0) => {
          if ((pdf_position_y + incrementValue + rowHeight) >= pdf_height - margin_t) {
            pdf.addPage();
            pdf_position_y = margin_t; // Reset the position to the top of the new page
          } else {
            pdf_position_y += incrementValue; // Add the input value
          }
        };

        const cellWidth = 120;
        const cellHeight = 120;

        let pdf_position_y = 0;

        pdf.addFileToVFS("Sarabun-Regular.ttf", fontBold);
        pdf.addFont("Sarabun-Regular.ttf", "Sarabun", "bold");
        pdf.addFileToVFS("Sarabun-Regular.ttf", font);
        pdf.addFont("Sarabun-Regular.ttf", "Sarabun", "normal");

        pdf.setFont("Sarabun", "bold");
        
        pdf.setFontSize(this.pdfAllCourseConfig.typo.header);
        pdf_position_y += margin_t;
        pdf.text(
          "รายงานการยืนยันตัวตนขณะเรียน",
          pdf_width / 2,
          pdf_position_y,
          null,
          null,
          "center"
        );
        addNewPageIfNeeded(this.pdfAllCourseConfig.endLegend);

        pdf.setFont("Sarabun", "normal");
        pdf.setFontSize(this.pdfAllCourseConfig.typo.normal);

        this.thaitext(
          pdf,
          "ชื่อผู้เรียน : " + this.memberInfo.mp_name,
          margin_l,
          pdf_position_y
        );
        addNewPageIfNeeded(this.pdfAllCourseConfig.endNormal);

        this.thaitext(
          pdf,
          "อีเมล : " + this.member.email,
          margin_l,
          pdf_position_y
        );
        addNewPageIfNeeded(this.pdfAllCourseConfig.endNormal);

        this.thaitext(
          pdf,
          "ชื่อคอร์ส : " + this.cerData.courseName,
          margin_l,
          pdf_position_y
        );
        addNewPageIfNeeded(this.pdfAllCourseConfig.endNormal);

        this.thaitext(
          pdf,
          "วันที่เริ่มเรียน : " + this.formatDateToThai(this.cerData.cerStartDate, false),
          margin_l,
          pdf_position_y
        );
        

        if ( this.cerData.cerCompleteDate ) {
          addNewPageIfNeeded(this.pdfAllCourseConfig.endNormal);
          this.thaitext(
            pdf,
            "วันที่เรียนจบ : " + this.formatDateToThai(this.cerData.cerCompleteDate, false),
            margin_l,
            pdf_position_y
          );
          addNewPageIfNeeded(this.pdfAllCourseConfig.endLegend);
        } else {
          addNewPageIfNeeded(this.pdfAllCourseConfig.endLegend);
        }

        pdf.setFont("Sarabun", "bold");
        this.thaitext(
          pdf,
          "รูปใบหน้ายืนยันตัวตน",
          margin_l,
          pdf_position_y
        );
        addNewPageIfNeeded(this.pdfAllCourseConfig.endNormal);

        pdf.setFont("Sarabun", "normal");
        let nowIndexImg = 1
        let maxHeightInRow = 1
        for (let i = 0; i < this.faceApprove.length; i++) {
          const imgUrl = `${this.baseUrl}/${this.faceApprove[i].face_path}?token=${this.token}`;
          const img = new Image();
          img.src = imgUrl;

          let height = 1
          
          switch(nowIndexImg){
            case 1:
              nowIndexImg += 1
              for (let j = i; j < i+3 && j < this.faceApprove.length ; j++) {
                height = pdf.splitTextToSize(this.faceApprove[j].lec_name, 100).length;
                maxHeightInRow = ( height > maxHeightInRow ) ? height : maxHeightInRow
              }
              
              pdf.rect(margin_l, pdf_position_y, cellWidth, cellHeight + (this.pdfAllCourseConfig.endNormal * (maxHeightInRow - 1)));
              this.thaitext(
                pdf,
                this.faceApprove[i].lec_name,
                margin_l + 10,
                pdf_position_y + 10,
                100
              );
              pdf.addImage(img, 'JPEG', margin_l + 10, pdf_position_y + 15 + (this.pdfAllCourseConfig.endNormal * (maxHeightInRow - 1)), 100, 80);
              pdf.text(
                "เวลา " + this.duration(this.faceApprove[i].learning_point),
                margin_l + (cellWidth / 2),
                pdf_position_y + 105 + (this.pdfAllCourseConfig.endNormal * (maxHeightInRow - 1)),
                null,
                null,
                "center"
              );
              break;
            case 2:
              nowIndexImg += 1
              pdf.rect(margin_l + cellWidth, pdf_position_y, cellWidth, cellHeight + (this.pdfAllCourseConfig.endNormal * (maxHeightInRow - 1)));
              this.thaitext(
                pdf,
                this.faceApprove[i].lec_name,
                margin_l + cellWidth + 10,
                pdf_position_y + 10,
                100
              );
              pdf.addImage(img, 'JPEG', margin_l + cellWidth + 10, pdf_position_y + 15 + (this.pdfAllCourseConfig.endNormal * (maxHeightInRow - 1)), 100, 80);
              pdf.text(
                "เวลา " + this.duration(this.faceApprove[i].learning_point),
                margin_l + cellWidth + (cellWidth / 2),
                pdf_position_y + 105 + (this.pdfAllCourseConfig.endNormal * (maxHeightInRow - 1)),
                null,
                null,
                "center"
              );
              break;
            case 3:
              nowIndexImg = 1
              pdf.rect(margin_l + cellWidth*2, pdf_position_y, cellWidth, cellHeight + (this.pdfAllCourseConfig.endNormal * (maxHeightInRow - 1)));
              this.thaitext(
                pdf,
                this.faceApprove[i].lec_name,
                margin_l + cellWidth*2 + 10,
                pdf_position_y + 10,
                100
              );
              pdf.addImage(img, 'JPEG', margin_l + cellWidth*2 + 10, pdf_position_y + 15 + (this.pdfAllCourseConfig.endNormal * (maxHeightInRow - 1)), 100, 80);
              pdf.text(
                "เวลา " + this.duration(this.faceApprove[i].learning_point),
                margin_l + cellWidth*2 + (cellWidth / 2),
                pdf_position_y + 105 + (this.pdfAllCourseConfig.endNormal * (maxHeightInRow - 1)),
                null,
                null,
                "center"
              );
              addNewPageIfNeeded(cellHeight + (this.pdfAllCourseConfig.endNormal * (maxHeightInRow - 1)), cellHeight + (this.pdfAllCourseConfig.endNormal * (maxHeightInRow - 1)));
              break;
          }
        }


        setTimeout(() => {
          pdf.save("รายงานการยืนยันตัวตนขณะเรียน.pdf");
          this.genaratingPDF = false;
        }, 0);
      } catch (err) {
        console.log(err);
        this.genaratingPDF = false;
      }     
    }
  }
}