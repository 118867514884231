<template>
  <div
    v-if="isCardShow"
    class="card card-csq no-shadow mt-4"
    style="min-height: 280px;"
  >
    <template v-if="isLoading">
      <div class="text-center mt-5">
        <b-spinner class="align-middle" variant="primary"></b-spinner>
        <p class="mt-3"><strong>กรุณารอสักครู่</strong></p>
        <p>เรากำลังเตรียมประวัติการเข้าเรียนของคุณ</p>
      </div>
    </template>
    <div
      v-else-if="!isLoading && faceApprove.length > 0"
      class="card-body"
    >
      <b-row>
        <b-col cols="9">
          <strong> {{ cerData.courseName }}</strong>
        </b-col>
        <b-col cols="3" class="text-right">
          <b-button
            @click="generateVerifyMemberReport()"
            size="sm"
            variant="light"
            class="btn btn-download"
            :disabled="genaratingPDF"
          >
            <b-spinner
              id="report-loading"
              class="aling-middle mr-2"
              style="display: none;"
              small
            ></b-spinner>
            <i class="fa fa-angle-double-down" aria-hidden="true"></i>
            ดาวน์โหลดรายงาน
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col cols="2"><strong>ชื่อผู้เรียน</strong></b-col>
        <b-col cols="5">
          {{ memberInfo.mp_name }}
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="2"><strong>อีเมล</strong></b-col>
        <b-col cols="5">
          {{ member.email }}
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="2"><strong>วันที่เริ่มเรียน</strong></b-col>
        <b-col cols="5">
          {{ formatDateToThai(cerData.cerStartDate, false) }}
        </b-col>
      </b-row>
      <b-row class="mt-2" v-if="cerData.cerCompleteDate">
        <b-col cols="2"><strong>วันที่เรียนจบ</strong></b-col>
        <b-col cols="5">
          {{ formatDateToThai(cerData.cerCompleteDate, false) }}
        </b-col>
      </b-row>

      <b-row class="py-2 mt-4 mx-1" style="border-bottom: 1px solid #000">
        <strong>ชื่อบทเรียน</strong>
      </b-row>

      <div class="grid-container">
        <div v-for="(item, index) in faceApprove" :key="index" class="grid-item">
          <p class="lec-name">{{ item.lec_name }}</p>
          <img
            class="img-fluid my-2"
            :src="`${baseUrl}/${item.face_path}?token=${token}`"
          />
          <p> เวลา {{ duration(item.learning_point) }}</p>
        </div>
      </div>
    </div>
    <template v-else>
      <div class="text-center mt-5">
        <p>ไม่มีประวัติการเข้าเรียน</p>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dateMixins from "@/components/pages/analytics/shared/mixins/date.js";
import timeMixins from "@/components/pages/analytics/shared/mixins/time.js";
import verifyMemberReportMixin from "@/mixins/verify-member-report-mixin.js";
import generateAttendanceReportPdfMixin from "@/mixins/generate-attendance-report-pdf-mixin.js";

export default {
  mixins: [dateMixins, timeMixins, verifyMemberReportMixin, generateAttendanceReportPdfMixin],
  props: {
    isLoading: Boolean,
    isCardShow: Boolean,
    faceApprove: Array,
    cerData: Object,
    member: Object
  },
  data() {
    return {
      token: localStorage.getItem("token"),
      baseUrl:
        process.env.VUE_APP_FILE_REC_ACCESS_SERVICE_URL ||
        "https://cs-file-access-service-20220905.coursesquare.com/",
      genaratingPDF: false,
      thaiMonths: [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม"
      ],
      pdfAllCourseOption: {
        orientation: "H",
        format: "a4",
        unit: "px",
        lineHeight: 2,
        putOnlyUsedFonts: true
      },
      pdfAllCourseConfig: {
        typo: {
          header: 14,
          large: 16,
          normal: 9,
          small: 7,
          chartHeader: 18
        },
        margin: {
          t: 72,
          b: 40,
          l: 40,
          r: 40,
          tChart: 10,
          tHeaderChart: 24,
          bHeaderTable: 5
        },
        endNormal: 11,
        endRow: 18,
        endTable: 16,
        endRowHeader: 27,
        endLegend: 32
      }
    };
  },
  computed: {
    ...mapGetters("MemberManagement", ["memberInfo"]),
  },
  methods: {
  },
};
</script>
<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: fit-content; /* Ensure it wraps around items */
}

.grid-item {
  text-align: center;
  padding: 10px;
  border: 1px solid #DDDDDD;
  border-left: none;
  border-top: none;
}

.grid-item:nth-child(-n+3) {
  border-top: none;
}

.grid-item:nth-child(3n+1) {
  border-left: none;
}

.lec-name {
  text-align: left;
  font: normal normal normal 16px/18px Helvetica Neue;
  letter-spacing: 0px;
  opacity: 1;
}
</style>