<template>
  <div>
    <PageHeader title="สรุปผู้เรียนรายคอร์ส" icon="briefcase"></PageHeader>
    <b-container fluid class="outer">
      <div class="inner bg-container">
        <div class="card no-shadow">
          <div class="card-header bg-white">
            <CourseInfo :data="courseInfo" class="mt-2" />
          </div>

          <div class="card-body pt-4">
            <b-row align-h="between">
              <b-col md="6" lg="4">
                <SearchBox
                  :title="`พิมพ์ชื่อผู้เรียนที่ต้องการค้นหา`"
                  @typed="getSearchKeyword"
                />
              </b-col>
              <b-col md="6" lg="4" class="text-right">
                <b-button
                  v-if="canEnrollMember && (!isTutor || IsTutorAdmin) && !isAccountingType() && !isInstructor"
                  variant="primary"
                  size="md"
                  @click="addMembers"
                >
                  <b-icon-plus />เพิ่มผู้เรียนใหม่
                </b-button>
              </b-col>
            </b-row>

            <b-row v-if="canEnrollMember" class="mt-4">
              <b-col md="7">
                <b-form inline>
                  <label for="inline-form-input-name" class="mr-2">
                    &nbsp;เลือกผู้เรียนจำนวน
                    {{ allSelectedItems.length }} คน
                  </label>
                  <b-form-select
                    v-model="selectedOption"
                    :options="options"
                    :disabled="allSelectedItems.length === 0"
                    size="sm"
                  />

                  <b-button
                    v-if="selectedOption && allSelectedItems.length > 0"
                    variant="primary"
                    size="sm"
                    class="ml-1 bg-csq"
                    @click="showConfirmActionModal()"
                  >
                    ยืนยัน
                  </b-button>
                </b-form>
              </b-col>
            </b-row>
            <b-table
              ref="selectableTable"
              :items="items.data"
              :fields="checkFields()"
              :current-page="items.meta.current_page"
              :busy="onLoading"
              :sort-by.sync="sort.by"
              :sort-desc.sync="sort.desc"
              sort-icon-left
              hover
              responsive
              class="mt-3 table-data"
              @row-clicked="rowClicked"
              :tbody-tr-class="tbodyRowClass"
              primary-key="id"
            >
              <template v-slot:header(±)="">
                <div v-show="canEnrollMember">
                  <b-link
                    v-if="isAllItemsSelected"
                    class="text-dark"
                    @click="clearSelected"
                  >
                    <b-icon-check2-square scale="1.2" />
                  </b-link>
                  <b-link v-else class="text-dark" @click="selectAllRows">
                    <b-icon-square />
                  </b-link>
                </div>
              </template>

              <template v-slot:cell(±)="{ item }">
                <div v-show="canEnrollMember">
                  <template v-if="isSelectedItem(item)">
                    <b-icon-check2-square scale="1.2" />
                    <span class="sr-only">Selected</span>
                  </template>
                  <template v-else>
                    <b-icon-square />
                    <span class="sr-only">Not selected</span>
                  </template>
                </div>
              </template>

              <template v-slot:cell(fullname)="props">
                <div v-if="isTutor" class="text-dark">
                  {{ props.item.firstName }}
                  {{ props.item.lastName }}
                </div>
                <router-link
                  v-else
                  :to="{
                    name: 'member.edit',
                    params: { id: props.item.id, company: company.url },
                  }"
                  class="text-dark"
                  >{{ props.item.firstName }}
                  {{ props.item.lastName }}</router-link
                >
              </template>
              <template v-slot:cell(groups)="props">
                <span
                  v-for="(group, index) in props.item.groups"
                  :key="index"
                  class="badge badge-secondary mr-1"
                  >{{ group.name }}</span
                >
              </template>

              <template v-slot:cell(enrolled_date)="props">
                <div>{{ props.item.enrolled_date | formatDate }}</div>
              </template>

              <template v-slot:cell(learningTime)="props">
                <div>
                  {{ props.item.learningTime }}
                </div>
              </template>

              <template v-slot:cell(expire_date)="props">
                <div v-if="props.item.expire_date">
                  {{ props.item.expire_date | formatDate }}
                </div>
                <div v-else>ไม่มีวันหมดอายุ</div>
              </template>

              <template v-slot:cell(action)="{ item: { cerId } }">
                <b-button
                  @click="gotoLearningStatusPage(cerId)"
                  size="sm"
                  variant="outline-primary"
                  >ตรวจสอบสถานะ</b-button
                >
              </template>

              <div slot="table-busy">
                <b-spinner class="align-middle text-csq"></b-spinner>
              </div>
            </b-table>
            <Pagination
              v-if="items.data.length"
              :items="items"
              @changePage="getResults"
              @changeLimit="getResults(1)"
            />
          </div>
        </div>
        <div class="mt-3">
          <router-link :to="{ name: 'course.enroll.index' }" class="text-csq">
            <b-icon-arrow-left-short scale="1.6" /> ย้อนกลับ
          </router-link>
        </div>
      </div>
    </b-container>

    <ConfirmCompleteEnrollmentModal
      modal-id="confirmCompleteEnrollmentModal"
      :items="allSelectedItems"
      @closed="closeConfirmActionModal"
    />
    <ConfirmUnenrollModal
      modal-id="confirmUnenrollModal"
      :course-id="courseId"
      :items="allSelectedItems"
      @closed="closeConfirmActionModal"
    />
  </div>
</template>

<script>
import api from "@/services/api";
import { mapActions, mapGetters } from "vuex";
import SearchBox from "../../shared/SearchBox";
import Numeral from "numeral";
import moment from "moment";
import PageHeader from "../../layouts/PageHeader";
import Pagination from "@/components/shared/Pagination";
import CourseInfo from "./partials/CourseInfo";
import ConfirmCompleteEnrollmentModal from "./partials/ConfirmCompleteEnrollmentModal";
import ConfirmUnenrollModal from "./partials/ConfirmUnenrollModal";
import SelectableTableMixin from "@/mixins/selectable-table-mixin.js";
import AuthMixin from "@/mixins/auth-mixin.js";

export default {
  components: {
    PageHeader,
    SearchBox,
    CourseInfo,
    Pagination,
    ConfirmCompleteEnrollmentModal,
    ConfirmUnenrollModal,
  },
  mixins: [SelectableTableMixin, AuthMixin],
  filters: {
    formatNumber: function (value) {
      return Numeral(value).format("0,0");
    },
    formatDate: function (value) {
      if (value) {
        moment.updateLocale("th", {});
        let year = parseInt(moment(String(value)).format("YYYY"));
        year += 543;
        return moment(String(value)).format(`D MMM ${year}`);
      }
    },
  },
  props: { company: { default: null, type: Object } },
  data() {
    return {
      courseId: this.$route.params.id,
      q: "",
      items: {
        data: [],
        meta: {
          current_page: 0,
          per_page: 15,
        },
      },
      fields: [
        {
          key: "±",
        },
        { label: "ชื่อผู้เรียน", key: "fullname", sortable: true },
        { label: "ชื่อผู้ใช้", key: "username", sortable: true },
        { label: "อีเมล", key: "email", sortable: true },
        {
          label: "กลุ่มเรียน",
          key: "groups",
          sortable: false,
        },
        { label: "วันที่ลงทะเบียน", key: "enrolled_date", sortable: true },
        { label: "เรียนได้ถึง", key: "expire_date", sortable: true },
        { label: "สถานะ", key: "status", sortable: true },
        // { label: '', key: 'action', sortable: false },
      ],

      tutorFields: [
        {
          key: "±",
        },
        { label: "ชื่อผู้เรียน", key: "fullname", sortable: true },
        { label: "อีเมล", key: "email", sortable: true },
        { label: "เบอร์โทรศัพท์", key: "phoneNumber", sortable: false },
        {
          label: "ชั่วโมงที่เรียนไปแล้ว",
          key: "learningTime",
          sortable: false,
        },
        { label: "วันที่ลงทะเบียน", key: "enrolled_date", sortable: true },
        { label: "เรียนได้ถึง", key: "expire_date", sortable: true },
        { label: "สถานะ", key: "status", sortable: true },
      ],

      sort: {
        by: "fullname",
        desc: false,
      },
      options: [
        { value: null, text: "เลือกตัวเลือก" },
        { value: "completeEnrollment", text: "ผ่านหลักสูตร", modalShow: false },
        {
          value: "unenroll",
          text: "ยกเลิกการลงทะเบียน",
          modalShow: false,
        },
      ],
      selectedOption: null,
    };
  },
  computed: {
    ...mapGetters("CourseManagement", ["isLoading", "courseInfo"]),

    sortOrder() {
      if (this.sort.desc) return "desc";
      return "asc";
    },
  },
  watch: {
    sort: {
      async handler() {
        await this.getResults(1)();
      },
      deep: true,
    },
  },
  created() {
    this.getCourseInfo({
      courseId: this.courseId,
      temp: false,
      excepts: "numberOfStudent,numberOfActiveStudent",
    });
    this.getResults();
  },

  methods: {
    ...mapActions("CourseManagement", ["getCourseInfo"]),
    ...mapActions("CourseEnrollment", [
      "syncSelectedItems",
      "clearSelectedItems",
    ]),

    checkFields() {
      if (this.isTutor == true) {
        return this.tutorFields;
      } else {
        return this.fields;
      }
    },

    async getResults(page = 1) {
      this.onLoading = true;
      await api
        .get(
          `/v1/courses/${this.courseId}/members?page=${page}&limit=${this.items.meta.per_page}&q=${this.q}&company=${this.company.id}&sort_by=${this.sort.by}&sort_order=${this.sortOrder}`
        )
        .then(({ data }) => {
          this.items = data;
          this.onLoading = false;
        })
        .catch(() => {});
    },
    getSearchKeyword(value) {
      this.q = value;
      this.getResults();
    },
    showConfirmActionModal() {
      if (this.selectedOption == "completeEnrollment") {
        this.$bvModal.show("confirmCompleteEnrollmentModal");
      } else if (this.selectedOption == "unenroll") {
        this.$bvModal.show("confirmUnenrollModal");
      }
    },
    closeConfirmActionModal(modalId) {
      this.selectedOption = null;
      this.allSelectedItems = [];
      this.$refs.selectableTable.clearSelected();
      this.getResults();
      this.$bvModal.hide(modalId);
    },
    addMembers() {
      this.clearSelectedItems();
      this.syncSelectedItems({
        itemKey: "courses",
        itemValue: {
          id: this.courseId,
          name: this.courseInfo.courseName,
          type: "คอร์สเรียน",
        },
      });

      localStorage.setItem("backToUrl", this.$router.history.current.fullPath);

      this.$router.push({
        name: "course.enroll.step2",
      });
    },
    gotoLearningStatusPage(cerId) {
      window.open(
        `/${this.company.url}/analytics/users/${cerId}/learning-status`,
        "_blank"
      );
    },
  },
};
</script>


<style lang="scss" scoped>
@media (min-width: 1380px) {
  .table-data {
    overflow-x: hidden;
  }
}
</style>
