<template>
  <div>
    <hr />
    <div class="exam-limit-time">
      <b-form-checkbox
        id="checkbox-3"
        v-model="chcekLimit"
        @change="
          tyepLimitCheck = '';
          updateLimit();
        "
        >ตั้งเวลาการทำข้อสอบ
        <b-icon
          class="icon mr-1"
          icon="exclamation-circle-fill"
          aria-hidden="true"
          id="tooltip-target-1"
          variant="warning"
        ></b-icon>
        <b-tooltip
          target="tooltip-target-1"
          triggers="hover"
          placement="bottom-right"
          class="warning-time"
        >
          ตั้งเวลาการส่งข้อสอบ คือ การตั้งเวลาข้อสอบทั้งหมด <br />
          ตั้งเวลาการทำแต่ละข้อ คือ การตั้งเวลาเฉพาะข้อสอบข้อนั้น ๆ
        </b-tooltip>
      </b-form-checkbox>
      <div class="input-limit pl-3 mt-2" style="padding-bottom: 8rem;">
        <b-form-radio
          v-model="tyepLimitCheck"
          value="EXAM"
          @change="
            chcekLimit = true;
            tyepLimitCheck = 'EXAM';
            updateLimit(true);
          "
          >ตั้งเวลาการส่งข้อสอบ</b-form-radio
        >
        <div class="input-time mt-1" v-if="tyepLimitCheck == 'EXAM'">
          <b-form inline>
            <p class="mb-0 mr-2">ตั้งเวลา</p>
            <vue-timepicker
              manual-input
              class="mr-3"
              v-model="estimatedTime"
              @change="updateLimit"
              format="HH:mm:ss"
            ></vue-timepicker>
          </b-form>
        </div>
        <b-form-radio
          class="pt-2"
          v-model="tyepLimitCheck"
          value="QUESTION"
          @change="
            tyepLimitCheck = 'QUESTION';
            chcekLimit = true;
            updateLimit();
          "
          >ตั้งเวลาการทำแต่ละข้อ</b-form-radio
        >
        <div class="input-time mt-1" v-if="tyepLimitCheck == 'QUESTION'">
          <b-form inline>
            <p class="mb-0 mr-2">ตั้งเวลา</p>
            <vue-timepicker
              manual-input
              class="mr-3"
              v-model="estimatedTime"
              @change="updateLimit"
              format="HH:mm:ss"
            ></vue-timepicker>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import TimePicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
export default {
  data() {
    return {
      tyepLimitCheck: "",
      token: localStorage.getItem("token"),
      unitOfTime: "MIN",
      chcekLimit: "",
      time: 30,
      estimatedTime: ""
    };
  },
  components: {
    'vue-timepicker': TimePicker
  },

  computed: {
    ...mapGetters("ExamBuilder", ["exam"]),
  },

  mounted() {
    if (Object.keys(this.exam._options.timeLimit).length == 0) {
      this.chcekLimit = false;
    } else {
      this.chcekLimit = true;
      this.tyepLimitCheck = this.exam._options.timeLimit.timeLimitType;
      this.time = this.exam._options.timeLimit.timeLimitSecond / 60;
      const totalSeconds = this.exam._options.timeLimit.timeLimitSecond;
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;
      this.estimatedTime = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    }
  },
  methods: {
    ...mapActions("ExamBuilder", ["updateOptionsTimeLimit"]),

    async updateLimit(){
      let [hours, minutes, seconds] = this.estimatedTime.split(":").map(Number);
      hours = isNaN(hours) ? 0 : hours
      minutes = isNaN(minutes) ? 0 : minutes
      seconds = isNaN(seconds) ? 0 : seconds
      let totalSeconds = hours * 3600 + minutes * 60 + seconds;
      let request = {
        body: {
          isSkippable: true,
          timeLimit: {
            timeLimitType: this.tyepLimitCheck,
            timeLimitSecond: totalSeconds,
          },
        },
        token: this.token,
        id: this.exam.id,
      };
      if (this.tyepLimitCheck == "") {
        request.body.timeLimit = {};
      }
      if ( this.tyepLimitCheck === "QUESTION" || this.exam._options?.solution?.hasSolutionAfterEachQuestion == true) {
        request.body.isSkippable = false;
      }
      await setTimeout(() => {
        this.updateOptionsTimeLimit(request);
      }, 1000);
    },
  },
};
</script>

<style lang="css" scope>
.inner hr {
  margin-top: 1rem !important;
}

.exam-limit-time .custom-control-input:checked ~ .custom-control-label::before {
  border-color: green !important;
  background-color: green !important;
}

.input-limit .custom-control-input:checked ~ .custom-control-label::before {
  background: #fff !important;
  border-color: #000 !important;
}

.input-limit
  .custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background-image: none !important;
  background-color: #007bff;
  border-radius: 10px;
  left: -22px;
  top: 6px;
  height: 12px;
  width: 12px;
}

.input-time {
  padding-left: 25px;
}

.select-limit-time {
  background: #fff url("../../../../assets/images/icon/down-chevron.svg")
    no-repeat right 0.75rem center/8px 10px !important;
}

.tooltip-inner{
  max-width: 400px !important;
  text-align: left;
}

.input-lt {
  border-radius: 10px;
}
</style>