<template>
  <div>
    <PageHeader title="อนุมัติการจบคอร์ส" style="margin-left: -20%" />
    <b-container class="outer" fluid>
      <div class="inner bg-container">
        <div class="card no-shadow">
          <div class="card-header bg-white">
            <b-row>
              <b-col cols="7" class="pr-0">
                <b-media>
                  <template #aside>
                    <b-img
                      :src="baseUrl + courseCompletionInfo.c_thumbnailPath"
                      width="125"
                      alt="placeholder"
                    ></b-img>
                  </template>
                  <h4>{{ courseCompletionInfo.c_name }}</h4>
                  <h6>By {{ courseOwnerName }}</h6>
                  <h6>{{ courseCompletionInfo.c_name }}</h6>
                </b-media>
              </b-col>
              <b-col
                cols="5"
                class=""
                style="border-left: 1px solid rgba(0, 0, 0, 0.125);"
              >
                <h5>เกณฑ์การจบคอร์ส</h5>
                <div
                  v-for="(rule, index) in courseCompletionInfo.ccr_rule"
                  :key="index"
                  class="d-flex justify-content-between"
                >
                  <h6>
                    {{ rule.th
                    }}<span v-if="rule.en == 'examRule'">
                      {{ rule.examName }}
                    </span>
                  </h6>
                  <h6 v-if="rule.en == 'examRule'">
                    {{ rule.examResultPercent }} {{ ruleType(rule.type) }}
                  </h6>
                  <h6 v-else>{{ rule.value }} {{ ruleType(rule.type) }}</h6>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="card-body pt-4">
            <b-row>
              <b-col cols="10">
                <b-row>
                  <b-col cols="4">
                    <SearchBox
                      title="พิมพ์ชื่อผู้เรียนหรืออีเมลที่ต้องการค้นหา"
                      size="sm"
                      @typed="getSearchKeyword"
                    />
                  </b-col>
                  <b-col cols="3">
                    <b-input-group size="sm">
                      <b-form-input
                        type="text"
                        :value="formattedDate"
                        placeholder="ระบุวันที่ต้องการค้นหา"
                        disabled
                      />
                      <b-input-group-prepend>
                        <b-button
                          variant="primary"
                          size="sm"
                          @click="showCalendarRange()"
                        >
                          <b-icon
                            icon="calendar3-range"
                            style="color: #fff;"
                          ></b-icon>
                        </b-button>
                      </b-input-group-prepend>
                    </b-input-group>
                    <DatePicker
                      :id="'calendar-range'"
                      style="
                        display: none;
                        float: right;
                        position: absolute;
                        will-change: transform;
                        left: 0px;
                      "
                      v-model="range"
                      is-range
                    />
                  </b-col>
                  <b-col cols="2">
                    <b-form-select
                      v-model="selectStatusType"
                      :options="statusOptions"
                      size="sm"
                    ></b-form-select>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="2" class="text-right">
                <b-button
                  variant="outline-primary"
                  size="sm"
                  @click="redirectToCourseCompleteInfo()"
                >
                  ประวัติการดำเนินการ
                </b-button>
              </b-col>
            </b-row>
            <h6 class="mt-3">
              ผู้เรียนที่เรียนจบเกณฑ์ {{ studentApprovedAmount }} คน จากทั้งหมด
              {{ this.courseCompletionInfo.total_students }} คน
            </h6>
            <div class="d-flex align-items-center mt-5">
              <h6>เลือกผู้เรียนจำนวน {{ studentAmountSelected }} คน</h6>
              <b-form-select
                v-model="selectApproveType"
                class="ml-4 mr-2"
                :options="approveOptions"
                :disabled="studentAmountSelected == 0"
                style="width: 135px;"
                size="sm"
              ></b-form-select>
              <b-button
                v-if="selectApproveType && studentAmountSelected > 0"
                variant="primary"
                size="sm"
                @click="confirmSelectMultipleStudent()"
              >
                ยืนยัน
              </b-button>
            </div>
            <b-table
              id="course-complete-member-list"
              :items="tableData"
              :fields="fields"
              :busy="isLoading"
              :sort-by.sync="sort.by"
              :sort-desc.sync="sort.desc"
              sort-icon-left
              hover
              responsive
              striped
              class="mt-3"
              @row-clicked="rowClicked"
            >
              <template #head(selectAll)="">
                <b-form-checkbox
                  v-model="isSelectAll"
                  @change="selectAllStudent"
                ></b-form-checkbox>
              </template>
              <template v-slot:cell(selectAll)="props">
                <b-form-checkbox
                  v-if="!props.item.member.cer_adminApproveStatus"
                  v-model="selected[props.item.member.cer_id]"
                  :key="props.item.member.cer_id"
                  @change="selectStudent"
                ></b-form-checkbox>
              </template>
              <template v-slot:cell(date)="props">
                <h6>
                  {{ props.item.member.cer_startDate | thaiDateFilter }} -
                  {{ props.item.member.cer_endDate | thaiDateFilter }}
                </h6>
              </template>
              <template v-slot:cell(criteria)="props">
                <div class="d-flex justify-content-between">
                  <h6>
                    {{ props.item.passProgressCount }} /
                    {{ courseCompletionInfo.ccr_rule.length }}
                  </h6>
                  <div>
                    <i
                      :id="`tooltip-${props.item.member.cer_id}`"
                      class="bi bi-clipboard2-check-fill"
                      style="color: #17A2B8;"
                    ></i>
                    <b-tooltip
                      :target="`tooltip-${props.item.member.cer_id}`"
                      triggers="hover"
                      placement="bottom-left"
                    >
                      <div
                        v-for="(rule, index) in courseCompletionInfo.ccr_rule"
                        :key="index"
                        class="d-flex px-2"
                      >
                        <b-icon
                          v-if="
                            props.item.progress &&
                              props.item.progress[index] === true
                          "
                          icon="check-circle-fill"
                          variant="success"
                        ></b-icon>
                        <p style="font-size: 14px; text-align: left;">
                          {{ rule.th
                          }}<span v-if="rule.en == 'examRule'">
                            {{ rule.examName }}
                          </span>
                        </p>
                      </div>
                    </b-tooltip>
                  </div>
                </div>
              </template>
              <template v-slot:cell(approveStatus)="props">
                <h6
                  v-if="props.item.member.cer_adminApproveStatus == null"
                  class="wait-approve"
                >
                  รออนุมัติ
                </h6>
                <h6
                  v-if="props.item.member.cer_adminApproveStatus == 'APPROVED'"
                  class="approve-status"
                >
                  อนุมัติ
                </h6>
                <h6
                  v-if="props.item.member.cer_adminApproveStatus == 'REJECT'"
                  class="not-approve"
                >
                  ไม่อนุมัติ
                </h6>
              </template>
              <template v-slot:cell(actions)="props">
                <div v-if="props.item.member.cer_adminApproveStatus == null">
                  <b-button
                    class="mr-2"
                    variant="success"
                    size="sm"
                    @click="
                      updateCourseEnrollApproveStatus('APPROVED', [
                        props.item.member.cer_id,
                      ])
                    "
                  >
                    อนุมัติ
                  </b-button>
                  <b-button
                    variant="danger"
                    size="sm"
                    @click="showNotApproveModal(props.item)"
                  >
                    ไม่อนุมัติ
                  </b-button>
                </div>
              </template>
            </b-table>
            <template v-if="tableData.length < 1">
              <h6 class="text-center my-4">ไม่มีข้อมูล</h6>
            </template>
            <b-row class="mt-4">
              <b-col cols="6">
                <div class="d-flex justify-content-start align-items-center">
                  <p class="pr-3">แสดง</p>
                  <b-form-select
                    v-model="perPage"
                    :options="[5, 10, 15, 20]"
                    style="width: 75px;"
                    @change="isSelectAll = false"
                  ></b-form-select>
                  <p class="pl-3">จาก {{ rows }} รายการ</p>
                </div>
              </b-col>
              <b-col cols="6">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  align="right"
                  aria-controls="course-complete-member-list"
                  last-number
                  @change="isSelectAll = false"
                ></b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <b-modal
        v-model="isApproveCourseModalShow"
        hide-header
        hide-footer
        centered
      >
        <div class="text-center py-3">
          <b-icon icon="check-circle" font-scale="4" variant="success">
          </b-icon>
          <h4 class="mt-4 mb-5" style="font-weight: 600;">
            {{ approveText }}
          </h4>
          <b-button
            variant="primary"
            size="sm"
            @click="
              fetchCourseCompletionMemberList(courseId);
              isApproveCourseModalShow = false;
            "
          >
            เสร็จสิ้น
          </b-button>
        </div>
      </b-modal>
      <b-modal
        v-model="isDisapprovalModalShow"
        title="ไม่อนุมัติการจบคอร์ส"
        hide-footer
        centered
      >
        <div>
          <b-media>
            <template #aside>
              <b-img
                :src="baseUrl + courseCompletionInfo.c_thumbnailPath"
                width="125"
                alt="placeholder"
              ></b-img>
            </template>
            <h5 style="font-weight: 600;">{{ courseCompletionInfo.c_name }}</h5>
            <h6>By {{ courseOwnerName }}</h6>
          </b-media>
          <h6 class="mt-3">ผู้เรียน {{ filterStudentData.length }} คน</h6>
          <div
            v-for="(student, index) in filterStudentData"
            :key="index"
            class="mb-2"
            style="border: 1px solid #DFDFDF; padding: 13px 20px;"
          >
            {{ studentOrder(index) }}
            {{
              studentName(
                student.member.mp_prefixName,
                student.member.mp_firstName,
                student.member.mp_lastName
              )
            }}
          </div>
          <h6 class="mt-4">
            กรุณากรอกเหตุผลที่ไม่อนุมัติ <span style="color: red;">*</span>
          </h6>
          <b-form-input
            v-model="reasonText"
            placeholder="เหตุผลที่ไม่อนุมัติ"
            aria-describedby="input-reason"
            :state="reasonState"
          ></b-form-input>
          <b-form-invalid-feedback id="input-reason">
            กรุณากรอกเหตุผลที่ไม่อนุมัติ
          </b-form-invalid-feedback>
          <div class="d-flex align-items-baseline justify-content-between mt-4">
            <b-pagination
              v-model="disapprovalCurrentPage"
              :total-rows="studentList.length"
              :per-page="5"
              style="width: 50%;"
            ></b-pagination>
            <div style="float: right;">
              <b-button
                class="mr-2"
                variant="outline-secondary"
                @click="isDisapprovalModalShow = false"
              >
                ยกเลิก
              </b-button>
              <b-button
                :disabled="!reasonText"
                variant="danger"
                @click="
                  updateCourseEnrollApproveStatus('REJECT', cerIds, reasonText)
                "
              >
                ไม่อนุมัติ
              </b-button>
            </div>
          </div>
        </div>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import Vue from "vue";
import PageHeader from "@/components/layouts/PageHeader";
import SearchBox from "@/components/shared/SearchBox";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import date from "@/components/pages/analytics/shared/mixins/date";
import moment from "moment";

import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [date],
  components: { PageHeader, SearchBox, DatePicker },
  data() {
    return {
      courseId: this.$route.params.courseId,
      baseUrl: process.env.VUE_APP_S1_URL || "https://s1-dev.coursesquare.com/",
      range: {
        start: "",
        end: "",
      },
      selectStatusType: "NOT_SELECT",
      statusOptions: [
        { value: "NOT_SELECT", text: "สถานะทั้งหมด" },
        { value: null, text: "รออนุมัติ" },
        { value: "APPROVED", text: "อนุมัติ" },
        { value: "REJECT", text: "ไม่อนุมัติ" },
      ],
      selectApproveType: null,
      approveOptions: [
        { value: null, text: "เลือกตัวเลือก" },
        { value: "APPROVED", text: "อนุมัติ" },
        { value: "REJECT", text: "ไม่อนุมัติ" },
      ],
      fields: [
        { label: "", key: "selectAll" },
        {
          label: "ชื่อผู้เรียน",
          key: "member.mp_firstName",
          sortable: true,
          formatter: (value, key, item) => {
            return (
              (item.member.mp_prefixName ?? "") +
              value +
              " " +
              item.member.mp_lastName
            );
          },
        },
        { label: "อีเมล", key: "member.m_email" },
        {
          label: "วันที่เข้าเรียนได้",
          key: "date",
          sortable: true,
        },
        { label: "เกณฑ์การจบคอร์ส", key: "criteria" },
        { label: "การอนุมัติ", key: "approveStatus" },
        { label: "", key: "actions" },
      ],
      sort: {
        by: "member.mp_firstName",
        desc: false,
      },
      isSelectAll: false,
      selected: {},
      perPage: 5,
      currentPage: 1,
      searchKeyword: null,
      isApproveCourseModalShow: false,
      reasonText: "",
      approveText: "",
      studentList: [],
      isDisapprovalModalShow: false,
      disapprovalCurrentPage: 1,
      cerIds: [],
    };
  },
  computed: {
    ...mapGetters("CourseCompletion", [
      "isLoading",
      "courseCompletionMemberList",
      "courseCompletionInfo",
    ]),
    courseOwnerName() {
      return this.studentName(
        this.courseCompletionInfo.mp_prefixName,
        this.courseCompletionInfo.mp_firstName,
        this.courseCompletionInfo.mp_lastName
      );
    },
    studentApprovedAmount() {
      return (
        this.courseCompletionInfo.total_students -
        this.courseCompletionInfo.pending_approvals
      );
    },
    formattedDate() {
      if (this.range.start) {
        let rangeCalendar = document.getElementById("calendar-range");
        if (rangeCalendar.style.display == "inline-block") {
          this.showCalendarRange();
        }
        return (
          moment(this.range.start).format("DD/MM/YYYY") +
          " - " +
          moment(this.range.end).format("DD/MM/YYYY")
        );
      } else {
        return "";
      }
    },
    tableData() {
      let paginatedData;
      let filteredData = this.courseCompletionMemberList;

      if (this.searchKeyword) {
        filteredData = filteredData.filter((item) => {
          return (
            this.studentName(
              item.member.mp_prefixName,
              item.member.mp_firstName,
              item.member.mp_lastName
            )
              .toLowerCase()
              .includes(this.searchKeyword.toLowerCase()) ||
            item.member.m_email
              .toLowerCase()
              .includes(this.searchKeyword.toLowerCase())
          );
        });
      }

      if (this.range.start && this.range.end) {
        filteredData = filteredData.filter((item) => {
          const cerStartDate = moment(item.member.cer_startDate).format(
            "YYYY-MM-DD"
          );
          const rangeStart = moment(this.range.start).format("YYYY-MM-DD");
          const rangeEnd = moment(this.range.end).format("YYYY-MM-DD");
          return cerStartDate >= rangeStart && cerStartDate <= rangeEnd;
        });
      }

      if (this.selectStatusType != "NOT_SELECT") {
        filteredData = filteredData.filter(
          (item) => item.member.cer_adminApproveStatus == this.selectStatusType
        );
      }

      const firstIndex = (this.currentPage - 1) * this.perPage;
      if (filteredData) {
        paginatedData = filteredData.slice(
          firstIndex,
          firstIndex + this.perPage
        );
      }
      return paginatedData;
    },
    filterStudentData() {
      let paginatedData;
      let filteredData = this.studentList;
      const firstIndex = (this.disapprovalCurrentPage - 1) * 5;
      if (filteredData) {
        paginatedData = filteredData.slice(firstIndex, firstIndex + 5);
      }
      return paginatedData;
    },
    rows() {
      if (
        this.searchKeyword ||
        (this.range.start && this.range.end) ||
        this.selectStatusType != "NOT_SELECT"
      ) {
        return this.tableData.length;
      }
      return this.courseCompletionMemberList.length;
    },
    reasonState() {
      if (this.reasonText) {
        return true;
      }
      return false;
    },
    studentAmountSelected() {
      const checkBoxValues = Object.values(this.selected);
      const count = checkBoxValues.filter((value) => value == true).length;
      return count;
    },
  },
  async mounted() {
    await this.fetchCourseCompletionMemberList(this.courseId);
    await this.fetchCourseCompletionInfo(this.courseId);
  },
  methods: {
    ...mapActions("CourseCompletion", [
      "fetchCourseCompletionMemberList",
      "fetchCourseCompletionInfo",
      "updateCourseEnrollApprove",
    ]),
    async updateCourseEnrollApproveStatus(status, cerId, reason) {
      let params = {
        cerIds: cerId,
        approveStatus: status,
        rejectReason: reason,
      };

      if (status == "REJECT") {
        await this.updateCourseEnrollApprove(params);
        this.approveText = "ไม่อนุมัติการจบคอร์สเรียบร้อยแล้ว";
        this.isNotApproveCourseModalShow = false;
        this.isDisapprovalModalShow = false;
        this.isApproveCourseModalShow = true;
      } else if (status == "APPROVED") {
        await this.updateCourseEnrollApprove(params);
        this.approveText = "อนุมัติการจบคอร์สเรียบร้อยแล้ว";
        this.isApproveCourseModalShow = true;
      }
      this.isSelectAll = false;
      this.selectAllStudent(false);
    },
    confirmSelectMultipleStudent() {
      this.cerIds = Object.keys(this.selected).filter(
        (key) => this.selected[key] === true
      );
      this.studentList = this.tableData.filter((item) =>
        this.cerIds.includes(String(item.member.cer_id))
      );

      if (this.selectApproveType == "APPROVED") {
        this.updateCourseEnrollApproveStatus("APPROVED", this.cerIds);
      } else if (this.selectApproveType == "REJECT") {
        this.isDisapprovalModalShow = true;
      }
    },
    showNotApproveModal(item) {
      this.isDisapprovalModalShow = true;
      this.cerIds = [item.member.cer_id];
      this.studentList = [item];
    },
    showCalendarRange() {
      let rangeCalendar = document.getElementById("calendar-range");
      if (rangeCalendar.style.display == "inline-block") {
        rangeCalendar.style.display = "none";
      } else {
        rangeCalendar.style.display = "inline-block";
      }
    },
    rowClicked(item) {
      if (!item.member.cer_adminApproveStatus) {
        Vue.set(
          this.selected,
          item.member.cer_id,
          !this.selected[item.member.cer_id]
        );
        this.selectStudent();
      }
    },
    selectStudent() {
      const itemsLength = this.courseCompletionMemberList.length;
      const selectedLength = Object.keys(this.selected).length;
      const allChecked = Object.values(this.selected).every((value) => value);

      if (itemsLength === selectedLength && allChecked) {
        this.isSelectAll = true;
      } else {
        this.isSelectAll = false;
      }
    },
    selectAllStudent(value) {
      if (value) {
        this.tableData.forEach((item) => {
          if (item.member.cer_adminApproveStatus == null) {
            Vue.set(this.selected, item.member.cer_id, true);
          } else {
            Vue.set(this.selected, item.member.cer_id, false);
          }
        });
      } else {
        this.tableData.forEach((item) => {
          Vue.set(this.selected, item.member.cer_id, false);
        });
      }
    },
    getSearchKeyword(keyword) {
      this.currentPage = 1;
      this.searchKeyword = keyword;
    },
    studentName(prefixName, firstName, lastName) {
      return (prefixName ?? "") + firstName + " " + lastName;
    },
    studentOrder(index) {
      return (this.disapprovalCurrentPage - 1) * 5 + index + 1 < 10
        ? `0${(this.disapprovalCurrentPage - 1) * 5 + index + 1}`
        : (this.disapprovalCurrentPage - 1) * 5 + index + 1;
    },
    ruleType(type) {
      if (type == "Percent") {
        return "เปอร์เซ็นต์";
      } else if (type == "Second") {
        return "วินาที";
      } else if (type == "Hour") {
        return "ชั่วโมง";
      }
    },
    redirectToCourseCompleteInfo() {
      this.$router.push({
        name: "course.completed.log",
        params: { courseId: this.courseId },
      });
    },
  },
};
</script>

<style scoped>
.vc-container {
  z-index: 1;
  left: 20px !important;
}
.input-group-prepend .btn,
.input-group-append .btn {
  z-index: 0;
}
.wait-approve {
  color: #ffc107;
}
.approve-status {
  color: #28a745;
}
.not-approve {
  color: #dc3545;
}
</style>
