<template>
  <div style>
    <b-row>
      <b-col cols>
        <layout :height="300">
          <template v-slot:header>
            <h6>ช่องทางการชำระ</h6>
          </template>
          <template v-slot:body>
            <div v-if="mockPieChart" :style="mockPieStyle">
              <h6 :style="mockPieText">ไม่มีข้อมูล</h6>
            </div>
            <div v-else style="padding: 0.5rem">
              <b-row>
                <b-col class="text-left pl-0 mt-2" cols="7" offset="5"> </b-col>
                <b-col cols="4">
                  <PieChart
                    :chart-data="datacollection"
                    :options="option"
                    :height="200"
                  ></PieChart>
                </b-col>
                <b-col cols="8">
                  <my-legend
                    :num="legendValue.length"
                    :color="[
                      'rgb(235,129,35)',
                      'rgb(253, 236, 210)',
                      'rgb(255, 181, 34)',
                      'rgb(255, 213, 0)',
                      'rgb(255, 145, 0)',
                      'rgb(255, 216, 108)',
                      'rgb(232, 177, 0)',
                      'rgb(255, 229, 159)',
                      'rgb(235, 129, 35)',
                      'rgb(255, 234, 128)',
                      'rgb(237,237,237)',
                    ]"
                  >
                    <template v-slot:1
                      >{{ label[0] }} {{ legendValue[0] }} ครั้ง</template
                    >
                    <template v-slot:2
                      >{{ label[1] }} {{ legendValue[1] }} ครั้ง</template
                    >
                    <template v-slot:3
                      >{{ label[2] }} {{ legendValue[2] }} ครั้ง</template
                    >
                    <template v-slot:4
                      >{{ label[3] }} {{ legendValue[3] }} ครั้ง</template
                    >
                    <template v-slot:5
                      >{{ label[4] }} {{ legendValue[4] }} ครั้ง</template
                    >

                    <template v-slot:6
                      >{{ label[5] }} {{ legendValue[5] }} ครั้ง</template
                    >
                    <template v-slot:7
                      >{{ label[6] }} {{ legendValue[6] }} ครั้ง</template
                    >
                    <template v-slot:8
                      >{{ label[7] }} {{ legendValue[7] }} ครั้ง</template
                    >
                    <template v-slot:9
                      >{{ label[8] }} {{ legendValue[8] }} ครั้ง</template
                    >
                    <template v-slot:10
                      >{{ label[9] }} {{ legendValue[9] }} ครั้ง</template
                    >
                  </my-legend>
                </b-col>
              </b-row>
            </div>
          </template>
        </layout>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PieChart from "@/components/pages/analytics/shared/PieChart";
import layout from "@/components/pages/analytics/shared/Layout";
import myLegend from "@/components/pages/analytics/shared/LegendPieChart";
import api from "@/services/api.js";
import chartMockMixin from "@/mixins/chart-mock-mixin.js";

export default {
  mixins: [chartMockMixin],
  components: {
    PieChart,
    layout,
    myLegend,
  },
  props: ["apiRoute"],
  data() {
    return {
      result: {},
      datacollection: {},
      option: {},
      legendValue: [],
      label: [],
    };
  },
  async mounted() {
    this.isBusy = true;
    let res = await api.get(this.apiRoute);
    this.result = res.data;
    this.checkMockPieChart(res.data);
    this.legendValue = this.result.count;
    this.label = this.result.label;
    this.fillData();
    this.fillOption();
    setTimeout(() => {
      this.isBusy = false;
    }, 1 * 1000);
  },
  methods: {
    fillData() {
      this.datacollection = {
        labels: this.label,
        datasets: [
          {
            backgroundColor: [
              "rgb(235,129,35)",
              "rgb(253, 236, 210)",
              "rgb(255, 181, 34)",
              "rgb(255, 213, 0)",
              "rgb(255, 145, 0)",
              "rgb(255, 216, 108)",
              "rgb(232, 177, 0)",
              "rgb(255, 229, 159)",
              "rgb(235, 129, 35)",
              "rgb(255, 234, 128)",
              "rgb(237,237,237)",
            ],

            data: this.legendValue,
          },
        ],
        borderWidth: 5,
      };
    },
    fillOption() {
      this.option = {
        legend: {
          display: false,
          position: "right",
          labels: {
            fontSize: 20,
            fontColor: "#000",
          },
        },
        plugins: {
          datalabels: {
            color: "black",
            labels: {
              title: {
                font: {
                  weight: "bold",
                  size: 16,
                },
              },
            },
            formatter: (value, ctx) => {
              if (value > 0) {
                let sum = 0;
                let dataArr = ctx.chart.data.datasets[0].data;
                dataArr.map((data) => {
                  sum += data;
                });
                sum = sum.toString();
                sum = sum.split(/(?=(?:...)*$)/);
                sum = sum.join(",");
                sum = null;
                return sum;
              } else {
                value = "";
                return value;
              }
            },
          },
        },

        elements: {
          arc: {
            borderWidth: 0,
          },
        },

        maintainAspectRatio: false,
        responsive: true,
      };
    },
    getRandomInt() {
      let n = Math.floor(Math.random() * (1000 - 500 + 1)) + 500;
      let n1 = Math.floor(Math.random() * (n - 0 + 1)) + 0;
      let n2 = n - n1;

      return { n1, n2 };
    },
  },
};
</script>

<style scoped>
.std-all-box {
  font-size: 15px;
  color: white;
  background-color: #eb8123;
}
h6 {
  font-size: 19px;
  color: #737373;
  font-family: "Prompt", sans-serif;
}
</style>
