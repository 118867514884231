<template>
  <div>
    <layout>
      <template v-slot:header>
        <h6 style="font-family: 'Prompt', sans-serif;">จำนวนชั่วโมงเรียนรวมในแต่ละช่วงเวลา</h6>
      </template>
      <template v-slot:body>
        <b-form-select
          v-model="month"
          :options="months"
          class="m-3"
        ></b-form-select>
        <line-chart
          :chartData="data"
          :chartOption="options"
          :chartHeight="150"
        ></line-chart>
      </template>
    </layout>
  </div>
</template>

<script>
import LineChart from "@/components/pages/analytics/shared/LineChart";
import layout from "@/components/pages/analytics/shared/Layout";
import api from "@/services/api.js";
import moment from "moment";
export default {
  components: {
    LineChart,
    layout
  },
  props: ["apiRoute"],
  data() {
    return {
      checkData: false,
      result: {},
      month: moment().format("M"),
      months: [
        { value: "1", text: "มกราคม" },
        { value: "2", text: "กุมภาพันธ์" },
        { value: "3", text: "มีนาคม" },
        { value: "4", text: "เมษายน" },
        { value: "5", text: "พฤษภาคม" },
        { value: "6", text: "มิถุนายน" },
        { value: "7", text: "กรกฏาคม" },
        { value: "8", text: "สิงหาคม" },
        { value: "9", text: "กันยายน" },
        { value: "10", text: "ตุลาคม" },
        { value: "11", text: "พฤศจิกายน" },
        { value: "12", text: "ธันวาคม" }
      ],
      data: {},
      options: {
        elements: {
          point: { radius: 2 },
          line: { borderWidth: 1.5 }
        },
        plugins: {
          datalabels: {
            display: false
          }
        },
        tooltips: {
          enabled: true
        },
        maintainAspectRatio: true,
        responsive: true,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: true
              },
              scaleLabel: {
                display: true,
                labelString: "เวลา",
                fontSize: 12
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: true
              },
              ticks: {
                callback: function(value) {
                  return value;
                },
                beginAtZero: true,
                maxTicksLimit: 20
              },
              scaleLabel: {
                display: true,
                labelString: "ชั่วโมง",
                fontSize: 12
              }
            }
          ]
        } // Add to prevent default behaviour of full-width/height
      }
    };
  },
  methods: {
    async fetch() {
      this.isBusy = true;
      let res = await api.get(this.apiRoute + "&month=" + this.month);
      this.result = res.data;

      this.data = {
        labels: Object.keys(this.result[0]),
        datasets: [
          {
            // Name the series
            data: Object.values(this.result[1]).map(el => el), // Specify the data values array
            fill: false,
            label: "วันธรรมดา (จ. - ศ.)",
            // Add custom color border (Line)
            borderColor: "#F6C72C",
            backgroundColor: "#F6C72C"
          },
          {
            // Name the series
            data: Object.values(this.result[0]).map(el => el), // Specify the data values array
            fill: false,
            label: "วันหยุด (ส. - อา.)",
            // Add custom color border (Line)
            borderColor: "#E76275", // Add custom color background (Points and Fill)
            backgroundColor: "#E76275"
          }
        ]
      };
      setTimeout(() => {
        this.isBusy = false;
      }, 1 * 1000);
    }
  },
  watch: {
    month() {
      this.fetch();
    }
  },
  async mounted() {
    this.fetch();
  }
};
</script>
