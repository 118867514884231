<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <PageHeader title="สรุปภาพรวมคำถาม"></PageHeader>

    <layout class="p-4">
      <template v-slot:header>
        <b-row>
          <b-col class="head-name" cols="6">
            <h6>{{ result[0] && result[0].c_name }}</h6>
          </b-col>
        </b-row>
      </template>
      <template v-slot:body>
        <h6 class="mb-3 mt-3">
          <strong>
            คำถามทั้งหมดในหลักสูตร
            {{ topic }} คำถาม</strong
          >
        </h6>
        <b-row class="card-answer">
          <b-col cols="3">
            <b-card header="คำถามที่ยังไม่ตอบ" class="text-center">
              <b-card-text>
                <h1 class="text-head pt-4">
                  {{ notReplied && notReplied }}
                </h1>
                <div align="end">
                  <span>คำถาม</span>
                </div>
              </b-card-text>
            </b-card>
          </b-col>
          <b-col cols="3">
            <b-card header="คำถามที่ตอบแล้ว" class="text-center">
              <b-card-text>
                <h1 class="text-head pt-4">
                  {{ replied && replied }}
                </h1>
                <div align="end">
                  <span class="text-right">คำถาม</span>
                </div>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
        <hr />
        <b-row class="justify-content-end">
          <b-col cols="auto">
            <b-form inline>
              <label class="mr-2"><b-icon-funnel />ตัวกรอง:</label>
              <b-form-select
                v-model="filter"
                :options="filterOptions"
              ></b-form-select>
            </b-form>
          </b-col>
          <b-col cols="auto">
            <b-form inline>
              <label class="mr-2">เรียงจาก:</label>
              <b-form-select
                v-model="orderBy"
                :options="orderOptions"
              ></b-form-select>
            </b-form>
          </b-col>
        </b-row>
        <CommentList
          :collection="discussions"
          :comments="orderedResult"
          :level="0"
          @posted="fetch"
        ></CommentList>
      </template>
    </layout>
  </div>
</template>
<script>
import PageHeader from "@/components/layouts/PageHeader.vue";
import layout from "@/components/pages/analytics/shared/Layout";
import CommentList from "./component/CommentList.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    PageHeader,
    layout,
    CommentList
  },
  data() {
    return {
      editorConfig: {
        btns: [
          ["formatting"],
          ["justifyLeft", "justifyCenter", "justifyRight"],
          ["unorderedList", "orderedList"],
          ["strong", "em", "link"]
        ],
        autogrow: true,
        removeformatPasted: true
      },
      topic: 0,
      reply: "",
      replied: 0,
      notReplied: 0,
      cId: this.$route.params.cId,
      result: [],
      isBusy: true,
      items: [],
      user: JSON.parse(localStorage.getItem("user")),
      mId: JSON.parse(localStorage.getItem("user")).id,
      filterOptions: [
        { value: null, text: "คำถามทั้งหมด" },
        { value: true, text: "คำถามที่ยังไม่ตอบ" },
        { value: false, text: "คำถามที่ตอบแล้ว" }
      ],
      filter: null,
      orderOptions: [
        { value: "answerAsc", text: "คำตอบน้อยไปมาก" },
        { value: "answerDesc", text: "คำตอบมากไปน้อย" },
        { value: "dateDesc", text: "วันที่โพสต์ล่าสุด" }
      ],
      orderBy: "dateDesc",
      perPage: 10,
      busy: false
    };
  },
  computed: {
    ...mapGetters("Discussion", ["discussions"]),
    orderedResult: function() {
      let filteredResult = [];
      if (this.filter == null) {
        filteredResult = this.discussions.filter(i => i.dis_parent_id == null);
      } else if (this.filter == true) {
        let topic = this.discussions.filter(i => i.dis_parent_id == null);
        for (let i of topic) {
          if (
            this.discussions.filter(j => j.dis_parent_id == i.dis_id).length ==
            0
          )
            filteredResult.push(i);
        }
      } else if (this.filter == false) {
        let topic = this.discussions.filter(i => i.dis_parent_id == null);
        for (let i of topic) {
          if (
            this.discussions.filter(j => j.dis_parent_id == i.dis_id).length > 0
          )
            filteredResult.push(i);
        }
      }
      if (this.orderBy == "answerDesc") {
        filteredResult.sort(function(a, b) {
          return b.replyCount - a.replyCount;
        });
      } else if (this.orderBy == "answerAsc") {
        filteredResult.sort(function(a, b) {
          return a.replyCount - b.replyCount;
        });
      } else if (this.orderBy == "dateDesc") {
        filteredResult.sort(function(a, b) {
          return b.dis_date - a.dis_date;
        });
      }
      return filteredResult;
    }
  },
  methods: {
    ...mapActions("Discussion", ["fetchDiscussions"]),
    getReply(disId) {
      return this.result.filter(i => i.dis_parent_id == disId);
    },
    loadMore() {
      this.busy = true;

      setTimeout(() => {
        this.perPage += 10;
        this.busy = false;
      }, 1000);
    },
    getRandomInt(max, min) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    async fetch() {
      this.fetchDiscussions(this.cId).then(() => {
        this.replied = 0;
        this.notReplied = 0;
        let top = this.discussions.filter(i => i.dis_parent_id == null);
        this.topic = this.discussions
          ? this.discussions.filter(i => i.dis_parent_id == null).length
          : 1;
        for (let i of top) {
          if (
            this.discussions.filter(j => j.dis_parent_id == i.dis_id).length > 0
          )
            this.replied++;
          else this.notReplied++;
        }
        this.currentPage = 1;
        setTimeout(() => {
          this.isBusy = false;
        }, 1 * 1000);
      });
    }
  },
  async mounted() {
    await this.fetch();
  }
};
</script>

<style scoped>
.card-answer h1 {
  font-family: "Prompt", sans-serif;
}
.text-head {
  color: #f0a502;
  font-weight: bold;
  font-family: "Sarabun", sans-serif;
}
.user-profile-image {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  object-fit: cover;
  margin-top: 10px;
}
.user-name {
  font-size: medium;
}
.user-course {
  font-size: x-small;
}
.user-message {
  font-size: medium;
  width: 600px;
}
.expand-collapse-button:hover {
  cursor: pointer;
}
.head-name h6 {
  margin-bottom: 0;
}
.card-header {
  background: #fff;
}
strong {
  font-family: "Prompt", sans-serif;
}
</style>
