<template>
  <div>
    <PageHeader title="อนุมัติการจบคอร์ส" />
    <b-container fluid="xl">
      <div class="py-5">
        <b-row>
          <b-col cols="4">
            <SearchBox
              title="พิมพ์ชื่อคอร์สเรียนที่ต้องการค้นหา"
              @typed="getSearchKeyword"
            />
          </b-col>
        </b-row>

        <template v-if="isLoading">
          <div class="text-center text-csq mt-5">
            <b-spinner class="align-middle" variant="primary"></b-spinner>
            <p>Loading</p>
          </div>
        </template>
        <div v-else-if="rows <= 0" class="text-center">
          <p class="mt-5">ไม่มีคอร์สที่เรียนจบ</p>
        </div>
        <div v-else>
          <b-table
            id="course-completion-list"
            :items="tableData"
            :fields="fields"
            :sort-by.sync="sort.by"
            :sort-desc.sync="sort.desc"
            :per-page="perPage"
            responsive
            sort-icon-left
            table-class="mt-4 border-bottom"
          >
            <template v-slot:cell(image)="props">
              <b-img-lazy
                slot="aside"
                v-bind="imageProps"
                :src="baseUrl + props.item.c_thumbnailPath"
                class="course-image"
                alt
              ></b-img-lazy>
            </template>
            <template v-slot:cell(courseName)="props">
              <router-link
                v-if="props.item.ccr_rule != null && props.item.ccr_requireApprovalBy != null"
                :to="{
                  name: 'course.complete.member.list',
                  params: { courseId: props.item.c_id },
                }"
              >
                {{ props.item.c_name }}
              </router-link>
              <h6 v-else>{{ props.item.c_name }}</h6>
            </template>
            <template v-slot:cell(courseOwnerName)="props">
              {{
                props.item.mp_prefixName
                  ? props.item.mp_prefixName
                  : "" + props.item.mp_firstName + " " + props.item.mp_lastName
              }}
            </template>
            <template v-slot:cell(actions)="props">
              <b-button
                v-if="props.item.ccr_rule != null"
                variant="outline-primary"
                size="sm"
                @click="redirectToCourseCompleteInfo(props.item.c_id)"
                >ประวัติการดำเนินการ</b-button
              >
            </template>
          </b-table>
        </div>
      </div>
      <b-row class="mt-4">
        <b-col cols="6">
          <div class="d-flex justify-content-start align-items-center">
            <p class="pr-3">แสดง</p>
            <b-form-select
              v-model="perPage"
              :options="[5, 10, 15, 20]"
              style="width: 75px;"
            ></b-form-select>
            <p class="pl-3">จาก {{ rows }} รายการ</p>
          </div>
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            align="right"
            aria-controls="course-completion-list"
            last-number
          ></b-pagination>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import PageHeader from "@/components/layouts/PageHeader";
import SearchBox from "@/components/shared/SearchBox";
import Numeral from "numeral";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { PageHeader, SearchBox },
  data() {
    return {
      fields: [
        { label: "", key: "image" },
        { label: "ชื่อคอร์สเรียน", key: "courseName", sortable: true },
        { label: "ผู้สอน", key: "courseOwnerName" },
        {
          label: "จำนวนผู้เรียนทั้งหมด",
          key: "total_students",
          sortable: true,
          class: "text-right",
          thStyle: { width: "16%" },
          formatter: (value) => {
            return Numeral(value).format("0,0");
          },
        },
        {
          label: "จำนวนรออนุมัติจบคอร์ส",
          key: "pending_approvals",
          sortable: true,
          class: "text-right",
          thStyle: { width: "18%" },
          formatter: (value, key, item) => {
            if (item.ccr_rule == null || item.ccr_requireApprovalBy == null) {
              return "ไม่ต้องอนุมัติ";
            }
            return Numeral(value).format("0,0");
          },
        },
        {
          label: "",
          key: "actions",
          class: "text-right",
          thStyle: { width: "16%" },
        },
      ],
      sort: {
        by: "c_name",
        desc: false,
      },
      perPage: 5,
      currentPage: 1,
      imageProps: {
        center: false,
        fluidGrow: false,
        blank: true,
        blankColor: "#bbb",
        width: 64,
        height: 48,
        class: "course-image",
      },
      baseUrl: process.env.VUE_APP_S1_URL || "https://s1-dev.coursesquare.com/",
      searchKeyword: null,
    };
  },
  computed: {
    ...mapGetters("CourseCompletion", ["isLoading", "courseCompletionList"]),
    tableData() {
      let filteredData;
      if (this.searchKeyword) {
        filteredData = this.courseCompletionList.filter((item) =>
          item.c_name.toLowerCase().includes(this.searchKeyword.toLowerCase())
        );
      } else {
        filteredData = this.courseCompletionList;
      }

      const firstIndex = (this.currentPage - 1) * this.perPage;
      const paginatedData = filteredData.slice(
        firstIndex,
        firstIndex + this.perPage
      );

      return paginatedData;
    },
    rows() {
      if (this.searchKeyword) {
        let filteredData = this.courseCompletionList.filter((item) =>
          item.c_name.toLowerCase().includes(this.searchKeyword.toLowerCase())
        );
        return filteredData.length;
      }
      return this.courseCompletionList.length;
    },
  },
  async mounted() {
    await this.fetchCourseCompletionList();
  },
  methods: {
    ...mapActions("CourseCompletion", ["fetchCourseCompletionList"]),
    redirectToCourseCompleteInfo(courseId) {
      this.$router.push({
        name: "course.completed.log",
        params: { courseId: courseId },
      });
    },
    getSearchKeyword(keyword) {
      this.currentPage = 1;
      this.searchKeyword = keyword;
    },
  },
};
</script>
