<template>
  <div class>
    <div>
      <doughnut
        :chart-data="datacollection"
        :options="option"
        style="width: 350px; height: 350px;"
      ></doughnut>
    </div>
  </div>
</template>

<script>
import doughnut from "@/components/charts/DonutChart.js";

export default {
  components: {
    doughnut
  },
  props: {
    bestScoreAnswerSheet: Object
  },
  data() {
    return {
      datacollection: {},
      option: {},
      correct: 0,
      incorrect: 0,
      blank: 0
    };
  },
  watch: {
    bestScoreAnswerSheet() {
      this.updateChart();
    }
  },
  mounted() {
    this.updateChart();
  },
  methods: {
    updateChart() {
      this.setScore();
      this.fillData();
      this.fillOption();
    },
    setScore() {
      if (this.bestScoreAnswerSheet) {
        this.correct = 0;
        this.incorrect = 0;
        this.blank = 0;
        for (let section of this.bestScoreAnswerSheet.sections) {
          this.correct += section.result.correct;
          this.incorrect += section.result.incorrect;
          this.blank += section.result.blank;
        }
      }
    },
    fillData() {
      this.datacollection = {
        labels: [
          `ทำถูก ${this.correct} ข้อ`,
          `ทำผิด ${this.incorrect} ข้อ`,
          `ไม่ได้ทำ ${this.blank} ข้อ`
        ],
        datasets: [
          {
            label: "",
            backgroundColor: ["#76BB71", "#DF615A", "#EFC763"],

            data: [this.correct, this.incorrect, this.blank]
          }
        ]
      };
    },
    fillOption() {
      this.option = {
        responsive: true,
        legend: {
          position: "right"
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        plugins: {
          datalabels: {
            display: false // This will hide the data labels
          }
        }
      };
    }
  }
};
</script>

<style scoped>
.small {
  width: 500px;
  margin: auto;
}
</style>
