var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"HoverCourseManagemenrList"}},[_c('ul',{attrs:{"id":"menu-hover-list"}},[_c('li',{staticClass:"hover-menu",attrs:{"id":"dropdown1"},on:{"mouseover":_vm.onOver,"mouseleave":_vm.onLeave}},[_c('b-dropdown',{ref:"dropdown1",staticClass:"hover-menu-company",attrs:{"id":"dropdown-1","dropright":"","no-caret":"","variant":"none","size":"sm"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon',{staticClass:"hover-icon",attrs:{"icon":"building","aria-hidden":"true"}})]},proxy:true}])},[_c('div',{staticClass:"link-title hover-title"},[_vm._v(" "+_vm._s(_vm.profile.company.displayName)+" ")]),_vm._l((_vm.userWorkAtCompanies),function(item,index){return _c('b-dropdown-item',{key:index,staticClass:"hover-menu-item",attrs:{"href":`/${item.company.url}`}},[_c('strong',{staticClass:"m-0 p-0 d-block"},[_vm._v(" "+_vm._s(item.company.displayName)+" ")]),_c('span',{staticClass:"small"},[_vm._v(_vm._s(item.fullName))])])})],2)],1),(
        _vm.hasRoles([
          'org/admin',
          'org/instructor',
          'org/hr',
          'org/group_admin',
          'tutor/admin',
          'tutor/instructor',
          'tutor/content_creator',
        ])
      )?_c('li',{staticClass:"hover-menu",attrs:{"id":"dropdown_courseManage"},on:{"mouseover":_vm.onOver,"mouseleave":_vm.onLeave}},[_c('b-dropdown',{ref:"dropdown_courseManage",attrs:{"id":"dropdown_courseManage","dropright":"","no-caret":"","variant":"none","size":"sm"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon-book-half',{staticClass:"mr-1 hover-icon"})]},proxy:true}],null,false,2117351697)},[_c('div',{staticClass:"link-title hover-title"},[_vm._v("คอร์สเรียน")]),_vm._l((_vm.hoverlist['dropdown_courseManage']),function(item){return _c('b-dropdown-item',{key:item.title,staticClass:"hover-menu-item",class:_vm.getCollapseListIsActive(item),attrs:{"variant":"none"},on:{"click":function($event){return _vm.onGoto(item.to.name)}}},[_vm._v(" "+_vm._s(item.title)+" ")])})],2)],1):_vm._e(),(
        _vm.hasRoles([
          'org/admin',
          'org/hr',
          'org/group_admin',
          'org/audit',
          'tutor/admin',
          'tutor/instructor',
          'org/instructor'
        ])
      )?_c('li',{staticClass:"hover-menu",attrs:{"id":"dropdown_member"},on:{"mouseover":_vm.onOver,"mouseleave":_vm.onLeave}},[_c('b-dropdown',{ref:"dropdown_member",attrs:{"id":"dropdown_member","dropright":"","no-caret":"","variant":"none","size":"sm"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon-people-fill',{staticClass:"mr-1"})]},proxy:true}],null,false,75162039)},[_c('div',{staticClass:"link-title menu_hide hover-title"},[_vm._v("ผู้เรียน")]),_vm._l((_vm.hoverlist['dropdown_member']),function(item){return _c('b-dropdown-item',{key:item.title,staticClass:"hover-menu-item",class:_vm.getCollapseListIsActive(item),on:{"click":function($event){return _vm.onGoto(item.to.name)}}},[_vm._v(" "+_vm._s(item.title)+" ")])})],2)],1):_vm._e(),(
        _vm.hasRoles([
          'org/admin',
          'org/hr',
          'org/instructor',
          'org/group_admin',
          'tutor/admin',
          'tutor/instructor',
        ])
      )?_c('li',{staticClass:"hover-menu",attrs:{"id":"dropdown_main_exam"},on:{"mouseover":_vm.onOver,"mouseleave":_vm.onLeave}},[_c('b-dropdown',{ref:"dropdown_main_exam",attrs:{"id":"dropdown_main_exam","dropright":"","no-caret":"","variant":"none","size":"sm"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon-file-text-fill',{staticClass:"mr-1"})]},proxy:true}],null,false,3169342082)},[_c('div',{staticClass:"link-title menu_hide hover-title"},[_vm._v("ข้อสอบ")]),_vm._l((_vm.hoverlist['dropdown_main_exam']),function(item){return _c('b-dropdown-item',{key:item.title,staticClass:"hover-menu-item",class:_vm.getCollapseListIsActive(item),on:{"click":function($event){return _vm.onGoto(item.to.name)}}},[_vm._v(" "+_vm._s(item.title)+" ")])})],2)],1):_vm._e(),(
        _vm.hasRoles([
          'org/admin',
          'org/instructor',
          'tutor/admin',
          'tutor/instructor',
        ]) && _vm.isInsuranceType
      )?_c('li',{staticClass:"hover-menu",attrs:{"id":"dropdown_course_complete"},on:{"mouseover":_vm.onOver,"mouseleave":_vm.onLeave}},[_c('b-dropdown',{ref:"dropdown_course_complete",attrs:{"id":"dropdown_course_complete","dropright":"","no-caret":"","variant":"none","size":"sm"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',[_c('i',{staticClass:"bi bi-clipboard-check-fill"})])]},proxy:true}],null,false,3534608401)},[_c('div',{staticClass:"link-title menu_hide hover-title"},[_vm._v("อนุมัติการจบคอร์ส")]),_vm._l((_vm.hoverlist['dropdown_course_complete']),function(item){return _c('b-dropdown-item',{key:item.title,staticClass:"hover-menu-item",class:_vm.getCollapseListIsActive(item),on:{"click":function($event){return _vm.onGoto(item.to.name)}}},[_vm._v(" "+_vm._s(item.title)+" ")])})],2)],1):_vm._e(),(
        _vm.hasRoles([
          'org/admin',
          'org/instructor',
          'tutor/admin',
          'tutor/instructor',
        ]) && _vm.comId == 512
      )?_c('li',{staticClass:"hover-menu",attrs:{"id":"dropdown_attendance"},on:{"mouseover":_vm.onOver,"mouseleave":_vm.onLeave}},[_c('b-dropdown',{ref:"dropdown_attendance",attrs:{"id":"dropdown_attendance","dropright":"","no-caret":"","variant":"none","size":"sm"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',[_c('img',{attrs:{"src":require("@/assets/images/icon/icon_attendance.svg"),"alt":"attendance","height":"22px"}})])]},proxy:true}],null,false,967949757)},[_c('div',{staticClass:"link-title menu_hide hover-title"},[_vm._v("การเข้าชั้นเรียน")]),_vm._l((_vm.hoverlist['dropdown_attendance']),function(item){return _c('b-dropdown-item',{key:item.title,staticClass:"hover-menu-item",class:_vm.getCollapseListIsActive(item),on:{"click":function($event){return _vm.onGoto(item.to.name)}}},[_vm._v(" "+_vm._s(item.title)+" ")])})],2)],1):_vm._e(),(
        _vm.hasRoles(['tutor/admin', 'tutor/instructor']) &&
          (_vm.isAccountingType() || _vm.isInsuranceType() || _vm.isTutorType())
      )?_c('li',{staticClass:"hover-menu",attrs:{"id":"dropdown_purchase"},on:{"mouseover":_vm.onOver,"mouseleave":_vm.onLeave}},[_c('b-dropdown',{ref:"dropdown_purchase",attrs:{"id":"dropdown_purchase","dropright":"","no-caret":"","variant":"none","size":"sm"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"cash"}})]},proxy:true}],null,false,1485782563)},[_c('div',{staticClass:"link-title menu_hide hover-title"},[_vm._v("การชำระเงิน")]),_vm._l((_vm.hoverlist['dropdown_purchase']),function(item){return _c('b-dropdown-item',{key:item.title,staticClass:"hover-menu-item",class:_vm.getCollapseListIsActive(item),on:{"click":function($event){return _vm.onGoto(item.to.name)}}},[_vm._v(" "+_vm._s(item.title)+" ")])})],2)],1):_vm._e(),(
        _vm.hasRoles([
          'org/admin',
          'org/instructor',
          'org/hr',
          'org/audit',
          'org/group_admin',
          'tutor/admin',
          'tutor/instructor',
          'tutor/content_creator',
        ]) && !_vm.canSpecificUserAccess
      )?_c('li',{staticClass:"hover-menu",attrs:{"id":"dropdown_analytics"},on:{"mouseover":_vm.onOver,"mouseleave":_vm.onLeave}},[_c('b-dropdown',{ref:"dropdown_analytics",attrs:{"id":"dropdown_analytics","dropright":"","no-caret":"","variant":"none","size":"sm"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon-bar-chart-line-fill',{staticClass:"mr-1"})]},proxy:true}],null,false,1334125063)},[_c('div',{staticClass:"link-title menu_hide hover-title"},[_vm._v("ข้อมูลเชิงลึก")]),_vm._l((_vm.hoverlist['dropdown_analytics']),function(item){return _c('b-dropdown-item',{key:item.title,staticClass:"hover-menu-item",class:_vm.getCollapseListIsActive(item),on:{"click":function($event){return _vm.onGoto(item.to.name)}}},[_vm._v(" "+_vm._s(item.title)+" ")])})],2)],1):_vm._e(),(
        _vm.hasRoles([
          'org/instructor',
          'org/hr',
          'org/audit',
          'org/group_admin',
          'org/admin',
          'tutor/admin',
          'tutor/instructor',
          'tutor/content_creator',
        ]) &&
          (_vm.comId == 485 || _vm.comId == 519 || _vm.comId == 513)
      )?_c('li',{staticClass:"hover-menu",attrs:{"id":"dropdown_report"},on:{"mouseover":_vm.onOver,"mouseleave":_vm.onLeave}},[_c('b-dropdown',{ref:"dropdown_report",attrs:{"id":"dropdown_report","dropright":"","no-caret":"","variant":"none","size":"sm"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon-clipboard',{staticClass:"mr-1"})]},proxy:true}],null,false,2132494138)},[_c('div',{staticClass:"link-title menu_hide hover-title"},[_vm._v("รายงาน")]),_vm._l((_vm.hoverlist['dropdown_report']),function(item){return _c('b-dropdown-item',{key:item.title,staticClass:"hover-menu-item",class:_vm.getCollapseListIsActive(item),on:{"click":function($event){return _vm.onGoto(item.to.name)}}},[_vm._v(" "+_vm._s(item.title)+" ")])})],2)],1):_vm._e(),(
        _vm.hasRoles([
          'org/admin',
          'org/instructor',
          'org/hr',
          'tutor/admin',
          'tutor/instructor',
        ]) && !_vm.isInsuranceType()
      )?_c('li',{staticClass:"hover-menu",attrs:{"id":"dropdown_discuss"},on:{"mouseover":_vm.onOver,"mouseleave":_vm.onLeave}},[_c('b-dropdown',{ref:"dropdown_discuss",attrs:{"id":"dropdown_discuss","dropright":"","no-caret":"","variant":"none","size":"sm"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon-chat-right-text-fill')]},proxy:true}],null,false,2744577298)},[_c('div',{staticClass:"link-title menu_hide hover-title"},[_vm._v("กระดานถาม-ตอบ")]),_vm._l((_vm.hoverlist['dropdown_discuss']),function(item){return _c('b-dropdown-item',{key:item.title,staticClass:"hover-menu-item",class:_vm.getCollapseListIsActive(item),on:{"click":function($event){return _vm.onGoto(item.to.name)}}},[_vm._v(" "+_vm._s(item.title)+" ")])})],2)],1):_vm._e(),(
        _vm.isMenuShow('live') &&
        (
          ( 
            _vm.hasRoles(['org/admin', 'tutor/admin', 'tutor/content_creator']) &&
            [112, 180, 277, 505, 516].includes(_vm.comId) 
          ) || 
          _vm.comId == 512
        )
      )?_c('li',{staticClass:"hover-menu",attrs:{"id":"dropdown_live"},on:{"mouseover":_vm.onOver,"mouseleave":_vm.onLeave}},[_c('b-dropdown',{ref:"dropdown_live",attrs:{"id":"dropdown_live","dropright":"","no-caret":"","variant":"none","size":"sm"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon-camera-video-fill')]},proxy:true}],null,false,3995322708)},[_c('div',{staticClass:"link-title menu_hide hover-title"},[_vm._v("Live")]),_vm._l((_vm.hoverlist['dropdown_live']),function(item){return _c('b-dropdown-item',{key:item.title,staticClass:"hover-menu-item",class:_vm.getCollapseListIsActive(item),on:{"click":function($event){return _vm.onGoto(item.to.name)}}},[_vm._v(" "+_vm._s(item.title)+" ")])})],2)],1):_vm._e(),(
        _vm.isMenuShow('affiliate') &&
          _vm.hasRoles(['tutor/admin', 'tutor/instructor']) &&
          _vm.isTutorType() && !_vm.canSpecificUserAccess
      )?_c('li',{staticClass:"hover-menu",attrs:{"id":"dropdown_affiliate"},on:{"mouseover":_vm.onOver,"mouseleave":_vm.onLeave}},[_c('b-dropdown',{ref:"dropdown_affiliate",attrs:{"id":"dropdown_affiliate","dropright":"","no-caret":"","variant":"none","size":"sm"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon',{attrs:{"icon":"link45deg"}})]},proxy:true}],null,false,1874171326)},[_c('div',{staticClass:"link-title menu_hide hover-title"},[_vm._v("Affiliate Program")]),_vm._l((_vm.hoverlist['dropdown_affiliate']),function(item){return _c('b-dropdown-item',{key:item.title,staticClass:"hover-menu-item",class:_vm.getCollapseListIsActive(item),on:{"click":function($event){return _vm.onGoto(item.to.name)}}},[_vm._v(" "+_vm._s(item.title)+" ")])})],2)],1):_vm._e(),(
        _vm.isMenuShow('etc') && _vm.hasRoles(['org/admin', 'tutor/admin']) && (!_vm.isInsuranceType() || _vm.comId == 503) && !_vm.canSpecificUserAccess
      )?_c('li',{attrs:{"id":"dropdown_ect"},on:{"mouseover":_vm.onOver,"mouseleave":_vm.onLeave}},[_c('b-dropdown',{ref:"dropdown_ect",attrs:{"id":"dropdown_ect","dropright":"","no-caret":"","variant":"none","size":"sm"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon-display')]},proxy:true}],null,false,1000326697)},[_c('p',{staticClass:"link-title menu_hide hover-title"},[_vm._v("อื่นๆ")]),_vm._l((_vm.hoverlist['dropdown_ect']),function(item){return _c('b-dropdown-item',{key:item.title,staticClass:"hover-menu-item",class:_vm.getCollapseListIsActive(item),on:{"click":function($event){return _vm.onGoto(item.to.name)}}},[_vm._v(" "+_vm._s(item.title)+" ")])})],2)],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }