<template>
  <div class>
    <layout>
      <template v-slot:header>
        <b-row>
          <b-col class="text-left" cols="6"
            ><h6 style="font-family: 'Prompt', sans-serif;">ผู้เรียนที่เข้าเรียนล่าสุด</h6></b-col
          >
          <b-col class="text-right" cols="6"
            ><a
              :href="`/${company.url}/analytics/users/recent-study`"
              target="_blank"
              >ดูทั้งหมด</a
            ></b-col
          >
        </b-row>
      </template>
      <template v-slot:body>
        <b-row class="mb-3 mt-3">
          <b-col cols="4" v-if="!isTutor">
            <b-form inline>
              <label class="mr-sm-2" for="recent-st">สาขา</label>
              <b-form-select
                id="recent-st"
                class="mb-2 ml-lg-2 mr-sm-2 mb-sm-0 fix-length"
                v-model="departmentSelected"
                :options="departmentOptions"
                @change="fetchTopTenRecentStudyStudent"
              ></b-form-select>
            </b-form>
          </b-col>
          <b-col cols="6" v-if="!isTutor">
            <b-form inline>
              <label class="mr-sm-2" for="recent-group">กลุ่มตำแหน่ง</label>
              <b-form-select
                id="recent-group"
                class="mb-2 ml-lg-2 mr-sm-2 mb-sm-0 fix-length"
                v-model="groupSelected"
                :options="groupOptions"
                @change="fetchTopTenRecentStudyStudent"
              ></b-form-select>
            </b-form>
          </b-col>
        </b-row>
        <div class>
          <b-table
            id="recent-study-table"
            table-class="tbNumber text-left"
            :items="items"
            :fields="checkFields()"
            small
            sort-icon-left
            responsive
            :busy="items === null"
            hover
            striped
          >
            <template v-slot:cell(memberName)="data">
              <a
                :href="
                  `/${company.url}/analytics/users/${data.item.m_id}/learning-stats`
                "
                target="_blank"
              >
                {{ data.item.memberName ? data.item.memberName : "-" | cut }}
              </a>
            </template>
            <template v-slot:cell(departmentName)="data">
              <span>{{
                data.item.departmentName ? data.item.departmentName : "-" | cut
              }}</span>
            </template>
            <template v-slot:cell(studyGroupName)="data">
              <span>{{
                data.item.studyGroupName ? data.item.studyGroupName : "-" | cut
              }}</span>
            </template>
            <template v-slot:cell(courseName)="data">
              <span>{{
                data.item.courseName ? data.item.courseName : "-" | cut(10)
              }}</span>
            </template>
            <template v-slot:cell(status)="data">
              <span>{{ getRecordStatus(data.item.status) }}</span>
            </template>
            <template v-slot:cell(lastLogin)="data">
              <span>{{ data.item.lastLogin | thaiDateFilter }}</span>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
import layout from "@/components/pages/analytics/shared/Layout";
import dateMixins from "@/components/pages/analytics/shared/mixins/date.js";
import api from "@/services/api.js";
import AuthMixin from "@/mixins/auth-mixin.js";
import moment from 'moment';

export default {
  components: {
    layout
  },
  mixins: [dateMixins, AuthMixin],
  data() {
    return {
      company: {},
      groupSelected: "*",
      groupOptions: [],
      departmentSelected: "*",
      departmentOptions: [],
      fields: [
        { key: "memberName", label: "รายชือผู้เรียน" },
        { key: "studyGroupName", label: "กลุ่มเรียน" },
        { key: "departmentName", label: "สาขา" },
        { key: "courseName", label: "คอร์สที่ลงเรียน" },
        { key: "status", label: "สถานะผู้เรียน" },
        { key: "lastLogin", label: "เข้าสู่ระบบล่าสุด" }
      ],
      tutorFields: [
        { key: "memberName", label: "รายชือผู้เรียน" },
        { key: "email", label: "อีเมล" },
        { key: "phoneNumber", label: "เบอร์โทรศัพท์" },
        { key: "courseName", label: "คอร์สที่ลงเรียน" },
        { key: "status", label: "สถานะผู้เรียน" },
        { key: "lastLogin", label: "เข้าสู่ระบบล่าสุด" }
      ],
      periodDateTo: moment().format('YYYY-MM-DD'),
      periodDateFrom: moment()
        .subtract(1, 'month')
        .format('YYYY-MM-DD'),
      items: [],
    };
  },
  mounted() {
    this.setCompanyInfo();
    this.setGroupOptions();
    this.setDepartmentOptions();
    this.fetchTopTenRecentStudyStudent();
  },

  methods: {
    checkFields() {
      if (this.isTutor == true) {
        return this.tutorFields;
      } else {
        return this.fields;
      }
    },

    setCompanyInfo() {
      this.company = { ...this.$store.state.Company.companyInfo };
    },
    async setGroupOptions() {
      try {
        // Fetch the company groups from backend
        let results = await api.get(`/v1/companies/${this.company.id}/groups`);
        this.groupOptions = results.data.data.map(el => {
          return { value: el.id, text: el.name };
        });
        // Add the total option in the first place
        this.groupOptions.unshift({
          value: "*",
          text: "ทั้งหมด"
        });
      } catch (err) {
        console.log("setGroupOptions error", err);
      }
    },
    async setDepartmentOptions() {
      try {
        // Fetch the company departments from backend
        let results = await api.get(
          `/v1/companies/${this.company.id}/departments`
        );
        this.departmentOptions = results.data.map(el => {
          // Handle null case
          if (!el) {
            return { value: "null", text: "ไม่มี" };
          }
          return { value: el.replace(/ /g, "!"), text: el };
        });
        // Add the total option in the first place
        this.departmentOptions.unshift({
          value: "*",
          text: "ทั้งหมด"
        });
      } catch (err) {
        console.log("setDepartmentOptions error", err);
      }
    },
    async fetchTopTenRecentStudyStudent() {
      try {
        let queryString = `start=${this.periodDateFrom}&end=${this.periodDateTo}&group=${this.groupSelected}&department=${this.departmentSelected}&topten=true`;
        const res = await api.get(
          `/v1/analytics/students/learning-recent-study-stats?${queryString}`
        );
        this.items = res.data;
      } catch (err) {
        console.log("fetchTopTenRecentStudyStudent error", err);
      }
    },
    getRecordStatus(item) {
      if (!item) {
        return "-";
      } else {
        let message = "-";
        switch (String(item)) {
          case "0":
            message = "";
            break;
          case "IN_PROGRESS":
            message = "กำลังเรียน";
            break;
          case "NOT_STARTED":
            message = "ยังไม่เริ่มเรียน";
            break;
          case "REMOVED":
            message = "ลบข้อมูลการเรียน";
            break;
          case "CANCEL":
            message = "ยกเลิกการเรียน";
            break;
          case "TIMEOUT":
            message = "หมดเวลาเรียน";
            break;
          case "BLOCKED":
            message = "ระงับการเรียนแล้ว";
            break;
          case "CANCELED":
            message = "ยกเลิกการเรียนแล้ว";
            break;
          case "COMPLETED":
            message = "เรียนเสร็จแล้ว";
            break;
          case "HOLD":
            message = "พักการเรียนชั่วคราว";
            break;
          case "DELETED":
            message = "ลบข้อมูลการเรียน";
            break;
          case "EXPIRED":
            message = "เลยกำหนดเวลาเรียน";
            break;
        }
        return message;
      }
    }
  }
};
</script>
<style scoped>
.fix-length {
  width: 55% !important;
}
.center-cols {
  align-self: center !important;
}
</style>
