import { render, staticRenderFns } from "./PaymentSummaryPanel.vue?vue&type=template&id=2d8ede55&scoped=true&"
import script from "./PaymentSummaryPanel.vue?vue&type=script&lang=js&"
export * from "./PaymentSummaryPanel.vue?vue&type=script&lang=js&"
import style0 from "./PaymentSummaryPanel.vue?vue&type=style&index=0&id=2d8ede55&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d8ede55",
  null
  
)

export default component.exports