import CourseCompleteListPage from "@/components/pages/approve-course-complete/CourseCompleteListPage.vue";
import CourseCompleteMemberListPage from "@/components/pages/approve-course-complete/CourseCompleteMemberListPage.vue";
import ApproveEnrollLogPage from "@/components/pages/approve-course-complete/ApproveEnrollLogPage.vue";

export default [
  {
    path: "course/course-complete-list",
    name: "course.complete.list",
    component: CourseCompleteListPage,
  },
  {
    path: "course/:courseId/course-complete-member-list",
    name: "course.complete.member.list",
    component: CourseCompleteMemberListPage
  },
  {
    path: "course/:courseId/approve-enroll-log",
    name: "course.completed.log",
    component: ApproveEnrollLogPage
  }, 
];
