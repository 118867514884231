<template>
  <div class="form-row" v-if="companyId != 222">
    <b-form-group class="col-sm-12 col-md-6 col-lg-4">
      <label>รหัสผ่าน</label>
      <b-form-input
        v-model="form.password"
        :state="passwordState"
        type="password"
        placeholder="รหัสผ่านอย่างน้อย 5 หลัก"
        trim
        @keyup="typePassword"
      ></b-form-input>
      <b-form-invalid-feedback
        >กรุณากรอกรหัสผ่านอย่างน้อย 5 หลัก</b-form-invalid-feedback
      >
      <b-form-valid-feedback></b-form-valid-feedback>
    </b-form-group>

    <b-form-group
      v-if="form.password && validPassword(form.password)"
      class="col-sm-12 col-md-6 col-lg-4"
    >
      <label>ยืนยันรหัสผ่าน</label>
      <b-form-input
        v-model="form.passwordConfirm"
        :state="confirmPasswordState"
        :class="{ 'is-invalid': errors.confirmPassword }"
        type="password"
        placeholder="กรอกรหัสผ่านอีกครั้ง"
        trim
        @keyup="confirmPassword"
      ></b-form-input>
      <b-form-invalid-feedback
        >กรุณากรอกรหัสผ่านให้ตรงกัน</b-form-invalid-feedback
      >
      <b-form-valid-feedback></b-form-valid-feedback>
    </b-form-group>
  </div>
  <!-- company 222 ( Roche ) -->
  <div class="form-row" v-else>
    <b-form-group class="col-sm-12 col-md-6 col-lg-4">
      <label>รหัสผ่าน </label>
      <b-form-input
        v-model="form.password"
        :state="!form.password ? null : validation.password"
        type="password"
        placeholder="รหัสผ่านอย่างน้อย 4 หลัก"
        trim
        @keyup="form.passwordConfirm = ''"
      ></b-form-input>
      <b-form-invalid-feedback
        >กรุณากรอกรหัสผ่านอย่างน้อย 4 หลัก</b-form-invalid-feedback
      >
      <b-form-valid-feedback></b-form-valid-feedback>
    </b-form-group>

    <b-form-group
      v-if="form.password && validPasswordRoche(form.password)"
      class="col-sm-12 col-md-6 col-lg-4"
    >
      <label>ยืนยันรหัสผ่าน</label>
      <b-form-input
        v-model="form.passwordConfirm"
        :state="!form.passwordConfirm ? null : validation.passwordConfirm"
        :class="{ 'is-invalid': errors.confirmPassword }"
        type="password"
        placeholder="กรอกรหัสผ่านอีกครั้ง"
        trim
        @keyup="confirmPassword"
      ></b-form-input>
      <b-form-invalid-feedback
        >กรุณากรอกรหัสผ่านให้ตรงกัน</b-form-invalid-feedback
      >
      <b-form-valid-feedback></b-form-valid-feedback>
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: {
    errors: null,
    companyId: Number,
    isSetPassword: Boolean,
    isSetConfirmPassword: Boolean,
  },

  data() {
    return {
      form: {
        password: "",
        passwordConfirm: "",
      },
    };
  },

  computed: {
    passwordState() {
      if (this.isSetPassword == false) {
        return false;
      } else if (this.isSetPassword == null && !this.form.password) {
        return null;
      } else if (this.isSetPassword == null && this.form.password) {
        return this.validation.password;
      }
      return null;
    },
    confirmPasswordState() {
      if (this.isSetConfirmPassword == false) {
        return false;
      } else if (
        this.isSetConfirmPassword == null &&
        !this.form.passwordConfirm
      ) {
        return null;
      } else if (
        this.isSetConfirmPassword == null &&
        this.form.passwordConfirm
      ) {
        return this.validation.passwordConfirm;
      }
      return null;
    },
    validation() {
      const form = this.form;
      if(this.companyId != 222){
        return {
          password: form.password.length >= 5,
          passwordConfirm: form.password == form.passwordConfirm
        };
      }else{
        return {
          password: form.password.length >= 4,
          passwordConfirm: form.password == form.passwordConfirm
        };
      }
    }
  },

  methods: {
    validPassword: input => input.length >= 5,
    validPasswordRoche: input => input.length >=4,
    confirmPassword() {
      if (this.form.password == this.form.passwordConfirm)
        this.$emit("passwordConfirmed", this.form);
    },
    typePassword() {
      this.form.passwordConfirm = "";
    },
  },
};
</script>
