<template>
  <div style>
    <b-row>
      <b-col cols="12">
        <layout>
          <template v-slot:header>
            <h6>สรุปผลผู้ทำข้อสอบ</h6>
          </template>
          <template v-slot:body>
            <div v-if="mockPieChart" :style="mockPieStyle">
              <h6 :style="mockPieText">ไม่มีข้อมูล</h6>
            </div>
            <div v-else style="padding: 0.5rem">
              <b-row>
                <b-col class="text-right d-none d-lg-block" cols="8" offset="2">
                  <p>
                    จากจำนวนผู้เรียน
                    {{ result.fail + notStarted + result.pass }} คน
                  </p>
                </b-col>
                <b-col lg="6">
                  <PieChart
                    :chart-data="datacollection"
                    :options="option"
                    :height="200"
                  ></PieChart>
                </b-col>
                <b-col class="text-center d-lg-none mt-3" cols="12">
                  <p>
                    จากจำนวนผู้เรียน
                    {{ result.fail + notStarted + result.pass }} คน
                  </p>
                </b-col>
                <b-col lg="6">
                  <my-legend
                    :num="3"
                    :color="['#F0A502', '#EB8123', '#BEBEBE']"
                  >
                    <template v-slot:1
                      >จำนวนคนที่ผ่าน
                      {{ result && result.pass }}
                      คน</template
                    >
                    <template v-slot:2
                      >จำนวนคนที่ยังไม่ได้เข้าสอบ
                      {{ total && notStarted }}
                      คน</template
                    >
                    <template v-slot:3
                      >จำนวนคนที่ไม่ผ่าน
                      {{ result && result.fail }}
                      คน</template
                    >
                  </my-legend>
                </b-col>
              </b-row>
            </div>
          </template>
        </layout>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PieChart from "@/components/pages/analytics/shared/PieChart";
import layout from "@/components/pages/analytics/shared/Layout";
import myLegend from "@/components/pages/analytics/shared/LegendPieChart";
import api from "@/services/exam-api.js";
import sqlapi from "@/services/api.js";
import chartMockMixin from "@/mixins/chart-mock-mixin.js";

export default {
  mixins: [chartMockMixin],
  components: {
    PieChart,
    layout,
    myLegend,
  },
  props: ["apiRoute", "apiRoute2"],
  data() {
    return {
      datacollection: {},
      option: {},
      total: 0,
      notStarted: 0,
      result: {},
    };
  },
  async mounted() {
    this.fetch();
  },
  watch: {
    apiRoute() {
      this.fetch();
    },
  },
  methods: {
    async fetch() {
      let res = await api.get(this.apiRoute);
      this.result = res.data;
      this.checkMockPieChart(
        this.result.pass + this.notStarted + this.result.fail
      );
      let count = await sqlapi.get(this.apiRoute2);
      this.total = count.data;
      this.notStarted = this.total.filter(
        (i) => i.lel_status == "NOT_STARTED"
      ).length;
      this.fillData();
      this.fillOption();
    },
    fillData() {
      let data = [this.result.pass, this.notStarted, this.result.fail];
      this.datacollection = {
        labels: ["ผ่าน", "ยังไม่ได้สอบ", "ไม่ผ่าน"],
        datasets: [
          {
            backgroundColor: ["#F0A502", "#EB8123", "#BEBEBE"],
            data: data,
          },
        ],
        borderWidth: 5,
      };
    },
    fillOption() {
      this.option = {
        legend: {
          display: false,
          position: "right",
          labels: {
            fontSize: 20,
            fontColor: "#000",
          },
        },
        plugins: {
          datalabels: {
            color: "black",
            labels: {
              title: {
                font: {
                  weight: "bold",
                  size: 16,
                },
              },
            },
            formatter: (value) => {
              // if (value > 0) {
              //   let sum = 0;
              //   let dataArr = ctx.chart.data.datasets[0].data;
              //   dataArr.map((data) => {
              //     sum += data;
              //   });
              //   sum = sum.toString();
              //   sum = sum.split(/(?=(?:...)*$)/);
              //   sum = sum.join(",");
              //   return sum;
              // } else {
              //   value = "";
              if (value > 0) {
                return value;
              } else return null;
              // }
            },
          },
        },

        elements: {
          arc: {
            borderWidth: 0,
          },
        },

        maintainAspectRatio: false,
        responsive: true,
      };
    },
    getRandomInt() {
      let n = Math.floor(Math.random() * (1000 - 500 + 1)) + 500;
      let n1 = Math.floor(Math.random() * (n - 0 + 1)) + 0;
      let n2 = n - n1;

      return { n1, n2 };
    },
  },
};
</script>

<style scoped>
.std-all-box {
  font-size: 15px;
  color: white;
  background-color: #eb8123;
}
p, h6 {
  font-family: "Prompt", sans-serif;
}
</style>
