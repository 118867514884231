<template>
  <div>
    <b-row>
      <!-- Member information -->
      <b-col lg="3">
        <div>
          <layout>
            <template v-slot:header>
              <h6>ข้อมูลผู้เรียน</h6>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col cols="12">
                  <div class="text-center mt-2">
                    <b-img
                      class="profile-image-style"
                      :src="memberInfo ? memberInfo.avatarUrl : ''"
                      rounded="circle"
                    ></b-img>
                  </div>
                </b-col>
                <b-col cols="12">
                  <div
                    class="d-flex justify-content-center align-items-center pl-1 pr-1"
                  >
                    <span
                      style="font-weight: 500; font-size: 0.95rem"
                      class="pt-2 pb-3 text-center"
                      >{{
                        memberInfo
                          ? memberDisplayName()
                          : 'Loading ...'
                      }}</span
                    >
                  </div>
                </b-col>
                <div v-if="!isTutor">
                  <b-col cols="12">
                    <div class="ml-2">
                      <p><strong>บริษัท: </strong></p>
                      <span>{{
                        memberInfo
                          ? companyDisplayName()
                          : 'Loading ...'
                      }}</span>
                    </div>
                  </b-col>
                  <b-col cols="12">
                    <div class="ml-2 my-2">
                      <p><strong>สาขา: </strong></p>
                      <span>{{
                        memberInfo ? memberInfo.department : 'Loading ...'
                      }}</span>
                    </div>
                  </b-col>
                </div>
                <div v-else>
                  <b-col cols="12">
                    <div class="ml-2 my-2">
                      <p><strong>อีเมล: </strong></p>
                      <span>{{
                        memberInfo ? memberInfo.email : 'Loading...'
                      }}</span>
                    </div>
                  </b-col>
                  <b-col cols="12">
                    <div class="ml-2 my-2">
                      <p><strong>เบอร์โทรศัพท์: </strong></p>
                      <span>{{
                        memberInfo ? memberInfo.phoneNumber : 'Loading...'
                      }}</span>
                    </div>
                  </b-col>
                  <b-col cols="12">
                    <div class="ml-2 my-2">
                      <p><strong>สถานศึกษา: </strong></p>
                      <span>{{
                        memberInfo
                          ? companyDisplayName()
                          : 'Loading ...'
                      }}</span>
                    </div>
                  </b-col>
                </div>
              </b-row>
            </template>
          </layout>
        </div>
      </b-col>
      <!-- Finish course -->
      <b-col lg="3" class="mt-3 mt-lg-0">
        <div
          class="d-flex align-items-center flex-column justify-content-center h-100 card-layout"
        >
          <span class="ind-sm-card">จำนวนคอร์สที่เรียนจบ</span>

          <h2 class="text-head pt-4">{{ getMemberCompleteCourses }}</h2>
        </div>
      </b-col>
      <!-- Enroll course -->
      <b-col lg="3" class="mt-3 mt-lg-0">
        <div
          class="d-flex align-items-center flex-column justify-content-center h-100 card-layout"
        >
          <span class="ind-sm-card">จำนวนคอร์สที่ลงเรียน</span>

          <h1 class="text-head pt-4">{{ getMemberEnrollCourses }}</h1>
        </div>
      </b-col>
      <!-- Available lecture -->
      <b-col lg="3" class="mt-3 mt-lg-0">
        <div
          class="d-flex align-items-center flex-column justify-content-center h-100 card-layout"
        >
          <span class="ind-sm-card">จำนวนบทเรียนที่ลงเรียน</span>
          <h1 class="text-head pt-4">{{ getMemberStudiedLectures }}</h1>
        </div>
      </b-col>
      <!-- Percent of progress -->
    </b-row>
  </div>
</template>

<script>
import layout from '@/components/pages/analytics/shared/Layout.vue';
import api from '@/services/api.js';
import AuthMixin from '@/mixins/auth-mixin.js';

export default {
  components: {
    layout,
  },
  mixins: [AuthMixin],
  data() {
    return {
      memberId: this.$route.params.mId,
      memberInfo: null,
      memberInfoAvatarUrl: null,
      memberCourses: [],
      memberStudiedLectures: [],
      comId: this.$store.state.Company.companyInfo.id,
    };
  },
  computed: {
    getMemberCompleteCourses() {
      return this.memberCourses.filter((el) => el.status === 'COMPLETED')
        .length;
    },
    getMemberEnrollCourses() {
      return this.memberCourses.length;
    },
    getMemberStudiedLectures() {
      return this.memberStudiedLectures.filter(
        (el) => el.moduleLectureStatus === 'PUBLISH'
      ).length;
    },
  },
  created() {
    this.memberId = this.$route.params.mId;
    this.fetchMemberInfo();
    this.fetchMemberCourse();
    this.fetchMemberStudiedLecture();
  },
  methods: {
    async fetchMemberInfo() {
      try {
        const endpoint = `/v1/members/${this.memberId}`;
        const results = await api.get(endpoint);
        this.memberInfo = results.data.data;
        console.log(this.memberInfo);
      } catch (err) {
        console.log('fetchMemberInfo error', err);
      }
    },
    async fetchMemberCourse() {
      try {
        const endpoint = `/v1/members/${this.memberId}/courses`;
        const results = await api.get(endpoint);
        this.memberCourses = results.data.data;
      } catch (err) {
        console.log('fetchMemberCourse error', err);
      }
    },
    async fetchMemberStudiedLecture() {
      try {
        const endpoint = `/v1/members/${this.memberId}/studied-lectures`;
        const results = await api.get(endpoint);
        this.memberStudiedLectures = results.data.data;
      } catch (err) {
        console.log('fetchMemberStudiedLecture error', err);
      }
    },

    companyDisplayName() {
      const company = this.memberInfo.profiles.find( item => item.company && item.company.id === this.comId );
      if (company) {
        const displayName = company.company.displayName;
        return displayName;
      } else {
        return false;
      }
    },
    memberDisplayName() {
      const company = this.memberInfo.profiles.find( item => item.company && item.company.id === this.comId );
      if (company) {
        const memberName = company.fullName;
        return memberName;
      } else {
        return false;
      }
    }
  },
};
</script>

<style scoped>

.card-layout:hover {
    box-shadow: 2px 2px 9px rgb(0 0 0 / 20%);
}
.card-layout {
    box-shadow: 2px 2px 9px rgb(0 0 0 / 16%);
    border-radius: 6px;
    padding: 20px 35px;
}
.text-head {
  color: rgb(235, 129, 35);
  font-size: 13vmin;
  text-align: center;
}

.profile-image-style {
  width: 6rem;
  height: 6rem;
}

.test-red-border {
  border: 2px solid red;
  padding: 5px;
}

.test-blue-border {
  border: 2px solid blue;
  padding: 5px;
}

.test-green-border {
  border: 2px solid green;
  padding: 5px;
}
.ind-sm-card {
  font-family: 'Prompt', sans-serif;
  font-weight: 500;
  font-size: 20px;
}
h6, p {
  font-family: "Prompt", sans-serif;
}
</style>
