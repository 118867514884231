import Vue from "vue";
import Vuex from "vuex";
import Authentication from "./modules/auth/auth";
import Company from "./modules/company/company";
import ExamManagement from "./modules/exam-management/exam-management";
import MemberManagement from "./modules/member-management/member-management";
import Discussion from "./modules/discussion/discussion";
import CourseManagement from "./modules/course-management/course-management";
import CourseEnrollment from "./modules/course-enrollment/course-enrollment";
import CourseGroupManagement from "./modules/course-management/course-group-management";
import CourseCompleteRule from "./modules/course-management/course-complete-rule";
import CourseOrder from "./modules/course-order/course-order"
import LiveMeeting from "./modules/live-management/live-meeting";
import LiveForm from "./modules/live-management/live-form";
import ExamBuilder from "./modules/exam-builder/examBuilder";
import AttendanceManagement from "./modules/attendance/attendance-management";
import LearningHistory from "./modules/learning-history/learning-history";
import CourseCompletion from "./modules/course-completion/course-completion";

Vue.use(Vuex);

const registerModules = {
  Authentication,
  Company,
  ExamManagement,
  CourseManagement,
  CourseEnrollment,
  CourseGroupManagement,
  CourseCompleteRule,
  CourseOrder,
  MemberManagement,
  LiveMeeting,
  LiveForm,
  Discussion,
  ExamBuilder,
  AttendanceManagement,
  LearningHistory,
  CourseCompletion
};

const store = new Vuex.Store({
  modules: {
    Authentication,
    Company,
    ExamManagement,
    MemberManagement,
    CourseManagement,
    CourseEnrollment,
    CourseGroupManagement,
    CourseCompleteRule,
    CourseOrder,
    LiveMeeting,
    LiveForm,
    Discussion,
    ExamBuilder,
    AttendanceManagement,
    LearningHistory,
    CourseCompletion
  },
});

// this loop will automatically call  modulename/init for reseting state to default.
for (const moduleName in registerModules) {
  if (registerModules[moduleName].actions?.init) {
    store.dispatch(`${moduleName}/init`);
  }
}

export default store;
// export default new Vuex.Store({
//   state: {},
//   mutations: {},
//   actions: {},
//   modules: {}
// });
