<template>
  <div>
    <layout>
      <template v-slot:header>
        <b-row>
          <b-col class="text-left" cols="9">
            <h6 style="font-family: 'Prompt', sans-serif;">
              ผู้เรียนที่เข้าเรียนมากกว่า 1 จังหวัด (30 วันที่ผ่านมา)
            </h6>
          </b-col>
          <b-col class="text-right" cols="3"
            ><a
              :href="`/${company.url}/analytics/users/province-login`"
              target="_blank"
              >ดูทั้งหมด</a
            ></b-col
          >
        </b-row>
      </template>
      <template v-slot:body>
        <b-row class="mb-3 mt-3">
          <b-col cols="12">
            <b-form inline>
              <label class="mr-sm-2" for="recent-group">กลุ่มตำแหน่ง</label>
              <b-form-select
                id="recent-group"
                class="mb-2 ml-lg-2 mr-sm-2 mb-sm-0 fix-length"
                v-model="groupSelected"
                :options="groupOptions"
                @change="setProvinceManyLoginData"
              ></b-form-select>
            </b-form>
          </b-col>
        </b-row>
        <div class="mt-4">
          <b-table
            table-class="tbNumber text-left"
            :items="items"
            :fields="fields"
            small
            sort-icon-left
            responsive
            :busy="isLoading"
            hover
          >
            <template v-slot:cell(memberName)="data"
              ><span>{{ data.item.memberName | cut }}</span></template
            >
            <template v-slot:cell(groupName)="data">
              <span>{{ data.item.groupName ? data.item.groupName : '-' }}</span>
            </template>
            <template v-slot:cell(loginAmount)="data">
              <span>{{ data.item.loginAmount }}</span>
            </template>
            <template v-slot:cell(latestLoginProvince)="data">
              <span>{{
                data.item.latestLoginProvince
                  ? data.item.latestLoginProvince
                  : '-'
              }}</span>
            </template>
            <template v-slot:cell(btn)="data">
              <b-button
                size="sm"
                variant="outline-primary"
                v-b-modal="`modal-${data.item.memberName}`"
              >
                ดูข้อมูล
              </b-button>
              <b-modal
                size="xl"
                :id="`modal-${data.item.memberName}`"
                :title="
                  `ข้อมูลการเข้าเรียนตามพื้นที่ของ ${data.item.memberName}`
                "
              >
                <province-many-login-modal
                  :apiRoute="
                    `/v1/analytics/students/get-student-province-learning-stats/${data.item.memberId}?from=${periodDateFrom}&to=${periodDateTo}`
                  "
                ></province-many-login-modal>
              </b-modal>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
          <div style="text-align: center" v-if="items.length == 0">
            ไม่มีข้อมูล
          </div>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
import layout from '@/components/pages/analytics/shared/Layout.vue';
import province from '@/components/pages/analytics/shared/thailandDataMap.js';
import ProvinceManyLoginModal from './ProvinceManyLoginModal.vue';
import api from '@/services/api.js';
import moment from 'moment';

export default {
  components: {
    layout,
    ProvinceManyLoginModal,
  },
  data() {
    return {
      province: province,
      isLoading: true,
      fields: [
        {
          key: "memberName",
          label: "รายชือผู้เรียน",
          thStyle: { width: "135px" },
          tdStyle: { width: "135px" },
        },
        {
          key: "groupName",
          label: "กลุ่มตําแหน่ง",
          thStyle: { width: "103px" },
          tdStyle: { width: "103px" },
        },
        {
          key: "loginAmount",
          label: "จำนวนการ login",
          thStyle: { width: "130px" },
          tdStyle: { width: "130px" },
        },
        {
          key: "latestLoginProvince",
          label: "จังหวัดที่เข้าล่าสุด",
          thStyle: { width: "137px" },
          tdStyle: { width: "137px" },
        },
        {
          key: "btn",
          label: "",
          thStyle: { width: "45px" },
          tdStyle: { width: "45px" },
        },
      ],
      items: [],
      company: {},
      periodDateTo: moment().format('YYYY-MM-DD'),
      periodDateFrom: moment()
        .subtract(1, 'month')
        .format('YYYY-MM-DD'),
      groupSelected: '*',
      groupOptions: [],
    };
  },
  created() {
    this.setCompanyInfo();
    this.setGroupOptions();
    this.setProvinceManyLoginData();
  },
  methods: {
    setCompanyInfo() {
      this.company = { ...this.$store.state.Company.companyInfo };
    },
    async setGroupOptions() {
      try {
        // Fetch the company groups from backend
        let results = await api.get(`/v1/companies/${this.company.id}/groups`);
        this.groupOptions = results.data.data.map((el) => {
          return { value: el.id + '', text: el.name };
        });
        // Add the total option in the first place
        this.groupOptions.unshift({
          value: '*',
          text: 'ทั้งหมด',
        });
      } catch (err) {
        console.log('setGroupOptions error', err);
      }
    },
    fetchData() {
      const queryString = `from=${this.periodDateFrom}&to=${this.periodDateTo}&topten=true&group=${this.groupSelected}`;
      return api.get(
        `/v1/analytics/students/province-learning-stats?${queryString}`
      );
    },
    async setProvinceManyLoginData() {
      this.isLoading = true;
      try {
        let result = await this.fetchData();
        this.items = result.data;
      } catch (err) {
        console.log('setProvinceManyLoginData error!', err);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.fix-length {
  width: 75% !important;
}
.card-layout {
  padding: 20px 20px;
}
</style>
