<template>
  <div>
    <!-- Face Recognition -->
    <h5 class="d-block mt-5" v-if="isAccountingType() || isInsuranceType()">

      <b-form-checkbox
        switch
        class="d-inline"
        @change="hasFaceEvaluationToggle"
        v-model="hasFaceState"
      />
      การยืนยันตัวตนด้วยใบหน้าระหว่างเรียน
    </h5>
  </div>
</template>

<style>
.active-button {
  background: none !important;
  border: none !important;
}
.select-time-face-rec {
  width: 75px;
  margin-left: 65px;
}
.face-rec-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
}
.modal-footer-text {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}
</style>

<script>
import api from "@/services/api.js";
import { mapGetters, mapActions } from "vuex";
import authMixin from "@/mixins/auth-mixin";

export default {
  props: ["cId"],
  mixins: [authMixin],
  data() {
    return {
      gestureState: "",
      hasFaceState: "",
      mustState: "",
      url: "",
      linkStatus: "",
      statusColor: "",
      courseId: this.$route.params.courseId,
      comId: this.$store.state.Company.companyInfo.id,
      mustDoForm: false,
      isJotform: "",
    };
  },

  async mounted() {
    const getFaceRecognition = await api.get(
      `v1/courses/${this.courseId}?temp=1`
    );
    if (getFaceRecognition.data.data.faceRecogntionFlag != 1) {
      this.hasFaceState = false;
    } else {
      this.hasFaceState = true;
    }

    if (getFaceRecognition.data.data.gestureDetectionPeriod >= 1) {
      this.gestureState = true;
    } else {
      this.gestureState = false;
    }
  },



  computed: {
    ...mapGetters("CourseManagement", ["isLoading", "courseInfo"]),
  },

  methods: {
    ...mapActions("CourseManagement", [
      "getCourseInfo",
      "updateAttributeCourseInfo",
    ]),

    // When Toggle FaceRecognition
    async hasFaceEvaluationToggle() {
      let requestFaceRecognition;
      // Toggle On
      if (this.hasFaceState) {
        requestFaceRecognition = {
          courseId: this.courseId,
          attribute: {
            name: "c_faceRecognitionFlag",
            value: true,
          },
        };
        // Toggle Off
      } else {
        requestFaceRecognition = {
          courseId: this.courseId,
          attribute: {
            name: "c_faceRecognitionFlag",
            value: false,
          },
        };
      }
      console.log(requestFaceRecognition)
      // Update Attribute (enable/disable face-recognition)
      await this.updateAttributeCourseInfo(requestFaceRecognition).then(() => {
        this.getCourseInfo({
          courseId: this.courseId,
          temp: 1,
        });
      });
      // Disable Gesture When Disable Face-Recognition
      if (this.gestureState == true) {
        this.gestureState = false;
        let gestureDisable = {
          courseId: this.courseId,
          attribute: {
            name: "c_gestureDetectionPeriod",
            value: false,
          },
        };
        await this.updateAttributeCourseInfo(gestureDisable).then(() => {
          this.getCourseInfo({
            courseId: this.courseId,
            temp: 1,
          });
        });
      }
    },
  },
};
</script>
