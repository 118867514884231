<template>
  <div class>
    <layout>
      <template v-slot:header v-if="perPage == 10">
        <b-row>
          <b-col cols="6">
            <h6>การเข้าทำข้อสอบล่าสุด</h6>
          </b-col>
          <b-col cols="6" class="text-right">
            <a
              :href="`/${company.url}/analytics/exam/${examId}/recent-answersheet?bestScore=${bestScoreSelected}`"
              target="_blank"
              ><h6>ดูทั้งหมด</h6></a
            >
          </b-col>
        </b-row>
      </template>
      <template v-slot:body>
        <div class>
          <div v-if="perPage > 10" class="mb-3">
            <b-row class="justify-content-end">
              <b-col cols="2" class="text-right">
                <b-button @click="exportData()" class="btn btn-download" href=""
                  ><i class="fa fa-angle-double-down" aria-hidden="true"></i>ดาวน์โหลดรายงาน</b-button
                >
              </b-col>
            </b-row>
          </div>
          <b-table
            striped
            id="exam-recent-table"
            :per-page="perPage"
            :current-page="currentPage"
            table-class="tbNumber text-left "
            :items="result"
            :fields="fields"
            small
            sort-icon-left
            responsive
            :busy="isBusy"
            hover
          >
            <template v-slot:cell(member)="data">
              <span>{{
                data.item.metadata && data.item.metadata.memberName
              }}</span>
            </template>

            <template v-slot:cell(score)="data">
              <span>{{
                data.item.overallResult && data.item.overallResult.score
              }}</span>
            </template>

            <template v-slot:cell(date)="data">
              <span>{{ data.item.createdAt | thaiDate }}</span>
            </template>

            <template v-slot:cell(grade)="data">
              <span>{{
                data.item.overallResult && data.item.overallResult.grade
              }}</span>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="exam-recent-table"
            v-if="rows > 0"
          ></b-pagination>
        </div>
      </template>
    </layout>
  </div>
</template>
<script>
import layout from "@/components/pages/analytics/shared/Layout";
import api from "@/services/exam-api.js";
import exp from "@/services/export.js";
import moment from "moment";

export default {
  components: {
    layout,
  },
  props: ["apiRoute", "perPage","bestScoreSelected"],
  data() {
    return {
      currentPage: 1,
      rows: 0,
      result: [],
      isBusy: true,
      fields: [
        { key: "member", label: "ชื่อผู้เรียน" },
        { key: "score", label: "คะแนนที่สอบได้" },
        { key: "date", label: "วันที่ทำข้อสอบ" },
        { key: "grade", label: "เกรดที่ได้" },
      ],
      items: [],
      company: {},
      examId: this.$route.params.examId,
    };
  },

  methods: {
    getRandomInt(max, min) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    async fetch() {
      let res = await api.get(this.apiRoute);
      this.result = res.data;
      this.rows = this.result.length;
      setTimeout(() => {
        this.isBusy = false;
      }, 1 * 1000);
      this.company = this.$store.state.Company.companyInfo;
    },
    thaiDateFilterTimestamp(value) {
      if (value) {
        moment.updateLocale('th', {});
        let year = parseInt(moment(String(value)).format('YYYY'));
        year += 543;
        return moment(String(value)).format(`D MMM ${year}`);
      }
      return "-";
    },
    async exportData() {
      let value = [];
      let set = this.result;
      set.forEach((i) => {
        let temp = {
          title: i.metadata.memberName ? i.metadata.memberName : "-",
          score: i.overallResult.score ? i.overallResult.score : 0,
          create: i.createdAt ? this.thaiDateFilterTimestamp(i.createdAt) : "-",
          grade: i.overallResult.grade ? i.overallResult.grade : "-",
        };
        value.push(temp);
      });
      let key = [
        "ชื่อผู้เรียน",
        "คะแนนที่สอบได้",
        "วันที่ทำข้อสอบ",
        "เกรดที่ได้",
      ];
      let filename = "Individual-Exam-Recent-AnswerSheet-" + this.company.url;
      const data = {
        key: key,
        filename: filename,
        data: value,
      };
      await exp.exportData(data);
    },
  },
  watch: {
    apiRoute() {
      this.fetch();
    },
  },
  async mounted() {
    this.fetch();
  },
};
</script>
<style scoped>
h6 {
  font-family: "Prompt", sans-serif;
}
</style>
