<template>
  <div>
    <b-container class="widthPage">
      <b-row class="mt-5">
        <b-col cols="6">
          <h6 class="mb-3"><strong>ค้นหาผู้เรียน</strong></h6>
          <v-select
            :options="membersData"
            v-model="selectedMember"
            placeholder="พิมพ์ชื่อผู้เรียนที่ต้องการค้นหา..."
            :disabled="hasMembersData"
            :clearable="false"
          />
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col cols="6">
          <h6 class="mb-3"><strong>เลือกคอร์สเรียน</strong></h6>
          <v-select
            :options="coursesData"
            v-model="selectedCourseEnroll"
            placeholder="พิมพ์ชื่อคอร์สที่ต้องการค้นหา..."
            :disabled="hasCoursesData"
            :clearable="false"
          />
        </b-col>
      </b-row>
      <b-button
        @click="searchForLearningHistory()"
        class="mt-4"
        variant="primary"
        :disabled="!selectedCourseEnroll"
      >
        ค้นหาการยืนยันตัวตนขณะเรียน
      </b-button>

      <VerifyMemberLearningReport
        :isLoading="isLoading"
        :faceApprove="faceApprove"
        :cerData="cerData"
        :isCardShow="isCardShow"
        :member="member"
      />
    </b-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import api from "@/services/api.js";
import vSelect from "vue-select";
import VerifyMemberLearningReport from "./VerifyMemberLearningReport.vue";

export default {
  props: { company: Object },
  components: {
    vSelect,
    VerifyMemberLearningReport,
  },
  data() {
    return {
      comId: this.$store.state.Company.companyInfo.id,
      membersData: [],
      member: null,
      selectedMember: null,
      faceApprove: null,
      isLoading: false,
      hasMembersData: true,
      coursesData: [],
      selectedCourseEnroll: null,
      cerData: null,
      hasCoursesData: true,
      isCardShow: false,
    };
  },
  created() {
    this.getMemberList();
  },
  methods: {
    ...mapActions("MemberManagement", ["fetchMemberInfo"]),
    async getMemberList() {
      await api
        .get(`/v1/companies/${this.comId}/enrolled-members`)
        .then(({ data }) => {
          this.membersData = data.map((member) => ({
            label: member.mp_firstName + " " + member.mp_lastName,
            value: member.m_id,
          }));
          this.hasMembersData = false;
        });
    },
    async searchForLearningHistory() {
      this.isLoading = true;
      this.isCardShow = true;
      let cerId = this.selectedCourseEnroll.value.cerId;
      this.cerData = this.selectedCourseEnroll.value;
      await this.fetchMemberInfo(cerId);
      await this.fetchLearningFaceApprove(cerId);
      await this.getMember();
      this.isLoading = false;
    },
    async fetchLearningFaceApprove(cerId) {
      await api
        .get("/v1/learning-face-approve/" + cerId)
        .then(async ({ data }) => {
          this.faceApprove = await this.mapLearningData(data);
        });
    },
    async getMember() {
      await api
        .get(`/v1/members/${this.selectedMember.value}`)
        .then(({ data }) => {
          this.member = data.data;
        })
        .catch(() => {});
    },
    async mapLearningData(data) {
      return data.flatMap(item => 
          item.lec_images.map(image => ({
              lec_name: item.lec_name,
              stat_id: image.stat_id,
              lec_id: image.lec_id,
              face_path: image.face_path,
              learning_time: image.learning_time,
              learning_point: image.learning_point,
              face_verified: image.face_verified
          }))
      );
    }
  },
  watch: {
    selectedMember(newMember) {
      if (newMember !== null) {
        api.get(`v1/members/${newMember.value}/enrolls`).then(({ data }) => {
          this.coursesData = data.map((course) => ({
            label: course.courseName,
            value: course,
          }));
          this.hasCoursesData = false;
        });
      }
    },
    selectedCourseEnroll(newCourse) {
      if (newCourse !== null) {
        this.selectedCourseEnroll = newCourse;
      }
    },
  },
};
</script>

<style scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Prompt", sans-serif;
}

.widthPage {
  max-width: 81.25rem;
}
</style>
