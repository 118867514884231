import Vue from 'vue';
import VueRouter from 'vue-router';
import AuthLayout from '@/components/layouts/Auth.vue';
import AppLayout from '@/components/layouts/App.vue';
import Login from '@/components/pages/LoginPage.vue';
import ResetPassword from '@/components/pages/ResetPasswordPage.vue';
import DownloadAttendanceReport from '@/components/pages/DownloadAttendanceReport.vue';
import DownloadAttendanceAllCoursesReport from '@/components/pages/DownloadAttendanceAllCoursesReport.vue'
import MemberManagementRoutes from './member-management';
import CourseManagementRoutes from './course-management';
import CourseEnrollmentRoutes from './course-enrollment';
import AnalyticRoutes from './analytics';
import ReportRoutes from './report';
import ExamAnalyticRoutes from './exam-analytics';
import LiveManagementRoutes from './live-management';
import FaceVerificationRoutes from './face-verification';
import AffiliateRoutes from './affiliate';
import etcRoutes from './etc';
import TagRoutes from './tag-management';
import ExportDataRoutes from './export-data';
import Error from './errors';
import ExamBuild from './exam-build';
import Purchase from './purchase'
import Attendance from './attendance'
import ApproveCourseComplete from './approve-course-complete';


Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: '',
    redirect: {
      name: 'login',
      path: '/login',
    },
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: Login,
      },
    ],
  },
  {
    path: '/',
    name: '',
    redirect: {
      name: 'reset-password',
      path: '/reset-password',
    },
    component: AuthLayout,
    children: [
      {
        name: 'reset-password',
        path: 'reset-password',
        component: ResetPassword,
      },
    ],
  },
  {
    path: '/',
    name: '',
    redirect: {
      name: 'download-attendance-report',
      path: '/download-attendance-report',
    },
    component: AuthLayout,
    children: [
      {
        name: 'download-attendance-report',
        path: 'download-attendance-report/:comId/course/:cId/:accessToken',
        component: DownloadAttendanceReport,
      },
      {
        name: 'download-attendance-report',
        path: 'download-attendance-report/:comId/all-courses/:accessToken',
        component: DownloadAttendanceAllCoursesReport,
      },
    ],
  },
  {
    path: '/:company',
    component: AppLayout,
    children: [
      {
        name: 'dashboard',
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/components/pages/course-management/CourseListPage'
          ),
      },
      ...MemberManagementRoutes,
      ...CourseManagementRoutes,
      ...CourseEnrollmentRoutes,
      ...AnalyticRoutes,
      ...ReportRoutes,
      ...ExamAnalyticRoutes,
      ...LiveManagementRoutes,
      ...FaceVerificationRoutes,
      ...AffiliateRoutes,
      ...etcRoutes,
      ...TagRoutes,
      ...ExportDataRoutes,
      ...ExamBuild,
      ...Purchase,
      ...Attendance,
      ...ApproveCourseComplete,
      ...Error,
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
