<template>
  <div>
    <PageHeader
      :title="`ประวัติการเข้าเรียนของ ${memberName}`"
      style="margin-left: -10%;"
    ></PageHeader>
    <b-container class="widthPage py-5">
      <div class="card card-csq no-shadow" style="min-height: 450px;">
        <div v-if="courseListDisplay.length > 0" class="card-body pt-4">
          <b-row> 
            <b-col cols="12" class="text-right mb-3">
              <b-dropdown
                :disabled="generatingPDF"
                size="sm"
                variant="download"
                no-caret
                class="text-center"
                menu-class="w-100"
                right
              >
                <template #button-content>
                    <b-spinner v-if="generatingPDF" class="align-middle mr-2" small></b-spinner>
                    <i class="fa fa-angle-double-down" aria-hidden="true"></i>
                    ดาวน์โหลดรายงาน
                </template>
                <b-dropdown-item @click="downloadLearningHistoryReport(true)">ดาวน์โหลดรายงานการจบหลักสูตร</b-dropdown-item>
                <b-dropdown-item @click="downloadLearningHistoryReport()">ดาวน์โหลดรายงานทั้งหมด</b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row>
          <b-row class="py-2 mx-1" style="border-bottom: 2px solid #d8d8d8">
            <b-col style="flex: 1;"><strong>คอร์สเรียน</strong></b-col>
            <b-col style="max-width: 14%;" v-if="comId = 515"><strong>รหัสหลักสูตร</strong></b-col>
            <b-col style="max-width: 14%;"><strong>วันที่ลงทะเบียน</strong></b-col>
            <b-col style="max-width: 14%;"><strong>วันที่อบรม</strong></b-col>
            <b-col style="max-width: 14%;"><strong>ความยาวคอร์ส</strong></b-col>
            <b-col style="max-width: 14%;"><strong>เข้าเรียนแล้ว</strong></b-col>
          </b-row>
          <b-row
            class="text-font table-body py-2 mx-1"
            v-for="(course, courseIndex) in listCourseLearningHistory"
            :key="courseIndex"
            style="border-bottom: 1px solid #d8d8d8"
          >
            <b-col style="flex: 1;">
              <router-link
                :to="{
                  name: 'report.learning-history-report.enrolls',
                  params: { cerId: course.cerId },
                }"
                class="text-csq"
              >
                {{ course.courseName }}
              </router-link>
            </b-col>
            <b-col style="max-width: 14%;">
              <p>{{ course.courseCode }}</p>
            </b-col>
            <b-col style="max-width: 14%;">
              <p>{{ course.cerCompanyAssignDate | thaiDateFilter }}</p>
            </b-col>
            <b-col style="max-width: 14%;">
              <p>{{ course.cerStartDate | thaiDateFilter }}</p>
              <p v-if="course.cerCompleteDate"> - {{ course.cerCompleteDate | thaiDateFilter }}</p>
            </b-col>
            <b-col style="max-width: 14%;">
              <p>{{ course.courseDuration | duration | thaiDurationHourMinuteFilter }}</p>
            </b-col>
            <b-col style="max-width: 14%;">
              <p>{{ course.learningTime | duration | thaiDurationHourMinuteFilter }}</p>
            </b-col>
          </b-row>
        </div>

        <b-row v-if="courseListDisplay.length > 0" class="text-font mt-4 mx-1">
          <b-col cols="6">
            <div class="d-flex justify-content-start align-items-center">
              <p class="pr-2">แสดง</p>
              <b-form-select v-model="rowPerPage" style="width: 75px;">
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </b-form-select>
              <p class="pl-2">จาก {{ rows }} รายการ</p>
            </div>
          </b-col>
          <b-col cols="6">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="rowPerPage"
              aria-controls="course-learning-history"
              align="right"
              last-number
            ></b-pagination>
          </b-col>
        </b-row>

        <template v-else>
          <div class="text-center mt-5">
            <p>ไม่มีประวัติการเข้าเรียน</p>
          </div>
        </template>
      </div>
    </b-container>
  </div>
</template>

<script>
import PageHeader from "@/components/layouts/PageHeader.vue";
import api from "@/services/api.js";
import dateMixins from "@/components/pages/analytics/shared/mixins/date.js";
import timeMixins from "@/components/pages/analytics/shared/mixins/time.js";
import memberLearningHistoryReportMixin from "@/mixins/member-learning-history-report-mixin.js";
import generateAttendanceReportPdfMixin from "@/mixins/generate-attendance-report-pdf-mixin.js";

export default {
  components: {
    PageHeader,
  },
  mixins: [dateMixins, timeMixins, memberLearningHistoryReportMixin, generateAttendanceReportPdfMixin],
  data() {
    return {
      mId: this.$route.params.mId,
      comId: localStorage.getItem("ComId"),
      memberName: "",
      memberGender: null,
      memberData: null,
      companyData: null,
      courseListDisplay: [],
      courseList: [],
      rowPerPage: 5,
      currentPage: 1,
      generatingPDF: false,
      thaiMonths: [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม"
      ],
      pdfAllCourseOption: {
        orientation: "L",
        format: "a4",
        unit: "px",
        lineHeight: 2,
        putOnlyUsedFonts: true
      },
      pdfAllCourseConfig: {
        typo: {
          header: 14,
          large: 16,
          normal: 9,
          small: 7,
          chartHeader: 18
        },
        margin: {
          t: 72,
          b: 40,
          l: 40,
          r: 40,
          tChart: 10,
          tHeaderChart: 24,
          bHeaderTable: 5
        },
        endNormal: 11,
        endRow: 18,
        endTable: 16,
        endRowHeader: 27,
        endLegend: 32
      }
    };
  },
  created() {
    this.fetchMemberInfo();
    this.fetchCourseEnroll();
  },
  computed: {
    rows() {
      return this.courseListDisplay.length;
    },
    listCourseLearningHistory() {
      var firstIndex;
      if (this.currentPage == 1) {
        firstIndex = 0;
      } else {
        firstIndex = (this.currentPage - 1) * this.rowPerPage;
      }

      var showData = this.courseListDisplay.slice(
        firstIndex,
        parseInt(firstIndex) + parseInt(this.rowPerPage)
      );
      return showData;
    },
  },
  methods: {
    async fetchMemberInfo() {
      if (this.mId) {
        await api.get(`/v1/members/${this.mId}`).then(({ data }) => {
          this.memberName = data.data.firstName + " " + data.data.lastName;
          this.memberGender = this.getGenderDisplay(data.data.gender)
          this.memberData = data.data;
          this.companyData = this.getCompanyById(data.data.profiles);
        });
      }
    },
    async fetchCourseCompletedEnroll() {
      try {
        await api.get(`v1/members/${this.mId}/completed-enrolls`).then(({ data }) => {
          this.courseList = data;
        });
      } catch (error) {
        console.log(error);
      }
    },
    async fetchCourseEnroll() {
      try {
        await api.get(`v1/members/${this.mId}/enrolls`).then(({ data }) => {
          this.courseListDisplay = data
          this.courseList = data;
        });
      } catch (error) {
        console.log(error);
      }
    },
    async downloadLearningHistoryReport(onlyCompleted = false) {
      this.generatingPDF = true;
      if (onlyCompleted) {
        await this.fetchCourseCompletedEnroll();
      } else {
        await this.fetchCourseEnroll();
      }
      setTimeout(() => {
        this.generateLearningHistoryReport();
        }, 1000);
    },
    getGenderDisplay(gender) {
      if (gender){
        switch (gender.toLowerCase()) {
          case 'm':
            return 'ชาย';
          case 'f':
            return 'หญิง';
          default:
            return null;
        }
      } else {
        return null
      }
    },
    getCompanyById(profiles) {
      const profile = profiles.find(profile => profile.company.id == this.comId);
      return profile ? profile.company : null;
    }
  },
};
</script>

<style scoped>
.widthPage {
  max-width: 81.25rem;
}
.table-header {
  font-family: "Prompt", sans-serif;
  border-bottom: 2px solid #d8d8d8
}
.table-body .text-csq, .table-body p {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
</style>
