import Vue from "vue";

export default {
  SET_EXAMS(state, data) {
    state.exams = data;
  },
  UPDATE_EXAMS(state, data) {
    state.exams.push(data);
  },
  DELETE_EXAMS(state, data) {
    state.exams = state.exams.filter((exam) => exam.quizExamId != data);
  },

  FIND_EXAMS_BY_ID(state, data) {
    let section = data.sections.map((item) => ({
      ...item,
      isCollapse: true,
    }));

    for (let i = 0; i < data.sections.length; i++) {
      for (let j = 0; j < data.sections[i].questions.length; j++) {
        if (data.sections[i].questions[j].type === "MULTIPLE_CHOICE") {
          data.sections[i].questions[j]._choices.sort(function(a, b) {
            return parseFloat(a.index) - parseFloat(b.index);
          });
        } else {
          continue;
        }
      }
    }
    let globalCriteria = data._globalCriteria.sort(function(a, b) {
      return a.grade.localeCompare(b.grade);
    });

    globalCriteria = globalCriteria.map((item, index) => {
      return {
        ...item,
        isCollapse: data._globalCriteria.length - 1 === index ? true : false,
        isError: false,
      };
    });

    // assign hasAnswer variable to every question
    data.sections.forEach((section) => {
      section.questions.forEach((question) => {

        if(question.hasAnswer){
          Vue.set(state.hasAnswers, question.id, true);
        } else {
          Vue.set(state.hasAnswers, question.id, false);
        }
        // including PASSAGE and BUNDLE that consists of subquestions
        if(question.type === "PASSAGE" || question.type === "BUNDLE") {
          question.subQuestions.forEach((subQuestion) => {
            if(subQuestion.hasAnswer){
              Vue.set(state.hasAnswers, subQuestion.id, true);
            } else {
              Vue.set(state.hasAnswers, subQuestion.id, false);
            }
          })
        }
      })
    })

    data.sections = section;
    data._globalCriteria = globalCriteria;
    state.exam = data;
  },

  SET_CREATE_EXAM(state, data) {
    state.exams.push(data);
  },

  SET_SECTION(state, data) {
    state.exam.sections = data;
  },

  ADD_NEW_SECTION(state, data) {
    state.tempSection = data[0];
  },

  UPDATE_SECTION_INFO(state, data) {
    const index = state.exam.sections.findIndex(
      (section) => section.id == data.id
    );
    state.exam.sections[index].title = data.title;
  },

  TOGGLE_COLLAPSE(state, _globalCriteria) {
    _globalCriteria.isCollapse = !_globalCriteria.isCollapse;
  },

  ADD_NEW_QUESTION(state, payload) {
    state.tempQuestion = payload.data;
  },

  CREATE_NEW_SUB_QUESTION(state, payload) {
    state.tempQuestion = payload.data;
  },

  DELETE_QUESTIONS(state, payload) {
    state.exam.sections[payload.sectionIndex].questions = state.exam.sections[
      payload.sectionIndex
    ].questions.filter((question) => question.id != payload.questionId);
  },

  DELETE_SUB_QUESTIONS(state, payload) {
    state.exam.sections[payload.sectionIndex].questions[payload.questionIndex].subQuestions = state.exam.sections[
      payload.sectionIndex
    ].questions[payload.questionIndex].subQuestions.filter((subQuestion) => subQuestion.id !== payload.subQuestionId);
  },

  CREATE_CHOICE_FOR_MULTI_QUESTION(state, payload) {
    state.tempChoice = payload.data;
  },

  CREATE_CHOICE_FOR_SUB_QUESTION(state, payload) {
    state.tempChoice = payload.data.data;
  },

  SET_QUESTION_ANSWER(state, data) {
    state.answer = data;
  },

  SET_QUESTION_ALL_ANSWER(state, data) {
    state.allAnswer = data;
  },

  ADD_NEW_GRADING_CRITERIA(state, data) {
    data.isCollapse = false;

    state.exam._globalCriteria.push(data);
  },

  DELETE_GRADING_CRITERIA(state, gradeIndex) {
    state.exam._globalCriteria = state.exam._globalCriteria.filter(
      (grade) => grade.index != gradeIndex
    );
  },

  UPDATE_GRADING_CRITERIA(state, payload) {
    if (payload.checkUpdateOfDelete) {
      for (let i in state.exam._globalCriteria) {
        if (state.exam._globalCriteria[i].index === payload.data.data.index) {
          payload.data.data.isCollapse = false;
          payload.data.data.isError = false;
          state.exam._globalCriteria[i] = payload.data.data;
        }
      }
    }
  },

  UPDATE_CHOICES_FOR_MULTI_QUESTION(state, payload) {
    const { sectionIndex, questionIndex, choiceIndex, data } = payload;
    state.exam.sections[sectionIndex].questions[questionIndex]._choices[
      choiceIndex
    ].text = data.data.text;
  },

  UPDATE_OPTIONS(state, payload) {
    state.exam._options.isRandomChoice = payload.isRandomChoice;
    state.exam._options.isSkippable = payload.isSkippable;
    state.exam._options.isRandomChoice = payload.isRandomChoice;
    state.exam._options.isAllAnswersRequired = payload.isAllAnswersRequired;
    state.exam._options.hasResultSectionTable = payload.hasResultSectionTable;
    state.exam._options.hasResultQuestionTable = payload.hasResultQuestionTable;
    state.exam._options.examAccessLimit = payload.examAccessLimit;
    state.exam._options.solution = state.exam._options.solution || {};
    state.exam._options.solution.hasSolutionAfterSubmission = payload.solution?.hasSolutionAfterSubmission ?? false;
    state.exam._options.solution.hasSolutionAfterEachQuestion = payload.solution?.hasSolutionAfterEachQuestion ?? false;
  },

  UPDATE_OPTIONS_TIME_LIMIT(state, payload) {
    const { data } = payload;

    if (!data.timeLimit) {
      state.exam._options.timeLimit = {};
    } else {
      state.exam._options.timeLimit.timeLimitType =
        data.timeLimit.timeLimitType;
      state.exam._options.timeLimit.timeLimitSecond =
        data.timeLimit.timeLimitSecond;
    }
  },

  SECTION_OPTIONS_SETTING(state, payload) {
    state.exam.sections[payload.sectionIndex].options = {};

    let section = state.exam.sections.map((item) => ({
      ...item,
      isCollapse: true,
    }));

    state.exam.sections = section;
  },

  ADD_COLLAPSED_QUESTION(state, payload) {
    Vue.set(state.collapsedQuestions, payload, true);
  },

  TOGGLE_COLLAPSED_QUESTION(state, payload) {
    Vue.set(state.collapsedQuestions, payload, !state.collapsedQuestions[payload]);
  },

  SET_HAS_ANSWER(state, questionId){
    Vue.set(state.hasAnswers, questionId, true);
  },

  SET_NO_ANSWER(state, questionId){
    Vue.set(state.hasAnswers, questionId, false);
  },

  SET_SOLUTION(state, {questionId, solution}){
    Vue.set(state.solutions, questionId, solution)
  },

  SET_NO_SOLUTION(state, questionId){
    Vue.set(state.solutions, questionId, "")
  },

  UPDATE_SECTION_AND_QUESTION_COUNT(state, data) {
    state.questionCount = data.total_question;
    state.sectionCount = data.total_section;
  },


  INIT() {},
};
