import api from "../../../services/api";

export default {
  namespaced: true,
  state: {
    isLoading: false,
    courseCompletionList: [],
    courseCompletionInfo: [],
    courseCompletionMemberList: null,
    approveEnrollLog: [],
  },
  mutations: {
    SET_LOADING_STATUS(state, status) {
      state.isLoading = status === null ? !state.isLoading : status;
    },
    SET_COURSE_COMPLETION_LIST(state, data) {
      state.courseCompletionList = data;
    },
    SET_COURSE_COMPLETION_INFO(state, data) {
      state.courseCompletionInfo = data;
    },
    SET_COURSE_COMPLETION_MEMBER_LIST(state, data) {
      state.courseCompletionMemberList = data;
    },
    SET_APPROVE_ENROLL_LOG(state, data) {
      state.approveEnrollLog = data;
    },
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    courseCompletionList(state) {
      return state.courseCompletionList;
    },
    courseCompletionInfo(state) {
      return state.courseCompletionInfo;
    },
    courseCompletionMemberList(state) {
      return state.courseCompletionMemberList;
    },
    approveEnrollLog(state) {
      return state.approveEnrollLog;
    },
  },
  actions: {
    async fetchCourseCompletionList(context, params) {
      context.commit("SET_LOADING_STATUS", true);
      return await api
        .get(`/v1/courses-list/enroll-approve`, params)
        .then(({ data }) => {
          context.commit("SET_LOADING_STATUS", false);
          context.commit("SET_COURSE_COMPLETION_LIST", data.data);
        })
        .catch(({ errors }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.log("vuex:", errors);
        });
    },
    async fetchCourseCompletionInfo(context, courseId) {
      return await api
        .get(`/v1/courses/${courseId}/enroll-approve`)
        .then(({ data }) => {
          context.commit("SET_COURSE_COMPLETION_INFO", data.data[0]);
        })
        .catch(({ errors }) => {
          console.log("vuex:", errors);
        });
    },
    async fetchCourseCompletionMemberList(context, courseId) {
      context.commit("SET_LOADING_STATUS", true);
      return await api
        .get(`/v1/members/courses/${courseId}/enroll-approve`)
        .then(({ data }) => {
          context.commit("SET_LOADING_STATUS", false);
          context.commit("SET_COURSE_COMPLETION_MEMBER_LIST", data);
        })
        .catch(({ errors }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.log("vuex:", errors);
        });
    },
    async fetchApproveEnrollLog(context, courseId) {
      context.commit("SET_LOADING_STATUS", true);
      return await api
        .get(`/v1/courses/${courseId}/enroll-approve-log`)
        .then(({ data }) => {
          context.commit("SET_LOADING_STATUS", false);
          context.commit("SET_APPROVE_ENROLL_LOG", data);
        })
        .catch(({ errors }) => {
          context.commit("SET_LOADING_STATUS", false);
          console.log("vuex:", errors);
        });
    },
    async updateCourseEnrollApprove(context, params) {
      await api.patch(`v1/course-enroll/approve`, params);
    },
  },
};
