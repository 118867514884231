<template>
  <div>
    <div
      v-show="sidebarOpened"
      class="b-sidebar hidden-sidebar overflow-scroll"
      no-header
      shadow
    >
      <div class="px-3 py-2">
        <div class="d-flex brand">
          <img src="@/assets/images/cs_logo_light.png" class="img-responsive" />
          <b-icon icon="list" class="navigator" @click="openSidebar"></b-icon>
        </div>
        <div class="company">
          <b-button v-b-toggle.collapse-1 class="main-btn-company">
            <b-icon icon="building" aria-hidden="true"></b-icon>
            {{ profile.company.displayName }}
            <b-icon
              icon="chevron-expand"
              aria-hidden="true"
              style="float: right"
            ></b-icon>
          </b-button>
          <b-collapse id="collapse-1" class="mt-2">
            <div class="scrollable-menu">
              <a
                v-for="(item, index) in userWorkAtCompanies"
                :key="index"
                :href="`/${item.company.url}`"
                class="dropdown-item border-bottom"
              >
                <b-media>
                  <strong class="m-0 p-0 d-block">{{
                    item.company.displayName
                  }}</strong>
                  <span class="small">{{ item.fullName }}</span>
                </b-media>
              </a>
            </div>
          </b-collapse>
        </div>
        <hr />
        <nav class="mb-3">
          <ul id="menu">
            <li class="" v-show="false">
              <router-link
                :to="{
                  name: 'course.management.index',
                  params: { company: companyUrl },
                }"
              >
                <i class="fa fa-home" aria-hidden="true"></i>
                <span class="link-title menu_hide">แดชบอร์ด</span>
              </router-link>
            </li>
            <CourseManagementList
              v-if="
                hasRoles([
                  'org/admin',
                  'org/instructor',
                  'org/hr',
                  'org/group_admin',
                  'tutor/admin',
                  'tutor/instructor',
                  'tutor/content_creator',
                ])
              "
            />
            <MemberManagementList
              v-if="
                hasRoles([
                  'org/admin',
                  'org/hr',
                  'org/group_admin',
                  'org/audit',
                  'tutor/admin',
                  'tutor/instructor',
                  'org/instructor'
                ])
              "
            />
            <MainExamList
              v-if="
                hasRoles([
                  'org/admin',
                  'org/hr',
                  'org/instructor',
                  'org/group_admin',
                  'tutor/admin',
                  'tutor/instructor',
                ])
              "
            />
            <CourseCompleteManagementList
              v-if="
                hasRoles([
                  'org/admin',
                  'org/instructor',
                  'tutor/admin',
                  'tutor/instructor',
                ]) && isInsuranceType
              "
            />
            <AttendanceManagementList
              v-if="
                hasRoles([
                  'org/admin',
                  'org/instructor',
                  'tutor/admin',
                  'tutor/instructor',
                ]) && comId == 512
              "
            />
            <PurchaseList
              v-if="
                hasRoles(['tutor/admin', 'tutor/instructor']) &&
                  (isAccountingType() || isInsuranceType() || isTutorType())
              "
            />
            <analytics-tab
              v-if="
                hasRoles([
                  'org/admin',
                  'org/instructor',
                  'org/hr',
                  'org/audit',
                  'org/group_admin',
                  'tutor/admin',
                  'tutor/instructor',
                  'tutor/content_creator',
                ]) && !canSpecificUserAccess
              "
            ></analytics-tab>
            <ReportTab
              v-if="
                hasRoles([
                  'org/instructor',
                  'org/hr',
                  'org/audit',
                  'org/group_admin',
                  'org/admin',
                  'tutor/admin',
                  'tutor/instructor',
                  'tutor/content_creator',
                ]) &&
                  (comId == 485 || comId == 519 || comId == 513)
              "
            ></ReportTab>
            <DiscussionList
              v-if="
                hasRoles([
                  'org/admin',
                  'org/instructor',
                  'org/hr',
                  'tutor/admin',
                  'tutor/instructor',
                ]) && !isInsuranceType()
              "
            ></DiscussionList>
            <LiveManagementList
              v-if="
                isMenuShow('live') &&
                (
                  ( 
                    hasRoles(['org/admin', 'tutor/admin', 'tutor/content_creator']) &&
                    [112, 180, 277, 505, 516].includes(comId) 
                  ) || 
                  comId == 512
                )
              "
            />
            <affiliate-tab
              v-if="
                isMenuShow('affiliate') &&
                  hasRoles([
                    'tutor/admin',
                    'tutor/instructor',
                  ]) &&
                  isTutorType() && !canSpecificUserAccess
              "
            ></affiliate-tab>
            <ETCList
              v-if="
                isMenuShow('etc') &&
                  hasRoles(['org/admin', 'tutor/admin']) &&
                  (!isInsuranceType() || comId == 503) && !canSpecificUserAccess
              "
            />
          </ul>
        </nav>
        <hr />
        <div>
          <!-- Using modifiers -->
          <a v-b-toggle.collapse-2 class="m-1 b-link"
            ><b-icon-person /> {{ profile.fullName }}</a
          >
          <!-- Element to collapse -->
          <b-collapse id="collapse-2">
            <b-dropdown-item href="#" @click="logout" class="logout">
              <b-icon-box-arrow-left /> ออกจากระบบ
            </b-dropdown-item>
          </b-collapse>
        </div>
      </div>
    </div>

    <div class="inactive-sidebar">
      <ul>
        <li class="non-effect">
          <b-icon icon="list" class="navigator-d" @click="openSidebar"></b-icon>
        </li>
        <HoverMenu :profile="profile" :company="company" />
      </ul>
    </div>
  </div>
</template>

<script>
import CourseManagementList from "@/components/layouts/sidebars/courses/CourseManagementList.vue";
import MemberManagementList from "@/components/layouts/sidebars/members/MemberManagementList.vue";
import LiveManagementList from "@/components/layouts/sidebars/lives/LiveManagementList.vue";
import MainExamList from "@/components/layouts/sidebars/exams/MainExamList.vue";
import authMixin from "../../mixins/auth-mixin.js";
import AnalyticsTab from "@/components/layouts/sidebars/analytics/AnalyticsTab.vue";
import ReportTab from "@/components/layouts/sidebars/analytics/ReportTab.vue";
import DiscussionList from "@/components/layouts/sidebars/discussion/DiscussionList.vue";
import AffiliateTab from "@/components/layouts/sidebars/affiliate/AffiliateTab.vue";
import ETCList from "@/components/layouts/sidebars/etc/etcList.vue";
import PurchaseList from "@/components/layouts/sidebars/purchase/PurchaseList.vue";
import AttendanceManagementList from "./sidebars/attendance/AttendanceManagementList.vue";
import CourseCompleteManagementList from "./sidebars/approve-course-complete/CourseCompleteManagementList.vue";
import CompanySideBars from "./sidebars/CompanySidebarSwitch";
import HoverMenu from "@/components/layouts/HoverMenu.vue";
import { mapGetters } from "vuex";

export default {
  mixins: [authMixin],
  components: {
    MainExamList,
    CourseManagementList,
    MemberManagementList,
    AnalyticsTab,
    ReportTab,
    LiveManagementList,
    DiscussionList,
    AffiliateTab,
    ETCList,
    PurchaseList,
    AttendanceManagementList,
    CourseCompleteManagementList,
    HoverMenu,
  },
  props: { sidebarOpened: Boolean, company: Object, profile: Object },
  data() {
    return {
      companyUrl: "",
      comId: this.$store.state.Company.companyInfo.id,
      memberId: JSON.parse(localStorage.getItem("user")).id,
    };
  },
  computed: {
    ...mapGetters("Authentication", ["authUser"]),
    userWorkAtCompanies() {
      const roles = this.authUser.roles;
      const profiles = this.authUser.profiles;

      return profiles.filter((profile) => {
        return roles.find((role) => {
          return role.companyId == profile.company.id;
        });
      });
    },
  },
  created() {
    this.companyUrl = this.$route.params.company;
  },
  mounted() {},
  methods: {
    ...CompanySideBars,
    openSidebar() {
      this.$emit("sidebarOpened", !this.sidebarOpened);
    },
    getWidth() {
      return Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
      );
    },
    async logout() {
      await this.$store
        .dispatch("Authentication/logout")
        .then(() => {
          window.location.href = "/login";
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style lang="scss">
.img-responsive {
  width: 85%;
  padding-right: 20px;
}
.brand {
  align-items: center;
}
.navigator {
  font-size: 30px;
  float: right;
  color: #bbbbbb;
  transition-duration: 0.5s;
  transition-property: transform;
}
.navigator:hover {
  cursor: pointer;
  color: #495464;
  transform: rotate(90deg);
  -webkit-transform: 0.2s rotate(90deg);
}
$csq-primary: #f99f4e;

.hidden-sidebar {
  z-index: 500;
  background-color: #f6f6f6;
  overflow-y: auto;
}
#menu ul {
  font-family: "Prompt", sans-serif;
}
.link-title {
  font-family: "Prompt", sans-serif;
}
#menu > li.active {
  background-color: #e8e8e8;
}
.dropdown_menu > ul > li.active a {
  color: #343434 !important;
  background: #fff;
  padding: 10px 8px 10px 30px;
}
#menu li:not(.active) a:hover {
  background-color: #e8e8e8;
  color: #495464;
  padding: 15px 8px 15px 15px;
  border-bottom: none;
  border-left: 0px;
  box-shadow: 0 0 0 #ddd;
  cursor: pointer;
}
#menu li ul li:not(.active) a:hover {
  color: #f4f4f2;
  background-color: #bbbfca;
  border-left: 0;
  padding: 10px 8px 10px 26px;
  box-shadow: 0 0 0 #fff;
  cursor: pointer;
}

#menu li.active a {
  color: #f4f4f2;
  background: #bbbfca;
  padding: 10px 8px 10px 30px;
  border-radius: 3px;
  margin: 10px;
}

#menu li a:hover {
  background: #fff;
  padding: 10px 8px 10px 30px;
  border-radius: 3px;
  margin: 10px 0px;
  box-shadow: 0 0 0px #ccc;
  cursor: pointer;
}

#menu > li.dropdown_menu ul > li.active > a:hover {
  color: #f4f4f2;
  background: #bbbfca;
  padding: 10px 8px 10px 26px;
  cursor: pointer;
}

#left {
  left: 0px !important;
  margin-top: 0px;
  padding-top: 10px;
  height: calc(100vh - 52px);
  overflow: auto;
}

#menu li.active ul li.active > a {
  color: #fff;
  background-color: #fff;
}

#menu .fa.arrow {
  float: right;
  padding-top: 3px;
  margin-right: 15px;
  font-size: 20px;
}

#menu > li.dropdown_menu > a.collapsed > .fa.arrow:before {
  content: " " !important;
}

#menu > li.dropdown_menu > a > .fa.arrow:before {
  content: " " !important;
}
#menu > li.dropdown_menu > a:not(.collapsed) {
  color: #495464;
}
#menu > li.dropdown_menu > a:not(.collapsed) {
  color: #495464 !important;
  background-color: #e8e8e8;
  border-radius: 3px;
  margin: 0px;
  box-shadow: 0 0 0 #fff;
}
#menu > a.dropdown_menu:not(.collapsed):hover {
  color: #fff;
  background-color: $csq-primary;
  cursor: pointer;
}
#menu ul {
  background-color: #e8e8e8;
  padding: 10px;
}
#menu li a {
  margin: 10px;
}
.company {
  margin-top: 20px;
}
.main-btn-company {
  width: 100%;
  color: #495464 !important;
  border: 1px solid #495464 !important;
  background-color: #f4f4f2 !important;
  border-radius: 3px !important;
  box-shadow: 0 3px 6px #cacaca !important;
  text-align: left !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.main-btn-company:hover {
  color: #bbbfca !important;
  cursor: pointer;
}
.dropdown-item strong {
  color: #495464;
}
.dropdown-item span {
  color: #828992;
}
.logout {
  list-style: none;
  padding: 10px 5px;
}

.inactive-sidebar .navigator-d {
  margin-left: 0;
  font-size: 30px;
  transition-duration: 0.5s;
  transition-property: transform;
}
.inactive-sidebar .navigator-d:hover {
  color: #495464;
  transform: rotate(90deg);
  -webkit-transform: 0.2s rotate(90deg);
  cursor: pointer;
}
.inactive-sidebar {
  position: fixed;
  background-color: #f4f4f2;
  z-index: 100;
  padding: 10px;
  font-size: 18px;
  height: 100%;
  color: #495464;
  top: 0;
}
.inactive-sidebar ul {
  list-style: none;
  padding-left: 0;
  text-align: center;
}
.inactive-sidebar li {
  margin: 10px 0px;
  padding: 10px 15px;
}
.inactive-sidebar li:hover {
  cursor: pointer;
  background-color: #e8e8e8;
  /* padding: 10px 15px; */
  border-radius: 5px;
}
.inactive-sidebar li:active {
  background-color: #e8e8e8;
  border-radius: 5px;
}
.non-effect:hover {
  background-color: transparent !important;
}
.b-link:hover {
  cursor: pointer;
}
</style>
