<template>
  <div>
    <b-modal
      v-model="isOverlayVisible"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      centered
    >
      <div class="text-center">
        <div
          class="p-5 rounded d-flex align-items-center justify-content-center"
        >
          <div class="align-items-center">
            <b-spinner
              variant="primary"
              label="Spinning"
              style="width: 3rem; height: 3rem;"
            ></b-spinner>
            <div class="ml-3">
              <h2 class="mt-2">กรุณารอสักครู่</h2>
              <div class="mt-2">กำลังโหลดข้อมูลเกณฑ์การจบคอร์ส</div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <PageHeader title="จัดการเกณฑ์การจบคอร์ส"></PageHeader>
    <div class="outer">
      <div class="inner bg-container pb-5">
        <CourseNavSteps />
        <Loading
          v-if="isLoading"
          class="mt-2 fixed-top"
          style="z-index: 999999"
        />
        <b-card title="เกณฑ์การจบคอร์ส" class="mt-5 no-shadow border-0">
          <h5>กำหนดเกณฑ์ของการเรียนจบคอร์ส</h5>
          <b-card-text class="pl-4">
            <label>ผู้เรียนถือว่าเรียนจบคอร์สเมื่อ</label>
            <ul style="list-style-type: none">
              <li>
                <label class="h5 mt-2">การเข้าเรียน</label>
                <b-row class="mb-3">
                  <b-col sm="3"> เรียนจบ </b-col>
                  <b-col sm="2">
                    <b-form-input
                      id="lecturePercentCount"
                      v-model="courseCompleteRule.lecturePercentCount"
                      type="number"
                      size="sm"
                      min="0"
                      max="100"
                      step="10"
                      :state="validatelecturePercentCount"
                      aria-describedby="input-lecturePercentCount-live-feedback"
                      @change="update"
                      @input="removeLeadingZeroLecturePercentCount"
                    />
                    <b-form-invalid-feedback
                      id="input-lecturePercentCount-live-feedback"
                    >
                      กรุณาระบุ 0-100
                    </b-form-invalid-feedback>
                  </b-col>
                  <b-col sm="3"> % ของบทเรียนทั้งหมด </b-col>
                </b-row>
                <b-row class="mb-2">
                  <b-col sm="3">
                    <b-form-checkbox
                      id="useTimeSpentPercent"
                      v-model="useTimeSpentPercent"
                      name="useTimeSpentPercent"
                      :value="true"
                      :unchecked-value="false"
                      :disabled="!courseInfo.courseDuration"
                      switch
                      @change="useTimeSpent"
                    >
                      เข้าเรียนครบ
                    </b-form-checkbox>
                  </b-col>
                  <b-col sm="2" v-if="optionSelected == 'Percent'">
                    <b-form-input
                      id="timeSpentPercent"
                      v-model="timeSpentPercent"
                      :disabled="!useTimeSpentPercent"
                      :state="validateTimeSpentPercent"
                      :type="inputType"
                      aria-describedby="input-timeSpentPercent-live-feedback"
                      size="sm"
                      min="0"
                      :max="maxTimeSpent"
                      @change="update"
                    />
                    <b-form-invalid-feedback
                      id="input-timeSpentPercent-live-feedback"
                    >
                      <span v-if="optionSelected == 'Percent'"
                        >กรุณาระบุ 0-100</span
                      >
                    </b-form-invalid-feedback>
                  </b-col>
                  <b-col sm="2" v-else>
                    <div class="time-input">
                      <input
                        type="number"
                        class="hours"
                        v-model.number="hours"
                        min="0"
                        max="99"
                        @blur="updateTime"
                      />
                      <span class="separator">:</span>
                      <input
                        type="number"
                        class="minutes"
                        v-model.number="minutes"
                        min="0"
                        max="59"
                        @blur="updateTime"
                      />
                    </div>
                  </b-col>
                  <b-col sm="2">
                    <b-form-select
                      v-model="optionSelected"
                      :options="attendClassOptions"
                      :disabled="!useTimeSpentPercent"
                      size="sm"
                      @change="
                        update;
                        timeSpentPercent = 0;
                        hours = '00';
                        minutes = '00';
                      "
                    >
                    </b-form-select>
                    <span
                      class="text-secondary small"
                      v-if="!courseInfo.courseDuration"
                      >*ใช้งานได้เมื่อมีวีดิโอบทเรียน
                    </span>
                  </b-col>
                </b-row>
                <b-row class="mb-3" v-if="optionSelected == 'Hour'">
                  <b-col sm="3"></b-col>
                  <b-col sm="4">
                    <p
                      v-if="isInvalidHourType"
                      style="font-size: 12px; color: red;"
                    >
                      ชั่วโมงเข้าเรียนมากกว่าชั่วโมงเนื้อหาคอร์สทั้งหมด
                    </p>
                    <p style="font-size: 12px; color: #666">
                      <b-icon icon="info-circle-fill"></b-icon>
                      เนื้อหาคอร์สทั้งหมด {{ formatDuration() }} ชั่วโมง
                    </p>
                  </b-col>
                </b-row>
                <b-row
                  class="mb-3"
                  v-if="isAccountingType() || isInsuranceType()"
                >
                  <b-col sm="3">
                    <b-form-checkbox
                      id="useLecturePercentWithApprovedFaceVerification"
                      v-model="useLecturePercentWithApprovedFaceVerification"
                      name="useLecturePercentWithApprovedFaceVerification"
                      :value="true"
                      :unchecked-value="false"
                      switch
                    >
                      ยืนยันใบหน้าผู้เรียนโดยแอดมิน
                    </b-form-checkbox>
                  </b-col>
                  <b-col sm="2">
                    <b-form-input
                      id="lecturePercentWithApprovedFaceVerification"
                      v-model="
                        courseCompleteRule.lecturePercentWithApprovedFaceVerification
                      "
                      :disabled="!useLecturePercentWithApprovedFaceVerification"
                      :state="
                        validateLecturePercentWithApprovedFaceVerification
                      "
                      aria-describedby="input-lecturePercentWithApprovedFaceVerification-live-feedback"
                      type="number"
                      size="sm"
                      min="0"
                      max="100"
                      step="10"
                      @input="update"
                    />
                    <b-form-invalid-feedback
                      id="input-lecturePercentWithApprovedFaceVerification-live-feedback"
                    >
                      กรุณาระบุ 0-100
                    </b-form-invalid-feedback>
                  </b-col>
                  <b-col sm="3"> % ของบทเรียนทั้งหมด </b-col>
                </b-row>
              </li>

              <li>
                <label class="h5 mt-2">การสอบ</label>
                <div class="my-1">
                  <b-form-checkbox
                    v-model="useExamRules"
                    :value="true"
                    :unchecked-value="false"
                    :disabled="courseLectures.length === 0"
                    switch
                    @input="update"
                  >
                    ผ่านการทำข้อสอบ {{ countExamRules }} ชุด
                  </b-form-checkbox>
                </div>

                <b-collapse v-model="useExamRules">
                  <ExamRuleItem
                    v-for="item in courseLectures"
                    :key="item.lectureId"
                    :item="item"
                  />
                </b-collapse>
              </li>

              <li>
                <label class="h5 mt-2">การจบคอร์ส</label>
                <div class="my-1">
                  <b-form-checkbox
                    v-model="courseCompleteApprove"
                    :value="true"
                    :unchecked-value="false"
                    switch
                    @change="update"
                  >
                    อนุมัติการจบคอร์ส
                  </b-form-checkbox>
                </div>
                <label v-if="isCourseCompleteApprove">อนุมัติการจบคอร์สโดย</label>
                <b-collapse v-model="courseCompleteApprove">
                  <!-- Add buttons to select who approves the course completion -->
                  <div class="my-1 d-flex align-items-center">
                      <b-button-group>
                        <b-button 
                          v-for="option in approvalOptions" 
                          :key="option.value"
                          :variant="courseCompleteApprovalBy === option.value ? 'primary' : 'secondary'"
                          @click="selectApprover(option.value)"
                        >
                          {{ option.text }}
                        </b-button>
                      </b-button-group>
                      <div v-if="courseCompleteApprovalBy === 'SYSTEM'" style="margin-left: 20px">
                        <b-form-datepicker v-model="autoApprovalDate" @input="update"/>
                      </div>
                      <div v-if="courseCompleteApprovalBy === 'SYSTEM'" style="margin-left: 10px; color:yellow;">
                        <i class="fa-solid fa-circle-info" v-b-tooltip.hover title="ระบบจะทำการอนุมัติการจบคอร์สโดยอัตโนมัติเมื่อถึงเวลาที่ตั้งค่าไว้" ></i>
                      </div>
                  </div>

                </b-collapse>

                <div class="my-1">
                  <b-form-checkbox
                    v-model="courseCompleteApproveNotify"
                    v-if="isCourseCompleteApprove"
                    :value="true"
                    :unchecked-value="false"
                    switch
                    @change="update"
                  >
                    แจ้งเตือนผู้เรียนผ่านทางอีเมลเมื่อมีการอนุมัติ
                  </b-form-checkbox>
                </div>
              </li>
              <li>
                <label class="h5 mt-2">ประกาศนียบัตร</label>
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="pdfAction"
                  name="checkbox-1"
                  class="mt-2"
                  :value="true"
                  :unchecked-value="false"
                  switch
                  @input="updateActions"
                >
                  ผู้เรียนจะได้รับประกาศนียบัตรเมื่อจบคอร์ส
                </b-form-checkbox>

                <b-form-textarea
                  v-show="false"
                  id="textarea"
                  class="mt-4"
                  :value="courseCompleteActionsText"
                  rows="3"
                  max-rows="5"
                  disabled
                />
              </li>
              <li>
                <LevelRenew4
                  v-if="
                    isCompany([470, 500, 503, 506, 502]) &&
                      isInsuranceCourseLevel4()
                  "
                />
              </li>
            </ul>
          </b-card-text>
        </b-card>
        <CourseNavToolbar
          :sidebar-opened="sidebarOpened"
          :course="courseInfo"
          :company="company"
          :isOverlayVisible="isOverlayVisible"
        />
      </div>
    </div>
  </div>
  <!-- </b-overlay> -->
</template>

<script>
import PageHeader from "../../layouts/PageHeader";
import CourseNavSteps from "./partials/CourseNavSteps";
import CourseNavToolbar from "./partials/CourseNavToolbar";
import Loading from "../../shared/Loading";
import ExamRuleItem from "./ExamRuleItem";
import LevelRenew4 from "./forms/LevelRenew4";
import { mapActions, mapGetters } from "vuex";
import api from "@/services/api.js";
import authMixin from "@/mixins/auth-mixin.js";

export default {
  name: "CourseRulePage",
  components: {
    PageHeader,
    CourseNavSteps,
    CourseNavToolbar,
    ExamRuleItem,
    Loading,
    LevelRenew4,
  },
  props: {
    user: { default: null, type: Object },
    company: { default: null, type: Object },
    sidebarOpened: Boolean,
  },
  mixins: [authMixin],
  data() {
    return {
      isOverlayVisible: false,
      courseId: this.$route.params.courseId,
      comId: this.$store.state.Company.companyInfo.id,
      rules: {},
      actions: {},
      course: {},
      forms: {
        lecturePercentCount: null,
      },
      isUseTimeSpentPercent: false,
      isUseLecturePercentWithApprovedFaceVerification: false,
      isUseExamRules: false,
      isCourseCompleteApprove: false,
      isCourseCompleteApproveNotify: false,
      hasPdfAction: false,
      attendClassOptions: [
        { value: "Percent", text: "% ของเวลาเรียนทั้งหมด" },
        { value: "Hour", text: "ชั่วโมง" },
      ],
      hours: 0,
      minutes: 0,
      isInvalidHourType: false,
      approvalOptions: [
        { value: 'ADMIN', text: 'แอดมิน' },
        { value: 'SYSTEM', text: 'ระบบ' },
      ],
      courseCompleteApprovalBy: null,
      autoApprovalDate: new Date().toISOString().split('T')[0],
    };
  },
  computed: {
    ...mapGetters("CourseManagement", [
      "isLoading",
      "courseInfo",
      "insuranceCourse",
    ]),
    ...mapGetters("CourseCompleteRule", [
      "courseCompleteRule",
      "courseCompleteActions",
      "courseLectures",
      "courseExamRules",
    ]),
    inputType() {
      return this.optionSelected === "Percent" ? "number" : "text";
    },
    maxTimeSpent() {
      if (this.optionSelected == "Percent") {
        return 100;
      } else if (this.optionSelected == "Hour") {
        return Math.floor((this.courseInfo.courseDuration / 3600) * 100) / 100;
      }
      return 0;
    },
    validatelecturePercentCount() {
      if (!this.courseCompleteRule.lecturePercentCount) return null;
      return this.courseCompleteRule.lecturePercentCount >= 0 &&
        this.courseCompleteRule.lecturePercentCount <= 100
        ? null
        : false;
    },
    validateTimeSpentPercent() {
      if (!this.timeSpentPercent) return null;
      if (this.optionSelected == "Percent") {
        return this.timeSpentPercent >= 0 && this.timeSpentPercent <= 100
          ? null
          : false;
      } else if (this.optionSelected == "Hour") {
        let timeSpentSecond = this.timeSpentPercent * 3600;

        return this.timeSpentPercent >= 0 &&
          timeSpentSecond <= this.courseInfo.courseDuration
          ? null
          : false;
      }
      return null;
    },
    validateLecturePercentWithApprovedFaceVerification() {
      if (!this.courseCompleteRule.lecturePercentWithApprovedFaceVerification)
        return null;
      return this.courseCompleteRule
        .lecturePercentWithApprovedFaceVerification >= 0 &&
        this.courseCompleteRule.lecturePercentWithApprovedFaceVerification <=
          100
        ? null
        : false;
    },
    timeSpentPercent: {
      get() {
        let duration = this.courseInfo.courseDuration;
        let time = this.courseCompleteRule.timeSpentSecond;
        return duration && time
          ? Math.floor((time / duration).toFixed(2) * 100)
          : 0;
      },
      set(percent) {
        let duration = this.courseInfo.courseDuration;
        if (duration) {
          this.courseCompleteRule.timeSpentSecond = (duration / 100) * percent;
        }
      },
    },
    optionSelected: {
      get() {
        return this.courseCompleteRule.timeSpentType ?? 'Percent';
      },
      set(value) {
        this.courseCompleteRule.timeSpentType = value;
      },
    },
    countExamRules: {
      get() {
        return this.courseExamRules.length;
      },
      set() {},
    },

    useLecturePercentCount: {
      get() {
        return !!this.courseCompleteRule.lecturePercentCount;
      },
      set() {},
    },

    useTimeSpentPercent: {
      get() {
        return this.isUseTimeSpentPercent;
      },
      set(value) {
        this.isUseTimeSpentPercent = value;
      },
    },

    useLecturePercentWithApprovedFaceVerification: {
      get() {
        return this.isUseLecturePercentWithApprovedFaceVerification;
      },
      set(value) {
        this.isUseLecturePercentWithApprovedFaceVerification = value;
      },
    },

    useExamRules: {
      get() {
        return this.isUseExamRules;
      },
      set(value) {
        this.isUseExamRules = value;
      },
    },

    courseCompleteApprove: {
      get() {
        return this.isCourseCompleteApprove;
      },
      set(value) {
        this.isCourseCompleteApprove = value;
        this.courseCompleteApprovalBy = (this.courseCompleteRule.requireApprovalBy == null && value == true) ? "ADMIN" : this.courseCompleteRule.requireApprovalBy;
        this.courseCompleteRule.enableRequireApproval = this.isCourseCompleteApprove;
        if( this.courseCompleteRule.notifyApprovalByEmail == null && value == true ){
          this.isCourseCompleteApproveNotify = true;
        }
      },
    },

    courseCompleteApproveNotify: {
      get() {
        return this.isCourseCompleteApproveNotify;
      },
      set(value) {
        this.isCourseCompleteApproveNotify = value;
        this.courseCompleteRule.notifyApprovalByEmail = this.isCourseCompleteApproveNotify ? 1 : 0;
      },
    },

    pdfAction: {
      get() {
        return this.hasPdfAction;
      },
      set(value) {
        this.hasPdfAction = value;
      },
    },

    courseCompleteActionsText: {
      get() {
        let actions = this.courseCompleteActions
          .filter((item) => item.action !== "PDF")
          .map((item) => {
            return item.action;
          });
        return actions.join();
      },
    },
  },
  watch: {
    useLecturePercentWithApprovedFaceVerification: function(newValue) {
      if (newValue === false) {
        this.courseCompleteRule.useLecturePercentWithApprovedFaceVerification = 0;
        this.courseCompleteRule.lecturePercentWithApprovedFaceVerification = 0;
        this.update();
      } else {
        this.courseCompleteRule.lecturePercentWithApprovedFaceVerification = 80;
        this.update(); 
      }
    },
  },
  async created() {
    this.isOverlayVisible = true;
    await api.get(
      `v1/members-role-permission/${this.$store.state.Authentication.authUser.id}`
    );
    this.getCourseInfo({
      courseId: this.courseId,
      temp: true,
      excepts: "numberOfStudent,numberOfActiveStudent",
    });
    this.fetchInsuranceCourse();
    await this.fetchCourseCompleteRules(this.courseId).then(() => {});
    await this.fetchCourseExamRules(this.courseId).then(() => {});
    this.course = this.courseInfo;
    this.isUseExamRules = !!this.courseExamRules.length;
    this.isUseTimeSpentPercent = this.timeSpentPercent !== 0;
    this.isUseLecturePercentWithApprovedFaceVerification = !!this
      .courseCompleteRule.lecturePercentWithApprovedFaceVerification;
    this.courseCompleteApprovalBy = this.courseCompleteRule.requireApprovalBy;
    this.autoApprovalDate = this.courseCompleteRule.autoApproveCompleteEnrollAt;
    this.isCourseCompleteApprove = this.courseCompleteRule.enableRequireApproval;
    this.isCourseCompleteApproveNotify = this.courseCompleteRule.notifyApprovalByEmail ? true : false;

    let pdfActionIndex = this.courseCompleteActions.findIndex(
      (item) => item.action === "PDF"
    );
    this.hasPdfAction = pdfActionIndex !== -1;
    this.isOverlayVisible = false;

    let time = this.courseCompleteRule.timeSpentSecond;
    this.hours = Math.floor(time / 3600)
      .toString()
      .padStart(2, "0");
    let remainingSeconds = time % 3600;
    this.minutes = Math.floor(remainingSeconds / 60)
      .toString()
      .padStart(2, "0");
  },
  beforeRouteLeave(to, from, next) {
    this.fetchCourseExamRules(this.courseId).then(() => {});
    next();
  },
  methods: {
    ...mapActions("CourseManagement", ["getCourseInfo", "getInsuranceCourse"]),

    ...mapActions("CourseCompleteRule", [
      "fetchCourseCompleteRules",
      "fetchCourseExamRules",
      "updateCourseCompleteRules",
      "updateCourseCompleteActions",
    ]),

    selectApprover(value) {
      this.courseCompleteApprovalBy = value;
      this.courseCompleteRule.requireApprovalBy = this.courseCompleteApprovalBy;
      this.update();
    },

    useTimeSpent(newValue) {
        if (newValue === false) {
          this.timeSpentPercent = 0;
        } else {
          this.timeSpentPercent = 80;
        }
        this.update();
    },
    formatDuration() {
      let totalSeconds = this.courseInfo.courseDuration;
      let hours = Math.floor(totalSeconds / 3600);
      let remainingSeconds = totalSeconds % 3600;
      let minutes = Math.round(remainingSeconds / 60);
      let formattedTime = hours + ":" + (minutes < 10 ? "0" : "") + minutes;
      return formattedTime;
    },
    async updateTime() {
      if (this.hours < 0) this.hours = 0;
      if (this.minutes < 0) this.minutes = 0;
      if (this.minutes > 59) this.minutes = 59;

      this.courseCompleteRule.timeSpentSecond =
        this.hours * 3600 + this.minutes * 60;

      if (
        this.courseCompleteRule.timeSpentSecond <=
        this.courseInfo.courseDuration
      ) {
        await this.update();
        this.isInvalidHourType = false;
      } else {
        this.isInvalidHourType = true;
      }
      this.hours = this.hours.toString().padStart(2, "0");
      this.minutes = this.minutes.toString().padStart(2, "0");
    },
    isInsuranceCourseLevel4() {
      if (
        this.insuranceCourse[0] === null ||
        this.insuranceCourse[0] === undefined
      ) {
        return false;
      } else if (this.insuranceCourse[0].insuranceCourseLevel === "RENEW4") {
        return true;
      } else {
        return false;
      }
    },

    isCompany(comIdArr) {
      return comIdArr.includes(this.company.id);
    },

    async fetchInsuranceCourse() {
      await this.getInsuranceCourse(this.courseId).then(() => {});
    },

    async update() {
      this.courseCompleteRule.autoApproveCompleteEnrollAt = this.courseCompleteApprovalBy == "SYSTEM" ? this.autoApprovalDate : null;
      await this.updateCourseCompleteRules({
        courseId: this.courseId,
        isUseExamRules: this.isUseExamRules,
      });
      await this.getCourseInfo({
        courseId: this.courseId,
        temp: true,
        excepts: "numberOfStudent,numberOfActiveStudent",
      });
    },

    async updateActions() {
      await this.updateCourseCompleteActions({
        courseId: this.courseId,
        hasPdfAction: this.hasPdfAction,
      });
      await this.updateCourseCompleteRules({
        courseId: this.courseId,
        isUseExamRules: this.isUseExamRules,
      });
      await this.getCourseInfo({
        courseId: this.courseId,
        temp: true,
        excepts: "numberOfStudent,numberOfActiveStudent",
      });
    },
    removeLeadingZeroLecturePercentCount() {
      this.courseCompleteRule.lecturePercentCount = Number(
        this.courseCompleteRule.lecturePercentCount
      ).toString();
    },
    removeLeadingZeroTimeSpentPercent() {
      if (this.optionSelected == "Percent") {
        this.timeSpentPercent = Number(this.timeSpentPercent).toString();
      }
    },
  },
};
</script>

<style scoped>
.time-input {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
}

.time-input input {
  flex: 1;
  padding: 5px;
  border: none;
  outline: none;
}

.time-input .separator {
  padding: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
