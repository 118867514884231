<template>
  <div>
    <layout>
      <template v-slot:header v-if="perPage == 10">
        <b-row>
          <b-col cols="6">
            <h4 style="font-family: 'Prompt', sans-serif;">คอร์สทั้งหมดในระบบ</h4>
          </b-col>
          <b-col cols="6" class="text-right">
            <a
              :href="`/${company.url}/analytics/courses/courses-list`"
              target="_blank"
              ><h6 style="font-family: 'Prompt', sans-serif;">ดูทั้งหมด</h6></a
            >
          </b-col>
        </b-row>
      </template>
      <template v-slot:body>
        <div class>
          <div v-if="perPage > 10" class="mb-3">
            <b-row>
              <b-col cols="10">
                <b-form-input
                  v-model="search"
                  placeholder="ค้นหาคอร์สเรียน"
                  style="padding: 1rem; display: inline-block; width: 20%"
                >
                </b-form-input>
              </b-col>

              <b-col cols="2" class="text-right">
                <b-button @click="exportData()" class="btn btn-download" href=""
                  ><i class="fa fa-angle-double-down" aria-hidden="true"></i>ดาวน์โหลดรายงาน</b-button
                >
              </b-col>
            </b-row>
          </div>
          <b-table
            id="course-overview-table"
            :per-page="perPage"
            :current-page="currentPage"
            class="text-left table"
            :items="
              search == null
                ? result
                : result.filter((i) => i.c_name.includes(search))
            "
            :fields="fields"
            small
            sort-icon-left
            responsive
            :busy="isBusy"
            hover
          >
            <template v-slot:cell(courseName)="data"
              ><span>{{ data.item.c_name | cut(10) }}</span></template
            >

            <template v-slot:cell(owner)="data">
              <span>{{
                (data.item.mp_firstName + " " + data.item.mp_lastName)
                  | cut(perPage)
              }}</span>
            </template>

            <template v-slot:cell(courseLength)="data">
              <span
                >{{
                  data.item.c_duration
                    ? data.item.c_duration + " ชั่วโมง"
                    : "00:00:00 ชั่วโมง"
                }}
              </span>
            </template>
            <template v-slot:cell(date)="data">
              <span>{{ data.item.c_lastUpdate | thaiDate }}</span>
            </template>
            <template v-slot:cell(price)="data">
              <span>{{ Number(data.item.c_price).toLocaleString() }}</span>
            </template>
            <template v-slot:cell(totalStudent)="data">
              <span>{{ data.item.totalStudent }}</span>
            </template>
            <template v-slot:cell(activeStudent)="data">
              <span>{{ data.item.activeStudent }}</span>
            </template>
            <template v-slot:cell(btn)="data">
              <a
                @click="addToState(data.item.c_name)"
                :href="
                  `/${company.url}/analytics/courses/${data.item.c_id}/overview`
                "
                target="_blank"
                ><b-button size="sm" variant="outline-primary">
                  <span>ดูข้อมูล</span>
                </b-button>
              </a>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-primary m-5">
                <b-spinner class="align-middle"></b-spinner>
                <br />
                <p class="mt-2">Loading...</p>
              </div>
            </template>
          </b-table>
          <div style="text-align: center" v-if="result.length == 0">
            {{ "ไม่มีข้อมูล" }}
          </div>
          <b-pagination
            v-model="currentPage"
            :total-rows="
              search == null
                ? rows
                : result.filter((i) => i.c_name.includes(search)).length
            "
            :per-page="perPage"
            aria-controls="course-overview-table"
            v-if="perPage > 10"
          ></b-pagination>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
import layout from "@/components/pages/analytics/shared/Layout";
import api from "@/services/api.js";
import exp from "@/services/export.js";
import moment from "moment";
export default {
  components: {
    layout,
  },
  props: ["apiRoute", "perPage"],
  data() {
    return {
      company: {},
      search: null,
      currentPage: 1,
      rows: 0,
      result: [],
      isBusy: true,
      fields: [
        { key: "courseName", label: "คอร์สเรียน", thClass: "hello" },
        { key: "owner", label: "ชื่อผู้สอน", thClass: "hello" },
        {
          key: "courseLength",
          label: "ความยาวคอร์ส",
          thClass: "hello",
        },
        {
          key: "date",
          label: "วันที่อัพเดท",
          thClass: "hello",
        },
        {
          key: "price",
          label: "ราคาคอร์ส",
          thClass: "hello",
        },
        {
          key: "totalStudent",
          label: "จำนวนผู้เรียน",
          thClass: "hello",
        },
        {
          key: "activeStudent",
          label: "จำนวนผู้เรียนที่เรียนอยู่",
          thClass: "hello",
        },
        {
          key: "btn",

          label: "",
          thClass: "hello",
        },
      ],
      items: [],
      items2: [],
    };
  },
  methods: {
    async exportData() {
      let value = [];
      let set =
        this.search == null
          ? this.result
          : this.result.filter((i) => i.c_name.includes(this.search));
      set.forEach((i) => {
        let temp = {
          c_name: i.c_name,
          instructor: i.mp_firstName + " " + i.mp_lastName,
          duration: i.c_duration
            ? i.c_duration + " ชั่วโมง"
            : "00:00:00 ชั่วโมง",
          updateDate: i.c_lastUpdate
            ? this.formatDate(i.c_lastUpdate)
            : "ยังไม่มีการอัพเดต",
          price: i.c_price ? i.c_price : 0.0,
          totalStudent: i.totalStudent,
          activeStudent: i.activeStudent,
        };
        value.push(temp);
      });
      let key = [
        "คอร์สเรียน",
        "ชื่อผู้สอน",
        "ความยาวคอร์ส",
        "วันที่อัพเดท",
        "ราคาคอร์ส",
        "จำนวนผู้เรียน",
        "จำนวนผู้เรียนที่เรียนอยู่",
      ];
      let filename = "Course-Overview-Detail-" + this.company.url;
      const data = {
        key: key,
        filename: filename,
        data: value,
      };
      await exp.exportData(data);
    },

    formatDate(value) {
      if (value) {
        moment.updateLocale("th", {});
        let year = parseInt(moment(String(value)).format("YYYY"));
        year += 543;
        return moment(String(value)).format(`D MMM ${year}`);
      }
    },
  },
  async mounted() {
    this.isBusy = true;
    let res = await api.get(this.apiRoute);
    this.result = res.data;
    this.rows = this.result.length;
    this.currentPage = 1;
    setTimeout(() => {
      this.isBusy = false;
    }, 1 * 1000);
    this.company = this.$store.state.Company.companyInfo;
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/base/_dashboard.scss";
h6 {
  margin-bottom: 0 !important;
}
</style>
