<template>
  <div>
    <header class="head">
      <div class="main-bar">
        <h5 class="nav_top_align text-dark font-weight-bold title-head">
          {{ bestScoreAnswerSheet
            ? `การเข้าทำข้อสอบ ${bestScoreAnswerSheet.metadata.examTitle} ของ ${bestScoreAnswerSheet.metadata.memberName}`
            : 'Loading ...'
          }}
        </h5>
      </div>
    </header>
    <b-row class="p-4 px-md-5">
      <b-col v-if="this.company.id == 515" cols="12" class="my-4" style="text-align: end;">
        <h5 class="text-dark font-weight-bold title-head" v-if="memberInfo?.staffId && memberInfo?.department">
          รหัสพนักงาน {{ memberInfo.staffId }} | {{ memberInfo.department }}
        </h5>
        <h5 class="text-dark font-weight-bold title-head" v-else-if="memberInfo?.staffId">
          รหัสพนักงาน {{ memberInfo.staffId }}
        </h5>
        <h5 class="text-dark font-weight-bold title-head" v-else-if="memberInfo?.department">
          {{ memberInfo.department }}
        </h5>
      </b-col>

      <b-col cols="12" class="my-1">
        <div class="d-lg-flex">
          <h6><b-link @click="toPageExamOverview()">สรุปภาพรวมข้อสอบ</b-link><a style="color : gray"> / {{ bestScoreAnswerSheet ? `${bestScoreAnswerSheet.metadata.examTitle}` : 'Loading ...'}} </a></h6>
        </div>
      </b-col>
      <b-col cols="12" class="my-1">
        <exam-status-panel
          :bestScoreAnswerSheet="bestScoreAnswerSheet"
          :totalAnswerSheetsAmount="
            totalAnswerSheets ? totalAnswerSheets.length : null
          "
        ></exam-status-panel>
      </b-col>
      <b-col cols="12" class="my-1">
        <exam-table-panel
          :company="company"
          :examId="examId"
          :memberId="memberId"
          :totalAnswerSheets="totalAnswerSheets"
          :hasDonutChart="true"
          @selectedAnswerSheet="setAnswerSheet"
        ></exam-table-panel>
      </b-col>
      <b-col cols="12" class="mb-1 col-lg-10 offset-lg-1">
        <exam-figure-panel
          :bestScoreAnswerSheet="selectedAnswerSheet"
        ></exam-figure-panel>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ExamStatusPanel from "@/components/pages/analytics/exam/individual-learner/panel/ExamStatus.vue";
import ExamTablePanel from "@/components/pages/analytics/exam/individual-learner/panel/ExamTable.vue";
import ExamFigurePanel from "@/components/pages/analytics/exam/individual-learner/panel/ExamFigure.vue";
import axios from "@/services/exam-api.js";
import api from "@/services/api.js";
import adminService from "@/services/api.js";
export default {
  components: {
    ExamStatusPanel,
    ExamTablePanel,
    ExamFigurePanel
  },
  data() {
    return {
      company: null,
      quizIds: null,
      examId: null,
      memberId: null,
      bestScoreAnswerSheet: null,
      totalAnswerSheets: null,
      memberInfo: null,
      selectedAnswerSheet: null,
    };
  },
  created() {
    this.company = this.$store.state.Company.companyInfo;
    this.memberId = this.$route.params.memberId;
    this.examId = this.$route.params.examId;
    this.fetchAvailableQuizIds();
    this.fetchAllAnswerSheet();
    if (this.company.id == 515) this.fetchMemberInfo();
  },
  methods: {
    setAnswerSheet(answerSheet) {
      this.selectedAnswerSheet = answerSheet;
    },
    async fetchAvailableQuizIds() {
      try {
        const endpoint = `/v1/analytics/companies/${this.company.id}/courseQuizIds`;
        const result = await adminService.get(endpoint);
        this.quizIds = result.data.data.map(el => el.quizExamId);
        if (!this.quizIds.includes(this.examId)) {
          let url = `${window.location.origin}/${
            window.location.pathname.split("/")[1]
          }/un-authorized`;
          window.location.replace(url);
        }
      } catch (err) {
        console.log("fetchAvailableQuizIds error!", err);
      }
    },
    async fetchAllAnswerSheet() {
      try {
        const endpoint = `/answer-sheets/get-all-answer-sheets/exam/${
          this.examId
        }/member/${this.memberId}?filter={"comId":${
          this.company.id
        }}&access_token=${localStorage.getItem("token")}`;
        const result = await axios.get(endpoint);
        this.totalAnswerSheets = result.data;

        this.selectedAnswerSheet = result.data[0]
        result.data.forEach(exam => {
          if (exam.overallResult.bestScore) {
            this.bestScoreAnswerSheet = exam;
          }
        });
      } catch (err) {
        console.log("fetchAllAnswerSheet error!", err);
      }
    },
    async fetchMemberInfo() {
      try {
        const endpoint = `/v1/members/${this.memberId}`;
        const results = await api.get(endpoint);
        this.memberInfo = results.data.data;
      } catch (err) {
        console.log("fetchMemberInfo error", err);
      }
    },
  }
};
</script>

<style scoped>
@media (min-width: 997px) {
  .head{
    margin-left: -6%;
  }
  .main-bar{
    margin-left : 7%
  }
}
</style>
