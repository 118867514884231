<template>
  <div style>
    <b-row>
      <b-col cols>
        <layout>
          <template v-slot:header>
            <h6>สัดส่วนคำตอบ</h6>
          </template>
          <template v-slot:body>
            <div v-if="mockPieChart" :style="mockPieStyle">
              <h6 :style="mockPieText">ไม่มีข้อมูล</h6>
            </div>
            <div v-else style="padding: 0.5rem">
              <b-row>
                <b-col class="text-right d-none d-lg-block" cols="9" offset="2">
                  <p>
                    จากการทำข้อสอบ {{ result.length && result[0].count }} ครั้ง
                  </p>
                </b-col>
                <b-col lg="6">
                  <PieChart
                    :chart-data="datacollection"
                    :options="option"
                    :height="200"
                  ></PieChart>
                </b-col>
                <b-col class="text-center d-lg-none mt-3" cols="12">
                  <p>
                    จากการทำข้อสอบ {{ result.length && result[0].count }} ครั้ง
                  </p>
                </b-col>
                <b-col lg="6">
                  <my-legend
                    :num="3"
                    :color="['#FCC059', '#FDDCA1', '#EAA537']"
                  >
                    <template v-slot:1>
                      <p>
                        ข้อถูก
                        {{
                          result.length && total
                            ? ((result[0].correct / total) * 100).toFixed(2)
                            : "0.00"
                        }}%
                      </p>
                    </template>
                    <template v-slot:2>
                      <p>
                        ข้อผิด
                        {{
                          result.length && total
                            ? ((result[0].incorrect / total) * 100).toFixed(2)
                            : "0.00"
                        }}%
                      </p>
                    </template>
                    <template v-slot:3>
                      <p>
                        ไม่ตอบ
                        {{
                          result.length && total
                            ? ((result[0].blank / total) * 100).toFixed(2)
                            : "0.00"
                        }}%
                      </p>
                    </template>
                  </my-legend>
                </b-col>
              </b-row>
            </div>
          </template>
        </layout>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PieChart from "@/components/pages/analytics/shared/PieChart";
import layout from "@/components/pages/analytics/shared/Layout";
import myLegend from "@/components/pages/analytics/shared/LegendPieChart";
import api from "@/services/exam-api.js";
import chartMockMixin from "@/mixins/chart-mock-mixin.js";

export default {
  mixins: [chartMockMixin],
  components: {
    PieChart,
    layout,
    myLegend,
  },
  props: ["apiRoute"],
  data() {
    return {
      datacollection: {},
      option: {},
      total: 0,
      result: {},
    };
  },
  watch: {
    apiRoute() {
      this.fetch();
    },
  },
  async mounted() {
    this.fetch();
  },
  methods: {
    async fetch() {
      let res = await api.get(this.apiRoute);
      this.result = res.data;
      this.checkMockPieChart(res.data);
      this.total =
        this.result[0].correct +
        this.result[0].incorrect +
        this.result[0].blank;
      this.fillData();
      this.fillOption();
    },
    fillData() {
      let data = [
        this.result[0].correct,
        this.result[0].incorrect,
        this.result[0].blank,
      ];
      this.datacollection = {
        labels: ["ข้อถูก", "ข้อผิด", "ไม่ตอบ"],
        datasets: [
          {
            backgroundColor: ["#FCC059", "#FDDCA1", "#EAA537"],

            data: data,
          },
        ],
        borderWidth: 5,
      };
    },
    fillOption() {
      this.option = {
        legend: {
          display: false,
          position: "right",
          labels: {
            fontSize: 20,
            fontColor: "#000",
          },
        },
        plugins: {
          datalabels: {
            color: "black",
            labels: {
              title: {
                font: {
                  weight: "bold",
                  size: 16,
                },
              },
            },
            formatter: (value) => {
              // if (value > 0) {
              //   let sum = 0;
              //   let dataArr = ctx.chart.data.datasets[0].data;
              //   dataArr.map((data) => {
              //     sum += data;
              //   });
              //   sum = sum.toString();
              //   sum = sum.split(/(?=(?:...)*$)/);
              //   sum = sum.join(",");
              //   return sum;
              // } else {
              //   value = "";
              if (value > 0) {
                return value;
              } else return null;
              // }
            },
          },
        },

        elements: {
          arc: {
            borderWidth: 0,
          },
        },

        maintainAspectRatio: false,
        responsive: true,
      };
    },
    getRandomInt() {
      let n = Math.floor(Math.random() * (1000 - 500 + 1)) + 500;
      let n1 = Math.floor(Math.random() * (n - 0 + 1)) + 0;
      let n2 = n - n1;

      return { n1, n2 };
    },
  },
};
</script>

<style scoped>
.std-all-box {
  font-size: 15px;
  color: white;
  background-color: #eb8123;
}
p, h6 {
  font-family: "Prompt", sans-serif;
}
</style>
