import jsPDF from "jspdf";
import { font, fontBold } from "@/assets/fonts/Sarabun-normal.js";

export default {
  methods: {
    async generateLearningHistoryReport() {
      try {
        let pdf = new jsPDF(this.pdfAllCourseOption);

        const pdf_width = pdf.internal.pageSize.width;
        const pdf_height = pdf.internal.pageSize.height;

        const margin_l = this.pdfAllCourseConfig.margin.l;
        const margin_t = this.pdfAllCourseConfig.margin.t;

        const addNewPageIfNeeded = (incrementValue, rowHeight = 0) => {
          if ((pdf_position_y + incrementValue + rowHeight) >= pdf_height - margin_t) {
            pdf.addPage();
            pdf_position_y = margin_t; // Reset the position to the top of the new page
          } else {
            pdf_position_y += incrementValue; // Add the input value
          }
        };

        const cellHeight = 18;

        let pdf_position_y = 0;

        pdf.addFileToVFS("Sarabun-Regular.ttf", fontBold);
        pdf.addFont("Sarabun-Regular.ttf", "Sarabun", "bold");
        pdf.addFileToVFS("Sarabun-Regular.ttf", font);
        pdf.addFont("Sarabun-Regular.ttf", "Sarabun", "normal");

        pdf.setFont("Sarabun", "bold");

        const proxyUrl = 'https://corsproxy.io/?url=';
        const logoImg = await this.loadImage(`${proxyUrl}${this.companyData.whitelabelUrl}/images/${this.companyData.url}/brand_logo.png`);
        const logoImgHeight = 30;
        const logoImgWidth = (logoImg.naturalWidth / logoImg.naturalHeight) * logoImgHeight;
        pdf.setFontSize(this.pdfAllCourseConfig.typo.header);
        pdf_position_y += margin_t;
        pdf.addImage(logoImg, 'JPEG', margin_l, pdf_position_y - 17.5, logoImgWidth, logoImgHeight);

        pdf.text(
          "รายงานการฝึกอบรม",
          pdf_width / 2,
          pdf_position_y,
          null,
          null,
          "center"
        );
        pdf.setFont("Sarabun", "normal");
        pdf.setFontSize(this.pdfAllCourseConfig.typo.normal);
        const nowDate = new Date();
        const formattedDate = nowDate.toISOString().split('T')[0];
        this.thaitext(
          pdf,
          "วันที่ตีพิมพ์: " + this.formatDateToThaiShortYear2Digits(formattedDate),
          pdf_width - margin_l - 70,
          pdf_position_y
        );
        addNewPageIfNeeded(25);
        pdf.rect(margin_l, pdf_position_y, pdf_width - margin_l*2, 0);
        addNewPageIfNeeded(20);


        pdf.setFont("Sarabun", "normal");
        pdf.setFontSize(this.pdfAllCourseConfig.typo.normal);
        this.thaitext(
          pdf,
          "ชื่อผู้เรียน : " + this.memberName,
          margin_l,
          pdf_position_y
        );
        addNewPageIfNeeded(this.pdfAllCourseConfig.typo.normal);
        if(this.memberGender && this.comId == 515) {
          this.thaitext(
            pdf,
            "เพศ : " + this.memberGender,
            margin_l,
            pdf_position_y
          );
          addNewPageIfNeeded(this.pdfAllCourseConfig.typo.normal);
        }
        
        this.thaitext(
          pdf,
          "อีเมล  : " + this.memberData.email,
          margin_l,
          pdf_position_y
        );
        addNewPageIfNeeded(this.pdfAllCourseConfig.typo.normal);
        if (this.memberData.department && this.comId == 515) {
          this.thaitext(
            pdf,
            "หน่วยงาน  : " + this.memberData.department,
            margin_l,
            pdf_position_y
          );
          addNewPageIfNeeded(this.pdfAllCourseConfig.typo.large);
        }
        if (this.comId == 515){
          pdf.rect(margin_l, pdf_position_y, 30, cellHeight);
          pdf.rect(margin_l + 30, pdf_position_y, 50, cellHeight);
          pdf.rect(margin_l + 80, pdf_position_y, 150, cellHeight);
          pdf.rect(margin_l + 230, pdf_position_y, 80, cellHeight);
          pdf.rect(margin_l + 310, pdf_position_y, 70, cellHeight);
          pdf.rect(margin_l + 380, pdf_position_y, 60, cellHeight);
          pdf.rect(margin_l + 440, pdf_position_y, 100, cellHeight);

          this.thaitext(pdf, "ลำดับ", margin_l + 5, pdf_position_y + 12);
          this.thaitext(pdf, "รหัสหลักสูตร", margin_l + 35, pdf_position_y + 12);
          this.thaitext(pdf, "หลักสูตรที่ผ่านการอบรม", margin_l + 85, pdf_position_y + 12);
          this.thaitext(pdf, "วันที่อบรม", margin_l + 235, pdf_position_y + 12);
          this.thaitext(pdf, "จำนวนชั่วโมงอบรม", margin_l + 315, pdf_position_y + 12);
          this.thaitext(pdf, "จัดโดย", margin_l + 385, pdf_position_y + 12);
          this.thaitext(pdf, "วิทยากร", margin_l + 445, pdf_position_y + 12);

          addNewPageIfNeeded(this.pdfAllCourseConfig.endRow);
          
          for( let i = 0 ; i < this.courseList.length ; i++) {
            let date = this.thaiDateFilter(this.courseList[i].cerStartDate, true) + 
              (this.courseList[i].cerCompleteDate ? ' - ' + this.thaiDateFilter(this.courseList[i].cerCompleteDate, true) : '');
            // check max height
            let heights = [
              pdf.splitTextToSize(this.courseList[i].courseCode, 40).length,
              pdf.splitTextToSize(this.courseList[i].courseName, 140).length,
              pdf.splitTextToSize(date, 70).length,
              pdf.splitTextToSize(this.thaiDurationHourMinuteFilter(this.duration(this.courseList[i].learningTime)), 60).length,
              pdf.splitTextToSize(this.courseList[i].courseTags, 50).length,
              pdf.splitTextToSize(this.courseList[i].courseOwner, 90).length
            ];
            
            let maxHeight = Math.max(...heights) - 1;

            pdf.rect(margin_l, pdf_position_y, 30, cellHeight + ( 12 * maxHeight));
            pdf.rect(margin_l + 30, pdf_position_y, 50, cellHeight + ( 12 * maxHeight));
            pdf.rect(margin_l + 80, pdf_position_y, 150, cellHeight + ( 12 * maxHeight));
            pdf.rect(margin_l + 230, pdf_position_y, 80, cellHeight + ( 12 * maxHeight));
            pdf.rect(margin_l + 310, pdf_position_y, 70, cellHeight + ( 12 * maxHeight));
            pdf.rect(margin_l + 380, pdf_position_y, 60, cellHeight + ( 12 * maxHeight));
            pdf.rect(margin_l + 440, pdf_position_y, 100, cellHeight + ( 12 * maxHeight));

            let index = i + 1;
            pdf.text(index.toString(), margin_l + 5, pdf_position_y + 12);
            if ( this.courseList[i].courseCode ) {
              pdf.text(this.courseList[i].courseCode, margin_l + 35, pdf_position_y + 12, { maxWidth: 40, lineHeightFactor: 1.3 });
            } else {
              pdf.text('-', margin_l + 35, pdf_position_y + 12, { maxWidth: 40, lineHeightFactor: 1.3 });
            }
            this.thaitext(pdf, this.courseList[i].courseName, margin_l + 85, pdf_position_y + 12, 140);
            this.thaitext(pdf, date, margin_l + 235, pdf_position_y + 12, 70);
            this.thaitext(pdf, this.thaiDurationHourMinuteFilter(this.duration(this.courseList[i].learningTime)), margin_l + 315, pdf_position_y + 12, 60);
            this.thaitext(pdf, this.courseList[i].courseTags, margin_l + 385, pdf_position_y + 12, 60);
            this.thaitext(pdf, this.courseList[i].courseOwner, margin_l + 445, pdf_position_y + 12, 90);

            addNewPageIfNeeded(this.pdfAllCourseConfig.endRow + ( 12 * (maxHeight)), cellHeight  + ( 12 * maxHeight));
          }
          
        } else {
          pdf.rect(margin_l, pdf_position_y, 30, cellHeight);
          pdf.rect(margin_l + 30, pdf_position_y, 200, cellHeight);
          pdf.rect(margin_l + 230, pdf_position_y, 80, cellHeight);
          pdf.rect(margin_l + 310, pdf_position_y, 70, cellHeight);
          pdf.rect(margin_l + 380, pdf_position_y, 60, cellHeight);
          pdf.rect(margin_l + 440, pdf_position_y, 100, cellHeight);

          this.thaitext(pdf, "ลำดับ", margin_l + 5, pdf_position_y + 12);
          this.thaitext(pdf, "หลักสูตรที่ผ่านการอบรม", margin_l + 35, pdf_position_y + 12);
          this.thaitext(pdf, "วันที่ลงทะเบียน", margin_l + 235, pdf_position_y + 12);
          this.thaitext(pdf, "ความยาวหลักสูตร", margin_l + 315, pdf_position_y + 12);
          this.thaitext(pdf, "เข้าเรียนแล้ว", margin_l + 385, pdf_position_y + 12);
          this.thaitext(pdf, "ผู้สอน", margin_l + 445, pdf_position_y + 12);

          addNewPageIfNeeded(this.pdfAllCourseConfig.endRow);
          
          for( let i = 0 ; i < this.courseList.length ; i++) {
            // check max height
            let heights = [
              pdf.splitTextToSize(this.courseList[i].courseName, 190).length,
              pdf.splitTextToSize(this.thaiDateFilter(this.courseList[i].cerStartDate, true), 70).length,
              pdf.splitTextToSize(this.thaiDurationHourMinuteFilter(this.duration(this.courseList[i].courseDuration)), 60).length,
              pdf.splitTextToSize(this.thaiDurationHourMinuteFilter(this.duration(this.courseList[i].learningTime)), 50).length,
              pdf.splitTextToSize(this.courseList[i].courseOwner, 90).length
            ];
            
            let maxHeight = Math.max(...heights) - 1;

            pdf.rect(margin_l, pdf_position_y, 30, cellHeight + ( 12 * maxHeight));
            pdf.rect(margin_l + 30, pdf_position_y, 200, cellHeight + ( 12 * maxHeight));
            pdf.rect(margin_l + 230, pdf_position_y, 80, cellHeight + ( 12 * maxHeight));
            pdf.rect(margin_l + 310, pdf_position_y, 70, cellHeight + ( 12 * maxHeight));
            pdf.rect(margin_l + 380, pdf_position_y, 60, cellHeight + ( 12 * maxHeight));
            pdf.rect(margin_l + 440, pdf_position_y, 100, cellHeight + ( 12 * maxHeight));


            let index = i + 1;
            pdf.text(index.toString(), margin_l + 5, pdf_position_y + 12);
            this.thaitext(pdf, this.courseList[i].courseName, margin_l + 35, pdf_position_y + 12, 190);
            this.thaitext(pdf, this.thaiDateFilter(this.courseList[i].cerStartDate, true), margin_l + 235, pdf_position_y + 12, 70);
            this.thaitext(pdf, this.thaiDurationHourMinuteFilter(this.duration(this.courseList[i].courseDuration)), margin_l + 315, pdf_position_y + 12, 60);
            this.thaitext(pdf, this.thaiDurationHourMinuteFilter(this.duration(this.courseList[i].learningTime)), margin_l + 385, pdf_position_y + 12, 60);
            this.thaitext(pdf, this.courseList[i].courseOwner, margin_l + 445, pdf_position_y + 12, 90);

            addNewPageIfNeeded(this.pdfAllCourseConfig.endRow + ( 12 * (maxHeight)), cellHeight  + ( 12 * maxHeight));
          }
        }
        
        



        setTimeout(() => {
          pdf.save("รายงานการฝึกอบรม.pdf");
          this.generatingPDF = false;
        }, 0);
      } catch (err) {
        console.log(err);
        this.generatingPDF = false;
      }     
    }
  }
}