<template>
  <div>
    <layout class="user-learn-progress">
      <template v-slot:header> </template>
      <template v-slot:body>
        <div class="d-flex justify-content-between mb-3">
          <p>เกณฑ์การจบคอร์ส</p>
          <p>
            วันที่เรียนจบคอร์ส
            {{
              studentCcr.cerEndDate
                ? studentCcr.cerEndDate
                : "ยังเรียนไม่จบ" | thaiDateFilter
            }}
          </p>
        </div>
        <table class="table table-hover table-striped">
          <thead>
            <tr>
              <th scope="col">เงื่อนไข</th>
              <th scope="col">สถานะ</th>
            </tr>
          </thead>
          <tbody>
            <div v-if="isLoading" class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
            <div
              v-else-if="checkStudentCcr(studentCcr.ruleData) == 0"
              class="text-center mt-4"
            >
              <h5>ไม่มีเกณฑ์การจบคอร์ส</h5>
            </div>
            <tr
              v-else
              v-for="(stuccr, key, index) in studentCcr.ruleData"
              :key="index"
            >
              <td>{{ key }}</td>
              <td :class="[stuccr ? 'text-success' : 'text-danger']">
                {{ stuccr ? "ผ่าน" : "ไม่ผ่าน" }}
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </layout>
  </div>
</template>
<script>
import dateMixins from "@/components/pages/analytics/shared/mixins/date.js";
import api from "@/services/api.js";
import layout from "@/components/pages/analytics/shared/Layout";
export default {
  components: {
    layout,
  },

  mixins: [dateMixins],
  data() {
    return {
      studentCcr: [],
      cId: this.$route.params.cId,
      cerId: this.$route.params.cerId,
      isLoading: true,
    };
  },
  async mounted() {
    await api.get("/v1/student-ccr/cerId/" + this.cerId).then(({ data }) => {
      this.studentCcr = data;
      this.isLoading = false;
    });
  },

  methods: {
    checkStudentCcr(studentCcr) {
      if (studentCcr === null || studentCcr === undefined) {
        return 0;
      } else {
        return Object.keys(studentCcr).length;
      }
    },
  },
};
</script>
<style scoped>
p {
  font-family: "Prompt", sans-serif;
}
</style>