<template>
  <div class>
    <layout>
      <template v-slot:header v-if="perPage == 10">
        <b-row>
          <b-col cols="6">
            <h6>สถิติถูกผิดของคำถามแต่ละข้อ</h6>
          </b-col>
          <b-col cols="6" class="text-right">
            <a
              :href="`/${company.url}/analytics/exam/${examId}/exam-question-stat?bestScore=${bestScoreSelected}`"
              target="_blank"
              ><h6>ดูทั้งหมด</h6></a
            >
          </b-col>
        </b-row>
      </template>

      <template v-slot:body>
        <div class>
          <div v-if="perPage > 10" class="mb-3">
            <b-row class="justify-content-end">
              <b-col cols="2" class="text-right">
                <b-button @click="exportData()" class="btn btn-download" href=""
                  ><i class="fa fa-angle-double-down" aria-hidden="true"></i>ดาวน์โหลดรายงาน</b-button
                >
              </b-col>
            </b-row>
          </div>
          <b-table
            striped
            id="exam-question-table"
            :per-page="perPage"
            :current-page="currentPage"
            table-class="tbNumber text-left "
            :items="result"
            :fields="fields"
            small
            sort-icon-left
            responsive
            :busy="isBusy"
            hover
          >
            <template v-slot:cell(question)="data">
              <div v-if="data.item.mainQuestionTitle">
                <mark-down
                  :message="data.item.mainQuestionTitle + '<br><strong>' + data.item.questionTitle + '</strong>'"
                ></mark-down>
              </div>
              <div v-else>
                <mark-down
                  :message="data.item.questionTitle"
                ></mark-down>
              </div>
              
            </template>

            <template v-slot:cell(correct)="data">
              <span class="d-block text-right">{{ data.item.totalCorrect }}</span>
            </template>

            <template v-slot:cell(incorrect)="data">
              <span class="d-block text-right">{{ data.item.totalIncorrect }}</span>
            </template>
            <template v-slot:cell(btn)="data">
              <b-button
                size="sm"
                variant="outline-primary"
                v-b-modal="data.item._id"
              >
                <span>ดูข้อมูล</span>
              </b-button>
              <b-modal :id="data.item._id" size="xl" :hide-footer="true">
                <modal
                  :mainQuestionTitle="data.item.mainQuestionTitle"
                  :apiRoute="`exams/${examId}/question/${data.item._id}/get-question-stat-by-exam-id-question-id?bestScore=${bestScoreSelected}`"
                ></modal>
              </b-modal>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="exam-question-table"
            v-if="rows > 0"
          ></b-pagination>
        </div>
      </template>
    </layout>
  </div>
</template>
<script>
import layout from "@/components/pages/analytics/shared/Layout";
import api from "@/services/exam-api.js";
import modal from "./../modal/ExamQuestionOverviewModal";
import exp from "@/services/export.js";
import MarkDown from "@/components/shared/Markdown.vue";

export default {
  components: {
    layout,
    modal,
    MarkDown
  },
  props: ["apiRoute", "perPage","bestScoreSelected"],
  data() {
    return {
      currentPage: 1,
      rows: 0,
      result: [],
      isBusy: true,
      fields: [
        { key: "question", label: "คำถาม", thStyle: { width: "81%" }, },
        { key: "correct", label: "จำนวนครั้งที่ตอบถูก", thStyle: { width: "7%" }, },
        { key: "incorrect", label: "จำนวนครั้งที่ตอบผิด", thStyle: { width: "7%" }, },
        { key: "btn", label: "", thStyle: { width: "5%" }, },
      ],
      items: [],
      company: {},
      examId: this.$route.params.examId,
    };
  },
  methods: {
    async exportData() {
      let value = [];
      let set = this.result;
      set.forEach((i) => {
        let temp = {
          title: i.questionTitle ? i.questionTitle : "-",
          pass: i.totalCorrect ? i.totalCorrect : 0,
          total: i.totalIncorrect ? i.totalIncorrect : 0,
        };
        value.push(temp);
      });
      let key = ["คำถาม", "จำนวนครั้งที่ตอบถูก", "จำนวนครั้งที่ตอบผิด"];
      let filename = "Individual-Exam-Question-Stat-" + this.company.url;
      const data = {
        key: key,
        filename: filename,
        data: value,
      };
      await exp.exportData(data);
    },
    async fetch() {
      let res = await api.get(this.apiRoute + "?bestScore=" + this.bestScoreSelected);
      this.result =
        res.data &&
        res.data.filter((i) => i._id != null && i.questionType != "BUNDLE");

      this.rows = this.result.length;
      setTimeout(() => {
        this.isBusy = false;
      }, 1 * 1000);
      this.company = this.$store.state.Company.companyInfo;
    },
  },
  watch: {
    apiRoute() {
      this.fetch();
    },
  },
  async mounted() {
    this.fetch();
  },
};
</script>
<style scoped>
h6 {
  font-family: "Prompt", sans-serif;
}
</style>
