<template>
  <div class>
    <layout>
      <template v-slot:header>
        <b-row>
          <b-col cols="6">
            <h6>ความก้าวหน้าของผู้เรียนแต่ละหลักสูตร</h6>
          </b-col>
          <b-col cols="6" class="text-right">
            <a :href="`${$route.path}/learn-progress`" target="_blank">
              <h6>ดูทั้งหมด</h6>
            </a>
          </b-col>
        </b-row>
      </template>
      <template v-slot:body>
        <div class>
          <b-table
            table-class="tbNumber text-left "
            :items="items"
            :fields="fields"
            small
            sort-icon-left
            responsive
            :busy="isLoading"
            hover
            striped
          >
            <template v-slot:cell(courseName)="data">
              <span>{{ data.item.c_name | cut }}</span>
            </template>
            <template v-slot:cell(enrollment)="data">
              <span v-if="data.item.cer_status == 'NOT_STARTED'"
                >ยังไม่เริ่มเรียน</span
              >
              <span v-else-if="data.item.cer_status == 'IN_PROGRESS'"
                >กำลังเรียน ({{ data.item.lel_completed }})</span
              >
              <span v-else-if="data.item.cer_status == 'COMPLETED'"
                >เรียนจบแล้ว</span
              >
              <span v-else-if="data.item.cer_status == 'TIMEOUT'"
                >จำนวนชั่วโมงหมด</span
              >
            </template>
            <template v-slot:cell(courseEndDate)="data">
              <span>{{ data.item.cer_endDate | thaiDateFilter }}</span>
            </template>
            <template v-slot:cell(completeRule)="data">
              <span
                class="complete-rule-link"
                v-b-modal="`modal-complete-rule-${data.item.cer_id}`"
              >
                <span>{{ data.item.completeRuleStatus }}</span>
              </span>
              <b-modal size="xl" :id="`modal-complete-rule-${data.item.cer_id}`">
                <template v-slot:modal-title>
                  <span>เกณฑ์การจบคอร์ส {{ data.item.c_name }}</span
                  ><br />
                  <span>ของ {{ memberInfo.firstName + " " + memberInfo.lastName }}</span>
                </template>
                <complete-rule-modal
                  :apiRoute="`/v1/members/${memberId}/course/${data.item.c_id}/complete-rules?cerId=${data.item.cer_id}`"
                ></complete-rule-modal>
              </b-modal>
            </template>
            <template v-slot:cell(courseStatus)="data">
              <span v-if="data.item.cer_isExpired" style="color: red"
                >หมดอายุ</span
              >
              <span v-else-if="data.item.cer_status == 'TIMEOUT'" style="color: red;">จำนวนชั่วโมงหมด</span>
              <span v-else style="color: green">เข้าเรียนได้</span>
            </template>
            <template v-slot:cell(info)="data">
              <b-button class="mr-2" size="sm" variant="outline-primary" @click="redirectToLearningHistoryEnroll(data.item.cer_id)">
                  ดูประวัติ
              </b-button>
              <b-button size="sm" variant="outline-primary">
                <a
                  :href="`${$route.path}/learn-progress/${data.item.cer_id}/courses/${data.item.c_id}/overview`"
                  target="_blank"
                >
                  ดูข้อมูล
                </a>
              </b-button>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
import layout from "@/components/pages/analytics/shared/Layout.vue";
import api from "@/services/api.js";
import dateMixins from "@/components/pages/analytics/shared/mixins/date.js";
import timeMixins from "@/components/pages/analytics/shared/mixins/time.js";
import CompleteRuleModal from "./CompleteRuleModal.vue";

export default {
  components: {
    layout,
    CompleteRuleModal,
  },
  mixins: [dateMixins, timeMixins],
  data() {
    return {
      memberId: null,
      memberInfo: {},
      isLoading: true,
      fields: [
        { key: "courseName", label: "คอร์สเรียน" },
        { key: "enrollment", label: "การเข้าเรียน" },
        { key: "courseEndDate", label: "วันหมดอายุคอร์ส" },
        { key: "completeRule", label: "ผ่านเกณฑ์การจบคอร์ส" },
        { key: "courseStatus", label: "สถานะคอร์ส" },
        { key: "info", label: "" },
      ],
      items: [],
    };
  },
  mounted() {
    this.memberId = this.$route.params.mId;
    this.fetchMemberInfo();
    this.fetchMemberEachCourseProgress();
  },
  methods: {
    async fetchMemberInfo() {
      try {
        const endpoint = `/v1/members/${this.memberId}`;
        const results = await api.get(endpoint);
        this.memberInfo = results.data.data;
      } catch (err) {
        console.log("fetchMemberInfo error", err);
      }
    },
    async fetchMemberEachCourseProgress() {
      this.isLoading = true;
      try {
        let results = await api.get(
          `/v1/members/${this.memberId}/each-course-progress?limit=10`
        );
        for (let element of results.data) {
          element.completeRuleStatus = await this.getCompleteRuleStatus(
            element.c_id,
            element.cer_id
          );
        }
        this.items = results.data;
      } catch (err) {
        console.log("fetchMemberEachCourseProgress error", err);
      }
      this.isLoading = false;
    },
    async getCompleteRuleStatus(courseId, cerId) {
      const endpoint = `/v1/members/${this.memberId}/course/${courseId}/complete-rules?cerId=${cerId}`;
      let answer = "error";
      try {
        let res = await api.get(endpoint);
        let data = Array.isArray(res.data) ? res.data : [];
        
        if (data.length > 0) {
          let countSuccess = data.filter((el) => el.rulePass == true).length;
          let countTotal = data.length;

          answer = `${countSuccess} / ${countTotal}`;
        } else {
          answer = "ไม่มีเกณฑ์การจบ";
        }
      } catch (err) {
        console.log("getCompleteRuleStatus error!", err);
      }
      return answer;
    },
    redirectToLearningHistoryEnroll(enrollId) {
      const route = this.$router.resolve({
        name: "report.learning-history-report.enrolls",
        params: {cerId: enrollId}
      });
      window.open(route.href, "_blank");
    }
  },
};
</script>

<style scoped>
.progress-bar {
  background-color: #eb8123 !important;
}
.complete-rule-link {
  color: #007bff;
  cursor: pointer;
}
.complete-rule-link:hover {
  color: #007bff;
  opacity: 0.6;
  cursor: pointer;
}
h6 {
  font-family: "Prompt", sans-serif;
}
</style>
