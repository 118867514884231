<template>
  <div
    v-if="isCardShow"
    class="card card-csq no-shadow mt-4"
    style="min-height: 280px;"
  >
    <template v-if="isLoading">
      <div class="text-center mt-5">
        <b-spinner class="align-middle" variant="primary"></b-spinner>
        <p class="mt-3"><strong>กรุณารอสักครู่</strong></p>
        <p>เรากำลังเตรียมประวัติการเข้าเรียนของคุณ</p>
      </div>
    </template>
    <div
      v-else-if="!isLoading && learningHistoryList.learning_history.length > 0"
      class="card-body"
    >
      <b-row>
        <b-col cols="9">
          <strong>{{ learningHistoryList.c_name }}</strong>
        </b-col>
        <b-col cols="3" class="text-right">
          <b-button
            @click="exportReport()"
            size="sm"
            variant="light"
            class="btn btn-download"
          >
            <b-spinner
              id="report-loading"
              class="aling-middle mr-2"
              style="display: none;"
              small
            ></b-spinner>
            <i class="fa fa-angle-double-down" aria-hidden="true"></i>
            ดาวน์โหลดรายงาน
          </b-button>
          <ag-grid-vue
            style="width: 100%; height: 800px; display: none;"
            class="ag-theme-alpine"
            :columnDefs="columnReport"
            :rowData="rowReport"
            :excelStyles="excelStyles"
            @grid-ready="onGridReportReady"
          ></ag-grid-vue>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col cols="2"><strong>วันที่เริ่มเข้าเรียน</strong></b-col>
        <b-col cols="5">
          <h6>{{ learningHistoryList.cer_startDate | thaiDateFilter }}</h6>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="2"><strong>รวมเวลาที่เรียนทั้งหมด</strong></b-col>
        <b-col cols="5">
          <h6>{{ learningHistoryList.total_learning_time | duration }}</h6>
        </b-col>
      </b-row>

      <b-row class="py-2 mt-4 mx-1" style="border-bottom: 1px solid #000">
        <b-col cols="2"><strong>วันที่</strong></b-col>
        <b-col cols="2"><strong>เวลาที่เริ่มเรียน</strong></b-col>
        <b-col cols="3"><strong>หัวข้อ</strong></b-col>
        <b-col cols="3"><strong>บทเรียน</strong></b-col>
        <b-col cols="2" style="text-align: right;"><strong>เวลาที่ใช้เรียน</strong></b-col>
      </b-row>

      <div
        v-for="(history, historyIndex) in learningHistoryList.learning_history"
        :key="historyIndex"
      >
        <b-row
          v-for="(lecture, lectureIndex) in history.lectures"
          :key="lectureIndex"
          class="py-2 mx-1"
          style="border-bottom: 1px solid #9c9c9c"
        >
          <b-col v-if="lectureIndex == 0" cols="2">{{ history.date | thaiDateFilter }}</b-col>
          <b-col v-else cols="2"></b-col>
          <b-col cols="2">{{ lecture.start_learning_time }}</b-col>
          <b-col cols="3">{{ lecture.mod_name }}</b-col>
          <b-col cols="3">{{ lecture.lec_name }}</b-col>
          <b-col cols="1"></b-col>
          <b-col cols="1" >
            {{ lecture.learning_time | duration }}
          </b-col>
        </b-row>
        <b-row
          class="py-2 mx-1"
          style="border-bottom: 1px solid #9c9c9c; background-color: #eaeaea;"
        >
          <b-col cols="10"></b-col>
          <b-col cols="1" style="font-weight: 600;">รวมเวลา</b-col>
          <b-col cols="1" style="font-weight: 600;">
            {{ history.total_learning_time_by_day | duration }}
          </b-col>
        </b-row>
      </div>
    </div>
    <template v-else>
      <div class="text-center mt-5">
        <p>ไม่มีประวัติการเข้าเรียน</p>
      </div>
    </template>
  </div>
</template>

<script>
import dateMixins from "@/components/pages/analytics/shared/mixins/date.js";
import timeMixins from "@/components/pages/analytics/shared/mixins/time.js";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { AgGridVue } from "ag-grid-vue";

export default {
  components: { "ag-grid-vue": AgGridVue },
  mixins: [dateMixins, timeMixins],
  props: {
    isLoading: Boolean,
    isCardShow: Boolean,
    learningHistoryList: Object,
    memberName: String,
  },
  data() {
    return {
      columnReport: [
        { headerName: "", field: "A" },
        { headerName: "", field: "B" },
        { headerName: "", field: "C" },
        { headerName: "", field: "D" },
        { headerName: "", field: "E" },
      ],
      rowReport: [],
      excelStyles: [
        {
          id: "header",
          font: { fontName: "Cordia New", size: 16, bold: true },
        },
        {
          id: "cell",
          font: { fontName: "Cordia New", size: 16 },
        },
        {
          id: "stringType",
          dataType: "String",
        },
      ],
      gridApiReport: null,
    };
  },
  methods: {
    exportReport() {
      document.getElementById("report-loading").style.display = "inline-block";

      let historylist = this.learningHistoryList;
      let data = [];
      let allListData = [];

      if (historylist.learning_history.length > 0) {
        historylist.learning_history.forEach((items) => {
          data = items.lectures.map((item, index) => {
            if (index == 0) {
              return {
                A: `${this.thaiDateFilter(item.date)}`,
                B: `${item.start_learning_time}`,
                C: item.mod_name,
                D: item.lec_name,
                E: this.duration(item.learning_time),
              };
            } else {
              return {
                A: "",
                B: `${item.start_learning_time}`,
                C: item.mod_name,
                D: item.lec_name,
                E: this.duration(item.learning_time),
              };
            }
          });
          data.push({
            A: "",
            B: "",
            C: "",
            D: "รวมเวลา",
            E: this.duration(items.total_learning_time_by_day),
          });
          allListData = allListData.concat(data);
        });
      }

      let header = [
        { A: "ชื่อคอร์ส", B: historylist.c_name },
        { A: "วันที่เริ่มเข้าเรียน", B: this.thaiDateFilter(historylist.cer_startDate) },
        { A: "รวมเวลาที่เรียนทั้งหมด", B: this.duration(historylist.total_learning_time) },
        { A: "" },
        { A: "วันที่", B: "เวลาที่เริ่มเรียน", C: "หัวข้อ", D: "บทเรียน", E: "เวลาที่ใช้เรียน" },
      ];

      this.rowReport = [...header, ...allListData];
      const sanitizedMemberName = this.memberName.replace(/\./g, '_');
      setTimeout(() => {
        this.gridApiReport.exportDataAsExcel({
          sheetName: "ประวัติการเข้าเรียนของ" + this.memberName,
          fileName: "ประวัติการเข้าเรียนของ" + sanitizedMemberName,
        });
        document.getElementById("report-loading").style.display = "none";
      }, 1000);
    },
    async onGridReportReady(params) {
      this.gridApiReport = params.api;
      this.gridColumnApi = params.columnApi;
    },
  },
};
</script>

<style scoped>
strong {
  font-family: "Prompt", sans-serif;
}
</style>
