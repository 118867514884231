<template>
  <div>
    <div class="mb-3">
      <b-row>
        <b-col cols="12" v-if="items.length == 0 && showData == 0">
          <b-alert variant="warning" style="font-size: 0.9rem;" show>
            <strong>ไม่มีรายการสั่งซื้อในขณะนี้</strong> รายการสั่งซื้อทั้งหมด 0
            รายการ
          </b-alert>
        </b-col>
        <b-col cols="3">
          <label for="timerange" class="pl-2">วันที่แจ้งโอน</label>
          <b-input-group class="pt-1 pl-2">
            <b-form-input
              id="timerange"
              size="sm"
              type="text"
              :value="showRange"
              placeholder="วันที่เริ่มสั่งซื้อ - วันที่สิ้นสุดสั่งซื้อ"
              disabled
            />
            <b-input-group-prepend>
              <b-button
                size="sm"
                variant="primary"
                @click="showCalendarRange()"
              >
                <b-icon icon="calendar-range"></b-icon>
              </b-button>
            </b-input-group-prepend>
          </b-input-group>
          <DatePicker
            id="calendar-range-order"
            style="
              display: none;
              float: right;
              position: absolute;
              left: 15px;
              z-index: 999;
            "
          >
            <template #default>
              <div
                style="padding: 10px 0 5px;
                          background: white;
                          width: 674px;
                          border-style: solid solid none solid;
                          border-color: whitesmoke;
                          border-width: 1px;
                        "
              >
                <b-input-group>
                  <b-input-group-prepend>
                    <b-icon
                      icon="calendar"
                      class="input-group-text icon"
                    ></b-icon>
                  </b-input-group-prepend>
                  <b-form-input
                    class="inputDate"
                    size="sm"
                    style="margin-right: 1rem;"
                    :value="formatStartDate"
                  >
                  </b-form-input>
                  <b-input-group-prepend>
                    <b-icon
                      icon="calendar"
                      class="input-group-text icon"
                    ></b-icon>
                  </b-input-group-prepend>
                  <b-form-input
                    class="inputDate"
                    size="sm"
                    style="margin-right: 1rem;"
                    :value="formatEndDate"
                  >
                  </b-form-input>
                  <b-button
                    style="margin-right: 0.5rem;"
                    size="sm"
                    variant="primary"
                    @click="applyCalendarRange()"
                  >
                    Apply
                  </b-button>
                  <b-button
                    style="margin-right: 1rem;"
                    size="sm"
                    variant="light"
                    @click="showCalendarRange()"
                  >
                    Cancel
                  </b-button>
                </b-input-group>
              </div>
              <div
                style="background: white; 
                          padding-left: 10px;
                          padding-bottom: 10px;
                          border-style: none solid solid solid;
                          border-color: whitesmoke;
                          border-width: 1px;
                          position: relative;
                          z-index: 9;"
              >
                <DatePicker
                  v-model="range"
                  :columns="$screens({ default: 1, sm: 2 })"
                  is-range
                />
              </div>
            </template>
          </DatePicker>
        </b-col>
        <b-col cols="3">
          <label for="studentFirstName" class="pl-2"> ชื่อผู้เรียน </label>
          <b-input-group class="pt-1 pl-2">
            <b-form-input
              id="studentFirstName"
              size="sm"
              type="text"
              v-model="studentFirstName"
              placeholder="ค้นหาชื่อผู้เรียน"
            />
          </b-input-group>
        </b-col>
        <b-col cols="3">
          <label for="studentLastName" class="pl-2"> นามสกุลผู้เรียน </label>
          <b-input-group class="pt-1 pl-2">
            <b-form-input
              id="studentLastName"
              size="sm"
              type="text"
              v-model="studentLastName"
              placeholder="ค้นหานามสกุลผู้เรียน"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="my-4">
        <b-col cols="3">
          <label for="studentEmail" class="pl-2"> อีเมล </label>
          <b-input-group class="pt-1 pl-2">
            <b-form-input
              id="studentEmail"
              size="sm"
              type="text"
              v-model="studentEmail"
              placeholder="ค้นหาอีเมล"
            />
          </b-input-group>
        </b-col>
        <b-col cols="3">
          <label for="invoiceNumber" class="pl-2"> เลขใบแจ้งหนี้ </label>
          <b-input-group class="pt-1 pl-2">
            <b-form-input
              id="invoiceNumber"
              size="sm"
              type="text"
              v-model="invoiceNumber"
              placeholder="เลขใบแจ้งหนี้"
            />
          </b-input-group>
        </b-col>
        <b-col cols="3">
          <label for="paidName" class="pl-2"> สถานะรายการสั่งซื้อ </label>
          <b-dropdown right size="sm" variant="ligth" no-caret class="p-0">
            <template #button-content class="p-0">
              <b-input-group>
                <b-form-input
                  id="paidName"
                  size="sm"
                  class="input-dropdown"
                  :value="paidName"
                >
                </b-form-input>
                <b-input-group-append>
                  <i class="icon-dropdown" aria-hidden="true">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-funnel"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z"
                      />
                    </svg>
                  </i>
                </b-input-group-append>
              </b-input-group>
            </template>
            <b-dropdown-item @click="getPaidStatus('all')">
              รายการทั้งหมด
            </b-dropdown-item>
            <b-dropdown-item @click="getPaidStatus('success')">
              รายการที่ชำระเงินแล้ว
            </b-dropdown-item>
            <b-dropdown-item @click="getPaidStatus('failed')">
              รายการที่ยังไม่ชำระเงิน
            </b-dropdown-item>
            <b-dropdown-item @click="getPaidStatus('cancel')">
              รายการที่ยกเลิก
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
      <b-row class="my-4">
        <b-input-group class="pt-1 pl-4">
          <b-button size="sm" variant="primary" @click="getSearchKeyword()">
            ค้นหา
          </b-button>
        </b-input-group>
      </b-row>
    </div>
    <hr />
    <b-row class="mt-4">
      <b-col cols="7" v-if="items.length > 0 || showData == 1">
        <span style="font-size: 14px; margin-right: 10px;">
          <strong>รายการสั่งซื้อทั้งหมด {{ items.length }} รายการ</strong>
        </span>
        <b-button
          class="inputDate"
          size="sm"
          variant="success"
          style="font-size: 12px;"
        >
          ชำระเงินแล้ว {{ getPurchaseSuccess(items) }} รายการ
        </b-button>
      </b-col>
      <b-col cols="5" v-if="items.length > 0 || showData == 1">
        <b-button
          @click="exportExcel()"
          size="sm"
          variant="info"
          style="float: right;"
        >
          <b-icon icon="chevron-double-down" />
          ดาวน์โหลดรายงาน
          <b-spinner
            id="export-excel-loading"
            style="display: none"
            small
          ></b-spinner>
        </b-button>
        <ag-grid-vue
          style="width: 100%; height: 100%; display: none"
          class="ag-theme-alpine"
          :columnDefs="columnDefs"
          @grid-ready="onGridReady"
          :excelStyles="
            comId == 513 && isReportFlowAccData
              ? excelFlowAccStyles
              : excelDefStyles
          "
          :rowData="reportData"
        >
        </ag-grid-vue>
        <b-button
          hidden
          v-if="comId == 513"
          @click="exportExcelFlowAcc()"
          size="sm"
          variant="outline-info"
          style="float: right; margin-right: 4px;"
        >
          <b-icon icon="chevron-double-down" />
          ดาวน์โหลด FLOWACC
          <b-icon icon="graph-up" />
          <b-spinner
            id="export-excel-flow-acc-loading"
            style="display: none"
            small
          ></b-spinner>
        </b-button>
      </b-col>
      <b-col cols="12" class="mt-4" v-if="items.length == 0">
        <Loading v-if="isLoading && showData == 0" />
      </b-col>
      <b-col cols="12" class="mt-4" v-if="items.length > 0 || showData == 1">
        <b-table
          id="course-order-list"
          :fields="fields"
          :items="items"
          :per-page="perPage"
          :current-page="currentPage"
          responsive
          style="font-size: 14px;
                 border:hidden;"
          :busy="isLoading"
          hover
          striped
        >
          <template slot="table-busy" class="text-center text-danger my-2">
            <Loading />
          </template>
          <template v-slot:cell(datetime)="data">
            {{ formatDate(data.item.co_actualPurchaseDateTime) }}<br />
            {{ formatTime(data.item.co_actualPurchaseDateTime) }}
          </template>
          <template v-slot:cell(fullname)="data">
            {{ data.item.mp_firstName }} {{ data.item.mp_lastName }}
          </template>
          <template v-slot:cell(email)="data">
            {{ data.item.m_email }}
          </template>
          <template v-slot:cell(price)="data">
            <span v-if="comId == 519">
              {{ formatPrice(data.item.btf_bahtAmount) }} บาท
            </span>
            <span v-else>
              {{ formatPrice(data.item.co_purchasePrice) }} บาท
            </span>
          </template>
          <template v-slot:cell(amount)="data">
            {{ data.item.itemCount }}
          </template>
          <template v-slot:cell(status)="data">
            <span v-if="data.item.co_cancelFlag == 1" class="text-danger">
              <strong>ยกเลิก</strong>
            </span>
            <span v-else-if="data.item.co_paidFlag == 0" class="text-secondary">
              <strong>ยังไม่ชำระเงิน</strong>
            </span>
            <span v-else-if="data.item.co_paidFlag == 1">
              <strong class="text-success">ชำระเงินแล้ว</strong>
              <br />
              <strong v-if="formatPrice(data.item.co_purchasePrice) <= 0">ฟรี</strong>
              <strong v-else>
                {{ getPaymentMethod(data.item) }}
              </strong>
            </span>
          </template>
          <template #cell(action)="row">
            <b-button
              size="sm"
              variant="outline-primary"
              @click="row.toggleDetails"
              class="mr-2"
            >
              ดูข้อมูล
            </b-button>
          </template>
          <template v-slot:row-details="rowData">
            <b-row>
              <b-col>
                <table
                  class="borderless-table"
                  style="border-collapse: collapse; border: none;"
                >
                  <tr>
                    <td style="width: 9%;"></td>
                    <td style="width: 29%;"><b>ชื่อผู้แจ้งโอน</b></td>
                    <td>
                      {{ rowData.item.mp_firstName }}
                      {{ rowData.item.mp_lastName }}
                    </td>
                    <td v-if="comId == 519" class="text-right">
                      <b-button
                        class="btn-action"
                        size="sm"
                        variant="light"
                        :href="
                          `${company.whitelabelUrl}/invoice/paymentInvoice?orderId=${rowData.item.co_orderId}`
                        "
                        target="_blank"
                      >
                        ใบแจ้งหนี้
                      </b-button>
                    </td>
                    <td v-else class="text-right">
                      <b-button
                        v-if="rowData.item.co_paidFlag == 1"
                        class="btn-action"
                        size="sm"
                        variant="outline-primary"
                        @click="
                          info(rowData.item, rowData.index, $event.target)
                        "
                      >
                        หลักฐานการชำระเงิน
                      </b-button>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 9%;"></td>
                    <td style="width: 29%;"><b>อีเมล</b></td>
                    <td>
                      {{ rowData.item.m_email }}
                    </td>
                    <td v-if="comId == 519" class="text-right">
                      <b-button
                        v-if="rowData.item.co_paidFlag == 1"
                        class="btn-action"
                        size="sm"
                        variant="outline-primary"
                        @click="
                          info(rowData.item, rowData.index, $event.target)
                        "
                      >
                        หลักฐานการชำระเงิน
                      </b-button>
                    </td>
                    <td v-else></td>
                  </tr>
                  <tr>
                    <td style="width: 9%;"></td>
                    <td style="width: 29%;"><b>โทรศัพท์</b></td>
                    <td>
                      {{ rowData.item.mp_mobilePhone }}
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 9%;"></td>
                    <td style="width: 29%;"><b>ช่องทางการชำระเงิน</b></td>
                    <td v-if="formatPrice(rowData.item.co_purchasePrice) <= 0">ฟรี</td>
                    <td v-else >
                      {{ getPaymentMethod(rowData.item) }}
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 9%;"></td>
                    <td style="width: 29%;"><b>จำนวนเงินที่โอน</b></td>
                    <td>
                      {{ formatPrice(rowData.item.btf_bahtAmount) }}
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 9%;"></td>
                    <td style="width: 29%;"><b>รหัสคูปอง</b></td>
                    <td>
                      {{ rowData.item.pc_code ?? "-" }}
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 9%;"></td>
                    <td style="width: 29%;"><b>โปรโมชั่น</b></td>
                    <td>
                      {{
                        rowData.item.tcd_name ?? rowData.item.ccd_name ?? "-"
                      }}
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 9%;"></td>
                    <td style="width: 29%;"><b>หมายเหตุ</b></td>
                    <td>
                      {{ rowData.item.co_remark ?? "-" }}
                    </td>
                    <td></td>
                  </tr>
                </table>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <table class="custom-table" v-if="comId == 519">
                  <thead>
                    <tr>
                      <th style="width:80%">รายการ</th>
                      <th class="text-right">ราคา</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody id="item-data">
                    <tr
                      v-for="(item, index) in rowData.item.items"
                      :key="index"
                    >
                      <td>{{ item.c_name }}</td>
                      <td class="text-right">
                        {{ formatPrice(Number(item.coi_price) / 1.07) }}
                      </td>
                      <td></td>
                    </tr>
                    <tr v-if="rowData.item.co_discountValue > 0">
                      <td>ส่วนลด</td>
                      <td class="text-right">
                        -
                        {{
                          formatPrice(
                            Number(rowData.item.co_discountValue) / 1.07
                          )
                        }}
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>ภาษีมูลค่าเพิ่ม 7%</td>
                      <td class="text-right">
                        {{
                          formatPrice(
                            calculateVat(
                              rowData.item.co_discountValue,
                              rowData.item.items
                            )
                          )
                        }}
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>ยอดรวม</td>
                      <td class="text-right">
                        {{ formatPrice(rowData.item.co_purchasePrice) }}
                      </td>
                      <td class="bath">บาท</td>
                    </tr>
                    <tr>
                      <td>หักภาษี ณ ที่จ่าย 3%</td>
                      <td class="text-right">
                        {{
                          rowData.item.co_withHoldingTaxValue == "0.00"
                            ? "0.00"
                            : "-" +
                              formatPrice(rowData.item.co_withHoldingTaxValue)
                        }}
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>ยอดชำระจริง</td>
                      <td class="text-right">
                        {{
                          formatPrice(
                            rowData.item.co_purchasePrice -
                              rowData.item.co_withHoldingTaxValue
                          )
                        }}
                      </td>
                      <td class="bath">บาท</td>
                    </tr>
                  </tbody>
                </table>
                <table v-else>
                  <thead>
                    <tr>
                      <th style="width:90%">รายการ</th>
                      <th>ราคา</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in rowData.item.items"
                      :key="index"
                    >
                      <td>{{ item.c_name }}</td>
                      <td class="text-right">
                        {{ formatPrice(item.coi_price) }}
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>ส่วนลด</td>
                      <td class="text-right">
                        -{{ formatPrice(Math.abs(rowData.item.co_totalPrice - rowData.item.co_purchasePrice)) }}
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td><strong>ยอดรวม</strong></td>
                      <td class="text-right">
                        <strong>{{ formatPrice(rowData.item.co_purchasePrice) }}</strong>
                      </td>
                      <td class="bath"><strong>บาท</strong></td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </template>
        </b-table>

        <!-- modal -->
        <b-modal
          :id="infoModal.id"
          centered
          size="md"
          hide-footer
          @hide="resetInfoModal"
        >
          <template #modal-title>
            <h6 style="font-weight: 600;">หลักฐานการชำระเงิน</h6>
          </template>
          <template #default>
            <b-row>
              <b-col cols="3">
                <h6 style="font-weight: 600;">วันที่</h6>
              </b-col>
              <b-col>
                <h6>
                  {{ infoModal.content.co_actualPurchaseDateTime }}
                </h6>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3">
                <h6 style="font-weight: 600;">ชื่อผู้เรียน</h6>
              </b-col>
              <b-col>
                <h6>
                  {{ infoModal.content.mp_firstName }}
                  {{ infoModal.content.mp_lastName }}
                </h6>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3">
                <h6 style="font-weight: 600;">ราคา</h6>
              </b-col>
              <b-col>
                <h6 v-if="comId == 519">
                  {{
                    formatPrice(Number(infoModal.content.co_totalPrice) / 1.07)
                  }}
                  บาท
                </h6>
                <h6 v-else>
                  {{ formatPrice(infoModal.content.co_purchasePrice) }} บาท
                </h6>
              </b-col>
            </b-row>
            <b-row v-if="comId == 519">
              <b-col cols="3">
                <h6 style="font-weight: 600;">ยอดชำระจริง</h6>
              </b-col>
              <b-col>
                <h6>
                  {{
                    formatPrice(
                      infoModal.content.co_purchasePrice -
                        infoModal.content.co_withHoldingTaxValue
                    )
                  }}
                  บาท
                </h6>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" style="text-align: center; margin: 20px 0 20px">
                <img
                  :src="slipImageSrc"
                  alt="Payment Slip"
                  style="width: 100%;"
                />
              </b-col>
            </b-row>
          </template>
        </b-modal>

        <div
          style="text-align: center"
          v-if="showData == 1 && isLoading == false"
        >
          {{ "ไม่มีข้อมูล" }}
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-3" v-if="items.length > 0 || showData == 1">
      <b-col cols="6">
        <form class="form-inline">
          <div class="form-group mb-2">
            <label>แสดง</label>
          </div>
          <div class="form-group ml-2 mb-2">
            <b-form-select
              v-model="selectedShow"
              size="sm"
              @change="changeShowData"
            >
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="30">30</option>
              <option value="50">50</option>
            </b-form-select>
          </div>
          <div class="form-group ml-2 mb-2">
            <label>
              จาก
              {{ items.length }}
              รายการ
            </label>
          </div>
        </form>
      </b-col>
      <b-col cols="6" v-if="items.length > 0 || showData == 1">
        <nav class="float-right">
          <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="items.length"
            aria-controls="course-order-list"
            last-number
          >
          </b-pagination>
        </nav>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from "@/services/api.js";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import moment from "moment";
import Numeral from "numeral";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";
import Loading from "@/components/shared/Loading";
// import SearchBox from "../../../shared/SearchBox";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    DatePicker,
    "ag-grid-vue": AgGridVue,
    Loading,
    // SearchBox,
  },
  data() {
    return {
      comId: this.$store.state.Company.companyInfo.id,
      company: {},
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      slipImageSrc: null,
      items: [],
      fields: [
        {
          label: "วันที่",
          key: "datetime",
          sortable: true,
        },
        {
          label: "ชื่อผู้เรียน",
          key: "fullname",
        },
        {
          label: "อีเมล",
          key: "email",
        },
        {
          label: "ราคา",
          key: "price",
        },
        {
          label: "จำนวนคอร์ส",
          key: "amount",
        },
        {
          label: "สถานะ",
          key: "status",
        },
        {
          label: "",
          key: "action",
        },
      ],
      showData: 0,
      paidName: "รายการทั้งหมด",
      isLoading: false,
      range: {
        start: null,
        end: null,
      },
      fetchParams: {
        selectedMonth: "",
        startDate: "",
        endDate: "",
        courseName: "",
        studentFirstName: "",
        studentLastName: "",
        studentEmail: "",
        invoiceNumber: "",
        paid: "",
      },
      courseName: "",
      studentFirstName: "",
      studentLastName: "",
      studentEmail: "",
      invoiceNumber: "",
      selectedShow: 10,
      perPage: 10,
      currentPage: 1,
      columnDefs: [
        {
          headerName: "No.",
          field: "rowIndex",
          width: 80,
          pinned: "left",
          cellClass: "text-center",
        },
        {
          headerName: "No.Order",
          field: "orderNumber",
          cellClass: "text-center",
        },
        {
          headerName: "ชื่อ นามสกุล (ผู้เข้าอบรม)",
          field: "studentFullName",
          cellClass: "text-left",
        },
        {
          headerName: "ชื่อในใบกำกับ",
          field: "invoiceName",
          cellClass: "text-left",
        },
        {
          headerName: "ที่อยู่1",
          field: "address1",
          cellClass: "text-left",
        },
        {
          headerName: "ที่อยู่2",
          field: "address2",
          cellClass: "text-left",
        },
        {
          headerName: "เบอร์โทร",
          field: "Phone",
          cellClass: "text-left",
        },
        { headerName: "TAX ID", field: "taxId", cellClass: "text-center" },
        {
          headerName: "สนญ / สาขาที่",
          field: "branch",
          cellClass: "text-center",
        },
        { headerName: "#", field: "" },

        {
          headerName: "รวม-สูตร",
          field: "totalAmount",
          cellClass: "text-right",
        },
        {
          headerName: "ส่วนลด",
          field: "discount",
          cellClass: "text-right",
        },
        {
          headerName: "ยอดเงิน",
          field: "netTotal",
          cellClass: "text-right",
        },
        {
          headerName: "VAT",
          field: "vat",
          cellClass: "text-right",
        },
        {
          headerName: "เงินสุทธิ",
          field: "sumTotal",
          cellClass: "text-right",
        },
        {
          headerName: "หัก ณ ที่จ่าย",
          field: "withHoldingTax",
          cellClass: "text-right",
        },
        {
          headerName: "ยอดชำระจริง",
          field: "paymentAmount",
          cellClass: "text-right",
        },
        {
          headerName: "วันที่ชำระ",
          field: "purchaseDateTime",
          cellClass: "text-center",
        },
        {
          headerName: "ธนาคาร",
          field: "bankName",
          cellClass: "text-center",
        },
        { headerName: "#", field: "", cellClass: "text-left" },
        { headerName: "หมายเหตุ", field: "remark", cellClass: "text-left" },
        { headerName: "#", field: "", cellClass: "text-left" },
        { headerName: "คูปอง", field: "coupon", cellClass: "text-left" },
        {
          headerName: "โปรโมชั่น",
          field: "promotion",
          cellClass: "text-left",
        },
        {
          headerName: "ชื่อผู้รับใบเสร็จ",
          field: "billingName",
          cellClass: "text-left",
        },
        {
          headerName: "ที่อยู่จัดส่งใบเสร็จ1",
          field: "billingAddress1",
          cellClass: "text-left",
        },
        {
          headerName: "ที่อยู่จัดส่งใบเสร็จ2",
          field: "billingAddress2",
          cellClass: "text-left",
        },
        {
          headerName: "เบอร์โทรผู้รับใบเสร็จ",
          field: "billingPhone",
          cellClass: "text-left",
        },
        { headerName: "#", field: "" },
      ].filter(Boolean),
      reportData: null,
      gridApi: null,
      excelDefStyles: [
        {
          id: "header",
          font: { fontName: "Cordia New", size: 16, bold: true },
          alignment: {
            horizontal: "Center",
          },
        },
        {
          id: "text-center",
          font: { fontName: "Cordia New", size: 16 },
          alignment: {
            horizontal: "Center",
          },
          dataType: "String",
        },
        {
          id: "text-right",
          font: { fontName: "Cordia New", size: 16 },
          alignment: {
            horizontal: "Right",
          },
          dataType: "String",
        },
        {
          id: "text-left",
          font: { fontName: "Cordia New", size: 16 },
          alignment: {
            horizontal: "left",
          },
          dataType: "String",
        },
      ],
    };
  },
  async mounted() {
    this.isLoading = true;
    await api.get(
      `v1/members-role-permission/${this.$store.state.Authentication.authUser.id}`
    );
    await this.fetchPurchase();
    this.setCompanyInfo();
    this.isLoading = false;
  },
  computed: {
    ...mapGetters("CourseOrder", [
      "courseOrderListByOrder",
      "isLoading",
      "courseOrderListFlowAcc",
      "courseTransferOrderDetails",
    ]),
    showRange() {
      if (this.range.start != null && this.range.end != null) {
        return (
          moment(this.range.start).format("DD/MM/YYYY") +
          " - " +
          moment(this.range.end).format("DD/MM/YYYY")
        );
      } else {
        return null;
      }
    },
    formatStartDate() {
      if (this.range.start != null) {
        return moment(this.range.start).format("DD/MM/YYYY");
      } else {
        return null;
      }
    },
    formatEndDate() {
      if (this.range.end != null) {
        return moment(this.range.end).format("DD/MM/YYYY");
      } else {
        return null;
      }
    },
  },
  methods: {
    ...mapActions("CourseOrder", [
      "fetchCourseOrderListByOrder",
      "fetchCourseOrderListFlowAcc",
      "getCourseTransferOrderDetails",
    ]),
    isTutorrusCompany() {
      if (localStorage.getItem("ComId") == 408) {
        return true;
      } else {
        return false;
      }
    },
    async fetchPurchase() {
      this.fetchParams.selectedMonth = moment().format("YYYY-MM");
      this.fetchParams.startDate =
        this.range.start == null
          ? ""
          : moment(this.range.start).format("YYYY-MM-DD");
      this.fetchParams.endDate =
        this.range.end == null
          ? ""
          : moment(this.range.end).format("YYYY-MM-DD");
      this.fetchParams.courseName = this.courseName;
      this.fetchParams.studentFirstName = this.studentFirstName;
      this.fetchParams.studentLastName = this.studentLastName;
      this.fetchParams.studentEmail = this.studentEmail;
      this.fetchParams.invoiceNumber = this.invoiceNumber;
      this.fetchParams.paid = this.paidStatus;
      await this.fetchCourseOrderListByOrder(this.fetchParams);
      this.items = this.courseOrderListByOrder;
    },
    getPurchaseSuccess(item) {
      let paymentSuccess = 0;
      for (let i = 0; i < item.length; i++) {
        if (item[i].co_paidFlag == 1 && item[i].co_cancelFlag != 1) {
          paymentSuccess = paymentSuccess + 1;
        }
      }
      return paymentSuccess;
    },
    setPaidName(status) {
      if (status == "all") {
        this.paidName = "รายการทั้งหมด";
      } else if (status == "success") {
        this.paidName = "รายการที่ชำระเงินแล้ว";
      } else if (status == "failed") {
        this.paidName = "รายการที่ยังไม่ชำระเงิน";
      } else if (status == "cancel") {
        this.paidName = "รายการที่ยกเลิก";
      }
    },
    async exportExcel() {
      document.getElementById("export-excel-loading").style.display =
        "inline-block";
      await this.fetchPurchase();

      let subItem = this.items.map((element) => element.items);
      let maxLengthArray = subItem.reduce(
        (max, array) => (array.length > max.length ? array : max),
        []
      );

      let courseItemColumns = this.prepareCourseItemColumns(maxLengthArray);
      // insert course items columns into column#9
      this.columnDefs.splice(9, 0, ...courseItemColumns);

      let summaryRow = this.calculateSummaryRow();
      // merge order data with summary row
      this.reportData = [...this.prepareReportData(summaryRow), summaryRow];

      setTimeout(() => {
        this.gridApi.exportDataAsExcel({
          sheetName: "course-order-list-by-order",
          fileName: `CourseOrderListByOrder`,
        });
        document.getElementById("export-excel-loading").style.display = "none";
        this.columnDefs.splice(9, courseItemColumns.length);
      }, 1000);
    },
    async onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    prepareCourseItemColumns(maxLengthArray) {
      let resultColumn = [];
      maxLengthArray.forEach((item, index) => {
        let newColumn = [];
        newColumn.push(
          {
            headerName: `ชื่อหลักสูตร ${index + 1}`,
            field: `courseName_${index + 1}`,
            cellClass: "text-left",
          },
          {
            headerName: "คน",
            field: `people_${index + 1}`,
            cellClass: "text-center",
          },
          {
            headerName: "ราคา",
            field: "ราคา",
            cellClass: "text-right",
            valueGetter: (params) => {
              let pricePropertyName = `price_${index + 1}`;
              if (params.data[pricePropertyName] == -1) {
                return "";
              }
              if (params.data[pricePropertyName]) {
                if (this.comId == 519) {
                  return this.formatPrice(
                    params.data[pricePropertyName] / 1.07
                  );
                } else {
                  return params.data[pricePropertyName];
                }
              } else {
                return "";
              }
            },
          },
          {
            field: "จำนวนเงิน",
            cellClass: "text-right",
            valueGetter: (params) => {
              let pricePropertyName = `price_${index + 1}`;
              if (params.data[pricePropertyName] == -1) {
                return "";
              }
              if (params.data[pricePropertyName]) {
                if (this.comId == 519) {
                  return this.formatPrice(
                    params.data[pricePropertyName] / 1.07
                  );
                } else {
                  return params.data[pricePropertyName];
                }
              } else {
                return "0";
              }
            },
          }
        );
        resultColumn = resultColumn.concat(newColumn);
      });
      return resultColumn;
    },
    calculateSummaryRow() {
      let startDate =
        this.range.start != null
          ? moment(this.range.start)
              .add(543, "years")
              .format("DD/MM/YYYY")
          : "";
      let endDate =
        this.range.end != null
          ? moment(this.range.end)
              .add(543, "years")
              .format("DD/MM/YYYY")
          : "";

      let sumPuarchasePrice = null,
        sumPeople = null,
        sumtotalAmount = null,
        sumDiscount = null,
        sumVat = null,
        sumsumTotal = null,
        sumWithHolding = null,
        sumPaymentAmount = null;

      this.items.forEach((item) => {
        sumPeople = sumPeople + item.items.length;
        sumVat += Number(this.calculateVat(item.co_discountValue, item.items));
        sumsumTotal += Number(item.co_purchasePrice);
        if (this.comId == 519) {
          sumPuarchasePrice += item.co_purchasePrice / 1.07;
          sumtotalAmount += item.co_totalPrice / 1.07;
          sumDiscount += item.co_discountValue / 1.07;
          sumWithHolding += Number(item.co_withHoldingTaxValue);
          sumPaymentAmount +=
            item.co_purchasePrice - item.co_withHoldingTaxValue;
        } else {
          sumPuarchasePrice += item.co_purchasePrice;
          sumtotalAmount += item.co_totalPrice;
          sumDiscount += item.co_discountValue;
        }
      });
      let summaryRow = {
        rowIndex: startDate + " - " + endDate,
        studentFullName: this.formatPrice(sumPuarchasePrice),
        invoiceName: sumPeople,
        totalAmount: this.formatPrice(sumtotalAmount),
        discount: "-" + this.formatPrice(sumDiscount),
        netTotal: this.formatPrice(sumPuarchasePrice),
        vat: this.formatPrice(sumVat),
        sumTotal: this.formatPrice(sumsumTotal),
        withHoldingTax: this.formatPrice(sumWithHolding),
        paymentAmount: this.formatPrice(sumPaymentAmount),
        items: [{ coi_purchasePrice: -1 }],
      };
      return summaryRow;
    },
    prepareReportData(summaryRow) {
      let result = this.items.map((element, index) => {
        let flattenedC = {};
        element.items.forEach((item, index) => {
          flattenedC[`courseName_${index + 1}`] = item.c_name;
          flattenedC[`price_${index + 1}`] = Number(item.coi_price);
          flattenedC[`people_${index + 1}`] = "1";
          if (isNaN(summaryRow[`people_${index + 1}`])) {
            summaryRow[`people_${index + 1}`] = 1;
            summaryRow[`price_${index + 1}`] = -1;
          } else {
            summaryRow[`people_${index + 1}`]++;
            summaryRow[`price_${index + 1}`] = -1;
          }
        });

        const namePrefix = "คุณ";
        let studentFullName =
          namePrefix + element.mp_firstName + " " + element.mp_lastName;
        let invoiceName = element.mba_type == "CORPERATE"
          ? element.mba_corperateName
          : namePrefix + element.mba_firstName + " " + element.mba_lastName;
        let billingFullName =
          namePrefix + element.msa_firstName + " " + element.msa_lastName;

        let building = element.mba_buildingName
          ? "อาคาร " + element.mba_buildingName + " "
          : "";
        let floor = element.mba_floor ? "ชั้น " + element.mba_floor + " " : "";
        let number = element.mba_number ? element.mba_number + " " : "";
        let moo = element.mba_moo ? "ม." + element.mba_moo + " " : "";
        let village = element.mba_village
          ? "มบ." + element.mba_village + " "
          : "";
        let soi = element.mba_soi ? "ซ." + element.mba_soi + " " : "";
        let street = element.mba_street ? "ถ." + element.mba_street + " " : "";
        let subDistrict = "";
        let district = "";
        let province = "";
        if (element.mba_province == "กรุงเทพมหานคร") {
          subDistrict = element.mba_subDistrict
            ? "แขวง" + element.mba_subDistrict + " "
            : "";
          district = element.mba_district
            ? "เขต" + element.mba_district + " "
            : "";
          province = "กทม.";
        } else {
          subDistrict = element.mba_subDistrict
            ? "ต." + element.mba_subDistrict + " "
            : "";
          district = element.mba_district
            ? "อ." + element.mba_district + " "
            : "";
          province = element.mba_province
            ? "จ." + element.mba_province + " "
            : "";
        }
        let postcode = element.mba_postcode ? element.mba_postcode + " " : "";
        let branch =
          element.mba_branch == "MAIN_OFFICE"
            ? "สำนักงานใหญ่"
            : element.mba_branch == "BRANCH_OFFICE"
            ? "สาขาที่ " + element.mba_branchId
            : "-";

        let msa_buildingName = element.msa_buildingName
          ? "อาคาร " + element.msa_buildingName + " "
          : "";
        let msa_floor = element.msa_floor
          ? "ชั้น " + element.msa_floor + " "
          : "";
        let msa_number = element.msa_number ? element.msa_number + " " : "";
        let msa_moo = element.msa_moo ? "ม." + element.msa_moo + " " : "";
        let msa_village = element.msa_village
          ? "มบ." + element.msa_village + " "
          : "";
        let msa_soi = element.msa_soi ? "ซ." + element.msa_soi + " " : "";
        let msa_street = element.msa_street
          ? "ถ." + element.msa_street + " "
          : "";
        let msa_subDistrict = "";
        let msa_district = "";
        let msa_province = "";
        if (element.msa_province == "กรุงเทพมหานคร") {
          msa_subDistrict = element.msa_subDistrict
            ? "แขวง" + element.msa_subDistrict + " "
            : "";
          msa_district = element.msa_district
            ? "เขต" + element.msa_district + " "
            : "";
          msa_province = "กทม.";
        } else {
          msa_subDistrict = element.msa_subDistrict
            ? "ต." + element.msa_subDistrict + " "
            : "";
          msa_district = element.msa_district
            ? "อ." + element.msa_district + " "
            : "";
          msa_province = element.msa_province
            ? "จ." + element.msa_province + " "
            : "";
        }
        let msa_postcode = element.msa_postcode
          ? element.msa_postcode + " "
          : "";

        let totalAmount = null;
        let discount = null;
        let netTotal = null;
        let withHoldingTax = null;
        let paymentAmount = null;
        if (this.comId == 519) {
          totalAmount = this.formatPrice(element.co_totalPrice / 1.07);
          discount =
            "-(" + this.formatPrice(element.co_discountValue / 1.07) + ")";
          netTotal = this.formatPrice(element.co_purchasePrice / 1.07);
          withHoldingTax = element.co_withHoldingTaxValue;
          paymentAmount = this.formatPrice(
            element.co_purchasePrice - element.co_withHoldingTaxValue
          );
        } else {
          totalAmount = element.co_totalPrice;
          discount = "-(" + (element.co_totalPrice - element.co_purchasePrice ) + ")";
          netTotal = element.co_purchasePrice;
          withHoldingTax = null;
          paymentAmount = null;
        }

        return {
          rowIndex: index + 1,
          orderNumber: element.co_orderNumber,
          studentFullName: studentFullName,
          invoiceName: invoiceName,
          address1:
            number +
            building +
            floor +
            village +
            moo +
            soi +
            street +
            subDistrict,
          address2: district + province + postcode,
          Phone: element.mp_mobilePhone,
          taxId: element.mba_idNo,
          branch: branch,
          totalAmount: totalAmount,
          discount: discount,
          netTotal: netTotal,
          vat: this.formatPrice(this.calculateVat(element.co_discountValue, element.items)),
          sumTotal: element.co_purchasePrice,
          withHoldingTax: withHoldingTax,
          paymentAmount: paymentAmount,
          purchaseDateTime: this.formattedDate(
            element.co_actualPurchaseDateTime
          ),
          bankName: element.cpa_BTBankName == 'QRPROMPTPAY' ? 'QR code' : element.cpa_BTBankName,
          remark: element.co_remark,
          coupon: element.pc_code,
          promotion: element.tcd_name ?? element.ccd_name,
          billingName: billingFullName,
          billingAddress1:
            msa_number +
            msa_buildingName +
            msa_floor +
            msa_village +
            msa_moo +
            msa_soi +
            msa_street +
            msa_subDistrict,
          billingAddress2: msa_district + msa_province + msa_postcode,
          billingPhone: element.msa_phone,
          items: element.items,
          ...flattenedC,
        };
      });
      return result;
    },
    setCompanyInfo() {
      this.company = { ...this.$store.state.Company.companyInfo };
    },
    formatDate(datetime) {
      return moment(datetime).format("YYYY-MM-DD");
    },
    formatTime(datetime) {
      return moment(datetime).format("HH:mm:ss");
    },
    formatPrice(price) {
      return Numeral(price).format("0,0.00");
    },
    async getSearchKeyword() {
      this.isLoading = true;
      await this.fetchPurchase();
      if (this.items.length > 0) {
        this.showData = 0;
      } else {
        this.showData = 1;
      }
      this.isLoading = false;
    },
    async getPaidStatus(status) {
      this.isLoading = true;
      this.paidStatus = status;
      await this.fetchPurchase();
      if (this.items.length > 0) {
        this.showData = 0;
        this.setPaidName(this.paidStatus);
      } else {
        this.showData = 1;
        this.setPaidName(this.paidStatus);
      }
      this.isLoading = false;
    },
    async showCalendarRange() {
      let rangeCalendar = document.getElementById("calendar-range-order");
      if (rangeCalendar.style.display == "inline-block") {
        this.isLoading = true;
        rangeCalendar.style.display = "none";
        this.range.start = null;
        this.range.end = null;
        this.showData = 1;
        await this.fetchPurchase();
        if (this.items.length > 0) {
          this.showData = 0;
        }
        this.isLoading = false;
      } else {
        rangeCalendar.style.display = "inline-block";
      }
    },
    async applyCalendarRange() {
      let rangeCalendar = document.getElementById("calendar-range-order");
      if (this.range.start != null && this.range.end != null) {
        this.isLoading = true;
        rangeCalendar.style.display = "none";
        this.showData = 1;
        await this.fetchPurchase();
        if (this.items.length > 0) {
          this.showData = 0;
        }
        this.isLoading = false;
      } else {
        rangeCalendar.style.display = "none";
      }
    },
    changeShowData() {
      this.perPage = this.selectedShow;
    },
    async info(item, index, button) {
      await this.getCourseTransferOrderDetails(item.co_orderId).then(() => {});
      this.infoModal.content = item;
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
      this.slipImageSrc = this.courseTransferOrderDetails.paymentSlip.replace(
        "open?",
        "thumbnail?"
      );
      this.slipImageSrc += "&sz=w1000";
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    calculateVat(discount, items) {
      let sumPuarchasePriceWithVat = null;
      let sumPuarchasePriceNoVat = null;

      items.forEach((item) => {
        sumPuarchasePriceWithVat =
          sumPuarchasePriceWithVat + Number(item.coi_price);
      });
      items.forEach((item) => {
        sumPuarchasePriceNoVat =
          sumPuarchasePriceNoVat + Number(item.coi_price) / 1.07;
      });

      if (this.comId == 519) {
        return (
          sumPuarchasePriceWithVat -
          sumPuarchasePriceNoVat -
          (discount - discount / 1.07)
        );
      } else {
        return null;
      }
    },
    formattedDate(date) {
      const dateObject = new Date(date);
      const thaiYear = dateObject.getFullYear() + 543;

      const formattedDate = dateObject.toLocaleDateString("th-TH", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });

      const formattedDateWithThaiYear = formattedDate
        .replace(/\d{4}/, thaiYear.toString())
        .replace(/\//g, "-");

      return formattedDateWithThaiYear;
    },
    checkedTransfer(item) {
      let paymentMethod = "";

      if (item.cpa_accountType == "BANKTRANSFER") {
        paymentMethod = item.cpa_BTBankName + " " + item.cpa_BTAccountNumber;
      } else if (item.cpa_accountType == "QRTRANSFER") {
        paymentMethod = "QR code";
      } else if (item.cpa_accountType == "PROMPTPAYTRANSFER") {
        paymentMethod = "PROMPTPAY " + item.cpa_BTAccountNumber;
      } else {
        paymentMethod = 'เงินโอน'
      }

      return paymentMethod;
    },
    getPaymentMethod(item) {
      switch (item.co_paymentMethod) {
        case 'CASH' : 
          return 'เงินสด';
        case 'CASH_TRANSFER' : 
          return 'ชำระผ่านสาขา';
        case 'TRANSFER' : 
          return this.checkedTransfer(item);
        case 'ONLINEBANKING' : 
          return 'Online Banking';
        case 'CREDITCARD' : 
          return 'บัตรเครดิต';
        case 'COUNTER_SERVICE' : 
          return 'Counter service';
        case 'EXTENSION' : 
          return 'การต่ออายุ';
        case 'DATAIMPORT' : 
          return 'การนำเข้าข้อมูล';
        case 'KTC_FLEXI' : 
          return 'installment via KTC gateway';
        case 'KTC_RETAIL' : 
          return 'KTC gateway';
        case 'REDEEM' : 
          return 'แลกส่วนลด';
        case 'QRCODE' : 
          return 'QR Code';
        case 'INSTALLMENT' : 
          return 'ผ่อนชำระ';
        case 'PROMOCODE' : 
          return 'คูปอง';
        case 'SCBQRCODE' : 
          return 'SCB QR Code';
        case 'UNIONPAY' : 
          return 'UnionPay';
        default:
          return 'Unknown method';
      }
    }
  },
};
</script>

<style scoped>
.btn-action {
  padding: 2px 6px 2px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

.text-right {
  text-align: right;
}

.bath {
  padding-left: 0;
}

.table-striped tbody tr:nth-of-type(odd),
.table-striped tbody tr:nth-of-type(even) {
  background-color: #e7f1ff;
}

.custom-table tr td,
.custom-table tr th {
  padding-top: 7px;
  padding-bottom: 7px;
}

.borderless-table tr td {
  padding-top: 7px;
  padding-bottom: 7px;
}
.p-0,
.p-0 .dropdown,
.p-0 .btn {
  padding: 0 !important;
}
</style>
