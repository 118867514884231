<template>
  <div class>
    <layout>
      <template v-slot:header v-if="perPage == 10">
        <b-row>
          <b-col cols="6">
            <h6 style="font-family: 'Prompt', sans-serif;">นักเรียนที่กำลังเรียนอยู่</h6>
          </b-col>
        </b-row>
      </template>
      <template v-slot:body>
        <div class>
          <b-table
            striped
            id="course-student-learning-table"
            :per-page="perPage"
            :current-page="currentPage"
            table-class="tbNumber text-left "
            :items="result"
            :fields="fields"
            small
            sort-icon-left
            responsive
            :busy="isBusy"
            hover
          >
            <template v-slot:cell(display)="data">
              <img :src="data.item.mp_displayPath" class="user-profile-image" />
            </template>
            <template v-slot:cell(fullName)="data"
              ><span>{{
                data.item.mp_firstName + ' ' + data.item.mp_lastName
              }}</span></template
            >
            <template v-slot:cell(hour)="data">
              <span>{{ data.item.hour && data.item.hour }}</span>
            </template>

            <template v-slot:cell(lec_name)="data">
              <span>{{ data.item.lec_name }}</span>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="course-student-learning-table"
            v-if="perPage > 10"
          ></b-pagination>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
import layout from '@/components/pages/analytics/shared/Layout';
import api from '@/services/api.js';
export default {
  components: {
    layout,
  },
  props: ['apiRoute', 'perPage'],
  data() {
    return {
      cId: this.$route.params.cId,

      currentPage: 1,
      rows: 0,
      result: [],
      isBusy: true,
      fields: [
        { key: 'display', label: '' },
        { key: 'fullName', label: 'ชื่อผู้เรียน' },
        {
          key: 'hour',
          label: 'เวลาเริ่มเรียน',
        },
        {
          key: 'lec_name',
          label: 'บทเรียนที่เรียน',
        },
      ],
      items: [],
    };
  },
  methods: {
    getRandomInt(max, min) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
  },
  async mounted() {
    this.isBusy = true;
    let res = await api.get(this.apiRoute);
    this.result = res.data;
    this.rows = this.result.length;
    setTimeout(() => {
      this.isBusy = false;
    }, 1 * 1000);
    this.items = this.$store.getters.data;
  },
};
</script>
<style scoped>
.user-profile-image {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  object-fit: cover;
  margin-top: 10px;
}
</style>
