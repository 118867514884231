<template>
  <div class="h-100">
    <layout class="user-learn-progress h-100">
      <template v-slot:header> </template>
      <template v-slot:body>
        <div class="d-flex justify-content-between mb-3">
          <p>รายละเอียดแบบทดสอบ</p>
        </div>
        <div v-if="datalectures.length == 0" class="text-center mt-4">
          <h5>ไม่มีแบบทดสอบ</h5>
        </div>
        <b-table v-else striped hover :fields="fields" :items="datalectures">
          <template v-slot:cell(lec_name)="data">
            <span>
              {{ data.item.lec_name }}
            </span>
          </template>
        </b-table>
      </template>
    </layout>
  </div>
</template>
<script>
import api from "@/services/api.js";
import layout from "@/components/pages/analytics/shared/Layout";
export default {
  components: {
    layout,
  },
  data() {
    return {
      datalectures: [],
      cId: this.$route.params.cId,
      cerId: this.$route.params.cerId,
      fields: [
        { key: "lec_name", label: "แบบทดสอบ" },
        { key: "lel_examResultPercent", label: "คะแนน" },
      ],
    };
  },
  async mounted() {
    await api
      .get("/v1/learning-lectures/" + this.cerId + "?type=quiz")
      .then(({ data }) => {
        this.datalectures = data;
      });
  },
};
</script>
<style scoped>
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css);

p, h5 {
  font-family: "Prompt", sans-serif;
}
</style>