<template>
  <div>
    <page-header :title="'ผู้เรียนที่มีจำนวน login มากที่สุด'"></page-header>
    <layout class="m-4">
      <template v-slot:header>
        <!-- Selection -->
        <div class="pb-2">
          <b-row align-v="center">
            <b-col cols="12" md="6" class="my-2 d-flex flex-column">
              <p class="period-date-label">เริ่มต้น</p>
              <b-input
                v-model="periodDateFrom"
                type="date"
                :max="periodDateTo"
                @change="handlePeriodDateChange"
                :disabled="isLoading"
              ></b-input>
            </b-col>
            <b-col cols="12" md="6" class="d-flex flex-column my-2">
              <p class="period-date-label">สิ้นสุด</p>
              <b-input
                v-model="periodDateTo"
                type="date"
                class=""
                :max="periodDateToMax"
                @change="handlePeriodDateChange"
                :disabled="isLoading"
              ></b-input>
            </b-col>
          </b-row>
        </div>
      </template>
      <template v-slot:body>
        <!-- Table -->
        <b-row>
          <b-col cols="10"> </b-col>
          <b-col cols="2" class="text-right">
            <b-button @click="exportData()" class="btn btn-download" href=""
              ><i class="fa fa-angle-double-down" aria-hidden="true"></i>ดาวน์โหลดรายงาน</b-button
            >
          </b-col>
        </b-row>
        <div>
          <b-table
            id="most-login-table"
            table-class="tbNumber text-left "
            style="overflow-x: auto"
            :items="items"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            class="text-truncate"
            small
            sort-icon-left
            responsive="true"
            :busy="isLoading"
            hover
            striped
          >
            <template v-slot:cell(no)="data">
              <span>{{ data.item.no }}</span></template
            >
            <template v-slot:cell(memberName)="data">
              <a
                :href="`/${companyInfo.url}/analytics/users/${data.item.memberId}/learning-stats`"
                target="_blank"
              >
                {{
                  (data.item.memberName.trim().length > 0
                    ? data.item.memberName
                    : "-") | cut
                }}
              </a>
            </template>
            <template v-slot:cell(totalAmount)="data">
              <div class="text-right">
                {{ data.item.loginAmount }}
              </div>
            </template>
            <template v-slot:cell(activeAmount)="data">
              <div class="text-right">
                {{ data.item.activeAmount }}
              </div>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
          <div style="text-align: center" v-if="items.length == 0">
            {{ "ไม่มีข้อมูล" }}
          </div>
        </div>
        <!-- Pagination -->
        <div class="d-flex justify-content-end">
          <b-pagination
            class="mr-4"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="most-login-table"
          ></b-pagination>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
import PageHeader from "@/components/layouts/PageHeader.vue";
import layout from "@/components/pages/analytics/shared/Layout";
import api from "@/services/api";
import exp from "@/services/export.js";
import moment from "moment";

export default {
  components: {
    layout,
    PageHeader,
  },
  data() {
    return {
      isLoading: false,
      fields: [
        { key: "no", label: "ลำดับ" },
        { key: "memberName", label: "ชื่อผู้เรียน" },
        { key: "loginAmount", label: "จำนวนการ Login" },
      ],
      periodDateFrom: moment().subtract(1, "month").format("YYYY-MM-DD"),
      periodDateTo: moment().format("YYYY-MM-DD"),
      periodDateToMax: moment().format("YYYY-MM-DD"),
      items: [],
      companyInfo: {},
      currentPage: 1,
      perPage: 20,
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  mounted() {
    this.setCompanyInfo();
    this.fetchTopTenLoginStudent();
  },
  methods: {
    async exportData() {
      let value = [];
      let set = this.items;
      set.forEach((i) => {
        let temp = {
          name: i.memberName,
          id : i.memberStaffId,
          login: i.loginAmount,
        };
        value.push(temp);
      });
      let key = ["ชื่อผู้เรียน", "รหัสผู้เรียน", "จำนวนการ Login"];
      let filename = "User-Most-Login-" + this.companyInfo.url;
      const data = {
        key: key,
        filename: filename,
        data: value,
      };
      await exp.exportData(data);
    },
    setCompanyInfo() {
      this.companyInfo = this.$store.state.Company.companyInfo;
    },
    async fetchTopTenLoginStudent() {
      this.isLoading = true;
      try {
        const query = `from=${this.periodDateFrom}&to=${this.periodDateTo}`;
        const res = await api.get(
          `/v1/analytics/students/learning-login-stats?${query}`
        );
        this.items = res.data.data.map((el, index) => {
          return { no: index + 1, ...el };
        });
      } catch (err) {
        console.log("fetchTopTenLoginStudent error", err);
      }
      this.isLoading = false;
    },
    handlePeriodDateChange() {
      this.presetPeriod = "";
      if (moment(this.periodDateTo).isBefore(this.periodDateFrom)) {
        this.periodDateFrom = this.periodDateTo;
      }
      this.fetchTopTenLoginStudent();
    },
  },
};
</script>

<style scoped>
.period-date-label {
  font-size: 15px;
  margin-bottom: 2px;
  margin-left: 2px;
}

p {
  font-family: "Prompt", sans-serif;
}
</style>
