<template>
  <div>
    <layout>
      <template v-slot:header>
        <b-row>
          <b-col class="text-left" cols="8"
            ><h6 style="font-family: 'Prompt', sans-serif;">ผู้เรียนทีมีจํานวน login มากทีสุด</h6>
          </b-col>
          <b-col class="text-right" cols="4"
            ><a
              :href="`/${companyInfo.url}/analytics/users/most-login`"
              target="_blank"
              >ดูทั้งหมด</a
            ></b-col
          >
        </b-row>
      </template>
      <template v-slot:body>
        <div class="pb-2">
          <b-row class="mb-2 mt-2">
            <b-col class="pl-3 pr-0">
              <b-form inline class="col-12 p-0">
                <label class="mr-sm-2" for="start-d">เริ่มต้น</label>
                <b-input
                  id="start-d"
                  class="col-8 p-1"
                  v-model="periodDateFrom"
                  type="date"
                  :max="periodDateTo"
                  @change="handlePeriodDateChange"
                  :disabled="isLoading"
                ></b-input>
              </b-form>
            </b-col>
            <b-col class="p-0">
              <b-form inline class="col-12 p-0">
                <label class="mr-sm-2" for="end-d">สิ้นสุด</label>
                <b-input
                  id="end-d"
                  class="col-8 p-1"
                  v-model="periodDateTo"
                  type="date"
                  :max="periodDateToMax"
                  @change="handlePeriodDateChange"
                  :disabled="isLoading"
                ></b-input>
              </b-form>
            </b-col>
          </b-row>
        </div>
        <div>
          <b-table
            table-class="tbNumber text-left "
            style="overflow-x: auto"
            :items="items"
            :fields="fields"
            class="text-truncate"
            small
            sort-icon-left
            responsive="true"
            :busy="isLoading"
            hover
            striped
          >
            <template v-slot:cell(no)="data">
              <span>{{ data.item.no }}</span></template
            >
            <template v-slot:cell(memberName)="data">
              <a
                :href="
                  `/${companyInfo.url}/analytics/users/${data.item.memberId}/learning-stats`
                "
                target="_blank"
              >
                {{
                  (data.item.memberName.trim().length > 0
                    ? data.item.memberName
                    : '-') | cut
                }}
              </a>
            </template>
            <template v-slot:cell(totalAmount)="data">
              <div class="text-right">{{ data.item.loginAmount }}</div>
            </template>
            <template v-slot:cell(activeAmount)="data">
              <div class="text-right">{{ data.item.activeAmount }}</div>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
          <div style="text-align: center" v-if="items.length == 0">
            ไม่มีข้อมูล
          </div>
        </div>
      </template>
    </layout>
  </div>
</template>

<script>
import layout from '@/components/pages/analytics/shared/Layout';
import api from '@/services/api';
import moment from 'moment';

export default {
  components: {
    layout,
  },
  data() {
    return {
      isLoading: false,
      fields: [
        { key: 'no', label: 'ลำดับ' },
        { key: 'memberName', label: 'ชื่อผู้เรียน' },
        { key: 'loginAmount', label: 'จำนวนการ Login' },
      ],
      periodDateFrom: moment()
        .subtract(1, 'month')
        .format('YYYY-MM-DD'),
      periodDateTo: moment().format('YYYY-MM-DD'),
      periodDateToMax: moment().format('YYYY-MM-DD'),
      items: [],
      companyInfo: {},
    };
  },
  mounted() {
    this.setCompanyInfo();
    this.fetchTopTenLoginStudent();
  },
  methods: {
    setCompanyInfo() {
      this.companyInfo = this.$store.state.Company.companyInfo;
    },
    async fetchTopTenLoginStudent() {
      this.isLoading = true;
      try {
        const query = `from=${this.periodDateFrom}&to=${this.periodDateTo}&topten=true`;
        const res = await api.get(
          `/v1/analytics/students/learning-login-stats?${query}`
        );
        this.items = res.data.data.map((el, index) => {
          return { no: index + 1, ...el };
        });
      } catch (err) {
        console.log('fetchTopTenLoginStudent error', err);
      }
      this.isLoading = false;
    },
    handlePeriodDateChange() {
      this.presetPeriod = '';
      if (moment(this.periodDateTo).isBefore(this.periodDateFrom)) {
        this.periodDateFrom = this.periodDateTo;
      }
      this.fetchTopTenLoginStudent();
    },
  },
};
</script>

<style scoped>
.period-date-label {
  font-size: 15px;
  margin-bottom: 2px;
  margin-left: 2px;
}
</style>
